import { ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import {
	Box,
	Grid,
	Button,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Modal,
	Fade,
} from '@mui/material';
import { useIntl } from 'react-intl';
import AddressDetails from './address-details';
import { ConstructionAddress } from '../../../types';
import client from '../../../clients/client';
import GenericConfirmationModal from '../../modals/generic-confirmation-modal';

interface adressesTabProps {
	constructorAddresses: ConstructionAddress[];
	editAddress: (edition: boolean, adressToEdit?: ConstructionAddress) => void;
	setEmptyAddress: (isEmpty: boolean) => void;
	updateAddressList: () => void;
	token: string;
}

const AddressesTab: React.FC<adressesTabProps> = ({
	constructorAddresses,
	editAddress,
	token,
	setEmptyAddress,
	updateAddressList,
}) => {
	const intl = useIntl();

	const [addresses, setAddresses] = useState(constructorAddresses);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [addressToDelete, setAddressToDelete] = useState<ConstructionAddress>();
	const [modalLoading, setModalLoading] = useState(false);

	const switchEditMode = (edition: boolean, addressToEdit?: ConstructionAddress) => {
		editAddress(edition, addressToEdit);
	};

	const onDeleteModal = async () => {
		addressToDelete && (await handleDelete(addressToDelete));
		setShowDeleteModal(false);
	};
	const onCancelModal = () => {
		setShowDeleteModal(false);
		setAddressToDelete(undefined);
	};

	const handleDelete = async (addressToDelete: ConstructionAddress) => {
		setModalLoading(true);
		let data = { id: addressToDelete.id, contacts: addressToDelete.contacts };
		addresses.length === 1 && setEmptyAddress(true);
		await client.deleteOrganizationAddress({ data: data, token: token });
		setModalLoading(false);
		updateAddressList();
	};

	useEffect(() => {
		setAddresses(constructorAddresses);
	}, [constructorAddresses]);

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid display={'flex'} justifyContent={'end'} item xs={12}>
					<Button onClick={() => switchEditMode(true, undefined)} sx={{ fontSize: '1rem' }} size="small">
						{intl.formatMessage({ id: 'common.add_address' })}
					</Button>
				</Grid>

				{addresses.map((dir) => {
					return (
						<Grid item xs={12} key={dir.id}>
							<Accordion defaultExpanded={addresses.length === 1}>
								<AccordionSummary expandIcon={<ExpandMore sx={{ height: '2rem', width: '2rem' }} />}>
									<Typography variant="h6" pl={'1.5rem'} fontWeight={700}>
										{dir.alias}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<AddressDetails
										handleDelete={(addres: ConstructionAddress) => {
											setAddressToDelete(addres);
											setShowDeleteModal(true);
										}}
										switchEditMode={(edition) => switchEditMode(edition, dir)}
										constAddress={dir}
									/>
								</AccordionDetails>
							</Accordion>
						</Grid>
					);
				})}
			</Grid>
			<Modal open={showDeleteModal} onKeyDown={(event) => event.key === 'Escape' && setShowDeleteModal(false)}>
				<Fade in={showDeleteModal}>
					<Box>
						<GenericConfirmationModal
							isLoading={modalLoading}
							title={intl.formatMessage({ id: 'settings.company.addresses_tab.remove_address' })}
							message={intl.formatMessage({ id: 'settings.company.addresses_tab.remove_address_confirmation' })}
							primaryButtonText={intl.formatMessage({
								id: 'common.delete',
							})}
							primaryButtonCallback={onDeleteModal}
							secondaryButtonText={intl.formatMessage({
								id: 'common.cancel',
							})}
							secondaryButtonCallback={onCancelModal}
						></GenericConfirmationModal>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
};

export default AddressesTab;
