import { FormData, SignUpStep } from './interfaces';

const urlRegex = /^(https?:\/\/|www\.)[^\s/$.?#][^\s]*\.[^\s]+$/i;
const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const digitOnlyRegex = /^\d+$/;
const phoneRegex = /^\+?\d+$/;

export const areErrorsPresent = (step: string, formErrors: any) => {
	const firstStepValues = ['accountType', 'cuit', 'businessName'];

	if (step === SignUpStep.ACCOUNT_CHECK) {
		return Object.entries(formErrors)
			.filter((elem: any) => firstStepValues.includes(elem[0]))
			.some((error) => error[1] !== '');
	} else {
		return Object.values(formErrors).some((error) => error !== '');
	}
};

export const validateForm = (step: string, formData: FormData, setFormErrors: Function) => {
	let valid = true;
	const errors = {
		accountType: '',
		cuit: '',
		businessName: '',
		cuitProof: '',
		fullName: '',
		phone: '',
		password: '',
		mail: '',
		confirmedMail: '',
		workArea: '',
		category: '',
		passwordRepeat: '',
		role: '',
	};
	switch (step) {
		case SignUpStep.ACCOUNT_CHECK:
			if (formData.accountType.trim() === '') {
				errors.accountType = 'El tipo de empresa es requerido';
				valid = false;
			}

			if (formData.cuit.trim() === '') {
				errors.cuit = 'El CUIT es requerido';
				valid = false;
			} else if (formData.cuit.length !== 11) {
				errors.cuit = 'El CUIT debe tener 11 dígitos';
				valid = false;
			} else if (formData.cuit[0] === '-') {
				errors.cuit = 'El CUIT debe ser un número mayor a cero';
				valid = false;
			}
			else if (!digitOnlyRegex.test(formData.cuit)) {
                errors.cuit = 'El CUIT solo debe contener números';
                valid = false;
            }

			if (formData.businessName.trim() === '') {
				errors.businessName = 'La razón social es requerida';
				valid = false;
			}
			break;
		case SignUpStep.CONSTRUCTOR_CREATION:
			if (formData.fullName.trim() === '') {
				errors.fullName = 'El nombre completo es requerido';
				valid = false;
			}
			if (formData.phone.trim() === '') {
				errors.phone = 'El teléfono es requerido';
				valid = false;
			} else if (!phoneRegex.test(formData.phone)) {
				errors.phone = 'El teléfono no es válido, ingresá únicamente dígitos';
				valid = false;
			}
			if (formData.password.trim() === '') {
				errors.password = 'La contraseña es requerida';
				valid = false;
			} else if (formData.password.length < 8) {
				errors.password = 'La contraseña debe tener por lo menos 8 caracteres';
				valid = false;
			} else if (formData.passwordRepeat !== formData.password) {
				errors.passwordRepeat = 'Las contraseñas no coinciden';
				valid = false;
			}
			if (formData.mail.trim() === '') {
				errors.mail = 'El mail es requerido';
				valid = false;
			} else if (!emailRegex.test(formData.mail)) {
				errors.mail = 'El mail no es válido';
				valid = false;
			}
			if (formData.mail !== formData.confirmedMail) {
				errors.confirmedMail = 'Los mails no coinciden';
				valid = false;
			}
			if (formData.workArea.trim() === '') {
				errors.workArea = 'El área de trabajo es requerido';
				valid = false;
			}
			break;
		case SignUpStep.SELLER_CREATION:
			if (formData.fullName.trim() === '') {
				errors.fullName = 'El nombre completo es requerido';
				valid = false;
			}
			if (formData.role.trim() === '') {
				errors.role = 'El rol es requerido';
				valid = false;
			}
			if (formData.category.trim() === '') {
				errors.category = 'El tipo de empresa es requerido';
				valid = false;
			}
			if (formData.phone === '+') {
                errors.phone = 'El teléfono es requerido';
                valid = false;
            } else if (!phoneRegex.test(formData.phone)) {
                errors.phone = 'El teléfono no es válido, ingresá únicamente dígitos';
                valid = false;
            }
			if (formData.mail.trim() === '') {
				errors.mail = 'El mail es requerido';
				valid = false;
			} else if (!emailRegex.test(formData.mail)) {
				errors.mail = 'El mail no es válido';
				valid = false;
			}
			break;
		default:
			valid = true;
			break;
	}
	setFormErrors(errors);
	return valid;
};

export const hideEmail = (email: string) => {
	// Check if the email is valid to prevent errors
	if (email && email.includes('@')) {
		const parts = email.split('@');
		const localPart = parts[0];
		const domainPart = parts[1];

		// Ensure there's enough length to hide
		if (localPart.length > 2) {
			const visibleLocalPart = localPart.substring(0, 2);
			const hiddenLocalPart = '*'.repeat(localPart.length - 2);
			return `${visibleLocalPart}${hiddenLocalPart}@${domainPart}`;
		}
	}

	return email; // If not a valid email, return it unchanged
};
