import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useIntl } from 'react-intl';
import BaseTable, { DataRow } from '../../../baseTable';
import { historyDialogColumns } from '../columns';
import { Dialog } from '@mui/material';
import emptyStateLogo from '../../../../assets/wallet-empty-state.svg';
import styled from '@emotion/styled';

interface Props {
	open: boolean;
	onClose: () => void;
	data: any[];
	actionButton: (row: DataRow) => void;
	loading: boolean;
}

const EmptyStateImage = styled.img`
	width: 300px;
`;

const HistoryTokensDialog = (props: Props) => {
	const { open, onClose, data, actionButton, loading } = props;
	const intl = useIntl();
	return (
		<Dialog
			onClose={!!loading ? () => {} : onClose}
			open={open}
			maxWidth={'lg'}
			sx={{
				'.MuiDialog-paper': {
					borderRadius: '8px',
				},
			}}
		>
			<DialogTitle
				sx={{
					borderColor: '#C6C6C6',
				}}
			>
					{intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.history_tokens.title' })}
			</DialogTitle>
			<DialogContent>
				{!!loading ? (
					<Box
						sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40vh', color: 'grey.500' }}
					>
						<CircularProgress color="inherit" />
					</Box>
				) : (
					data.length > 0 && <BaseTable columns={historyDialogColumns(actionButton)} data={data} maxHeightOnPx={300} TableContainerOverflowX='auto' />
				)}
				{!loading && data.length === 0 && (
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<EmptyStateImage src={emptyStateLogo} alt="empty-state-logo" />
						<Typography
							variant="h5"
							sx={{
								marginBottom: '3rem',
								marginTop: '0.5rem',
								fontSize: '24px',
								color: '#707070',
								width: '35%',
								textAlign: 'center',
							}}
						>
							{intl.formatMessage({ id: 'mytokens.seller.dashboard.transactions.empty_state' })}
						</Typography>
					</Box>
				)}
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
				<Button
					disabled={!!loading}
					onClick={onClose}
					sx={{ 
						width: '162px',
						backgroundColor: '#FFF',
						color: '#406AFF',
						border: '1px solid #406AFF',
						borderRadius: '8px',
					}}
					variant="contained"
				>
					{intl.formatMessage({ id: 'common.close' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default HistoryTokensDialog;
