import { Card, CardContent, Grid, Box, Typography, Stack, CardProps } from "@mui/material";
import { formatNumberWithCurrency } from "../../../utils/data-utils";
import StackDataDisplay from "../../base-components/stack-data-display";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { StackedData } from "../../../types";

interface Props extends CardProps {
    stockSafekeepingOrder: any,
    sSks: any,
    bonusCondition: any,
    shippingCondition: any,
    financingCondition: any
}

const CostBreakdownCardComponent = ({ stockSafekeepingOrder, sSks, bonusCondition, shippingCondition, financingCondition, ...rest }: Props) => {
    const intl = useIntl();
    const [costBreakdownFormatted, setCostBreakdownFormatted] = useState<StackedData[]>([]);

    useEffect(() => {

        setCostBreakdownFormatted(() => {
            const getDetailTitle = (name: string, value?: number, value_type?: string) => {
                let formattedValue = '';
                if (value) {
                    formattedValue = `(${value}${value_type === 'percentage' ? '%' : ''})`;
                }
                return `${name} ${formattedValue}`;
            };

            let conditions: StackedData[] = [];

            if (!stockSafekeepingOrder || !sSks) {
                return conditions;
            }

            const bonusAmount = stockSafekeepingOrder.monto_descuento ?? 0;
            const shippingAmount = stockSafekeepingOrder.costo_envio ?? 0;
            const financingAmount = stockSafekeepingOrder.monto_interes ?? 0;
            const totalAmount = sSks.Monto_final ?? 0;
            const tokensAmount = totalAmount + bonusAmount - financingAmount - shippingAmount;

            conditions.push({
                title: intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.details.token_cost' }),
                objectDesc: <Typography variant="body2" whiteSpace={'pre-line'} textAlign={'end'}>{formatNumberWithCurrency(tokensAmount, 2)}</Typography>,
                description: formatNumberWithCurrency(tokensAmount, 2)
            });

            if (bonusCondition) {
                conditions.push({
                    title: getDetailTitle(bonusCondition.name, bonusCondition.value, bonusCondition.value_type),
                    objectDesc: <Typography variant="body2" whiteSpace={'pre-line'} textAlign={'end'} className="font-bold text-[#49ad0c]">{formatNumberWithCurrency(bonusAmount, 2)}</Typography>,
                    description: formatNumberWithCurrency(bonusAmount, 2)
                });
            }

            if (shippingCondition) {
                conditions.push({
                    title: getDetailTitle(shippingCondition.agreement_condition_type_label + ' - ' + shippingCondition.agreement_condition_zone_label),
                    objectDesc: <Typography variant="body2" whiteSpace={'pre-line'} textAlign={'end'}>{formatNumberWithCurrency(shippingAmount, 2)}</Typography>,
                    description: formatNumberWithCurrency(shippingAmount, 2)
                });
            }

            if (financingCondition) {

                conditions.push({
                    title: financingCondition.name,
                    objectDesc: financingAmount ? 
                        <Typography variant="body2" whiteSpace={'pre-line'} textAlign={'end'}>{formatNumberWithCurrency(financingAmount, 2)}</Typography> : 
                        <Typography variant="body2" whiteSpace={'pre-line'} textAlign={'end'} className="font-bold text-[#49ad0c]">{intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.details.financing.no_interest' })}</Typography>,
                    description: formatNumberWithCurrency(financingAmount, 2)
                })
            }

            conditions.push({
                title: intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.details.final_cost' }),
                objectDesc: <Typography variant="body2" whiteSpace={'pre-line'} textAlign={'end'} className="font-bold text-[#4b4b4b]">{formatNumberWithCurrency(totalAmount, 2)}</Typography>,
                description: formatNumberWithCurrency(totalAmount, 2)
            });

            return conditions;
        });

        return () => {
            setCostBreakdownFormatted([]);
        };
    }, [stockSafekeepingOrder, sSks, bonusCondition, shippingCondition, financingCondition]);

    return (
        <Card {...rest}>
            <CardContent sx={{ p: 4 }}>
                <Grid container spacing={2} justifyContent="space-between" >
                    <Grid item xs={12} md={5}>
                        <Box>
                            <Typography color={'text.primary'} variant='h5'>
                                {intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.details.title' })}
                            </Typography>
                        </Box>
                        <Box>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="body2">
                                    {intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.details.subtitle' })}
                                </Typography>
                                <Typography variant="body2">
                                    {formatNumberWithCurrency(stockSafekeepingOrder?.monto_unit_token ?? 0, 2)}
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <StackDataDisplay data={costBreakdownFormatted} descriptionTextAlign='right' ></StackDataDisplay>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default CostBreakdownCardComponent;