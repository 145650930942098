import { Box, Button, Grid, MenuItem, Paper, Stack, Typography } from '@mui/material';
import { Areas, User } from '../../../types';
import { construct } from '../organization/main-settings';
import { useMobile } from '../../../hooks/use-mobile';
import { useIntl } from 'react-intl';
import TextFieldWithPlaceholder from '../../text-field-with-placeholder';
import SelectWithPlaceholder from '../../select-with-placeholder';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import client from '../../../clients/client';
import GenericTabFrame from '../../base-components/generic-tab-frame';

interface userInfoEditionTabProps {
	callback: (editMode: boolean) => void;
	showRequiredError: (showError: boolean) => void;
	user: User;
	updateUserData: (userData: any) => void;
}

const UserInfoEditionTab: React.FC<userInfoEditionTabProps> = ({
	callback,
	showRequiredError,
	user,
	updateUserData,
}) => {
	const intl = useIntl();
	const isMobile = useMobile();
	const [mail, setMail] = useState(user.email);
	const [fullName, setFullName] = useState(user.name);
	const [phone, setPhone] = useState(user.phone ?? '');
	const [area, setArea] = useState(user.area ?? '');
	const [firstSubmit, setFirstSubmit] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const areas = [
		{
			value: Areas.PURCHASES,
			label: intl.formatMessage({ id: 'settings.my-account.area.purchase' }),
		},
		{
			value: Areas.CONSTRUCTIONS,
			label: intl.formatMessage({ id: 'settings.my-account.area.works' }),
		},
		{
			value: Areas.FINANCES,
			label: intl.formatMessage({ id: 'settings.my-account.area.administration_and_finance' }),
		},
		{
			value: Areas.TECHNICAL,
			label: intl.formatMessage({ id: 'settings.my-account.area.technical_office_and_tenders' }),
		},
	];

	const updateLocalData = () => {
		let localUser = JSON.parse(localStorage.getItem('user') ?? '');
		localUser.user.phone = phone;
		localUser.user.name = fullName;
		localUser.user.area = area;
		localStorage.setItem('user', JSON.stringify(localUser));
	};

	const handleSave = async () => {
		setFirstSubmit(true);
		let items = document.querySelectorAll(
			'.MuiInputBase-input, .MuiSelect-nativeInput'
		) as NodeListOf<HTMLInputElement>;
		let arrayItems = Array.from(items);
		//A little delay to ensure the state changes before evaluate it's aria-invalid attribute
		await new Promise((resolve) => setTimeout(resolve, 0));
		const anyInvalid = arrayItems?.some((item) => item.getAttribute('aria-invalid') === 'true');
		if (anyInvalid) {
			showRequiredError(true);
		} else {
			setIsSaving(true);
			showRequiredError(false);
			const data = { phone: phone, area: area, name: fullName };
			try {
				await client.updateUserPersonalInformation({ data: data, token: user.token });
				updateLocalData();
				updateUserData(data);
			} catch (error) {
				console.log({ error });
			} finally {
				setIsSaving(false);
				callback(false);
			}
		}
	};

	return (
		<>
			<GenericTabFrame
				title={intl.formatMessage({ id: 'settings.my-account.general.title' })}
				subtitle={intl.formatMessage({ id: 'common.required_fields' })}
			>
				<Stack justifyContent={'space-between'} width={'100%'} minHeight={'300px'}>
					<TextFieldWithPlaceholder
						label={intl.formatMessage({ id: 'settings.my-account.general.mail' })}
						disabled={true}
						fullWidth
						value={mail}
						required
					></TextFieldWithPlaceholder>

					<TextFieldWithPlaceholder
						label={intl.formatMessage({ id: 'settings.my-account.general.full_name' })}
						required
						fullWidth
						onChange={(event) => setFullName(event.target.value as string)}
						value={fullName}
						error={firstSubmit && fullName === '' ? true : false}
					></TextFieldWithPlaceholder>

					<TextFieldWithPlaceholder
						label={intl.formatMessage({ id: 'settings.my-account.general.phone' })}
						fullWidth
						onChange={(event) => setPhone(event.target.value as string)}
						value={phone}
						error={firstSubmit && phone === '' ? true : false}
						required
					></TextFieldWithPlaceholder>

					<SelectWithPlaceholder
						label={intl.formatMessage({ id: 'settings.my-account.general.area' })}
						placeholder={''}
						onChange={(event) => setArea(event.target.value as string)}
						fullWidth
						value={area}
						error={firstSubmit && area === '' ? true : false}
						required
					>
						{areas.map((item) => (
							<MenuItem key={item.value} value={item.value} id={item.value}>
								{item.label}
							</MenuItem>
						))}
					</SelectWithPlaceholder>
				</Stack>
			</GenericTabFrame>
			<Paper elevation={0} sx={{ pb: 3, px: 5, display:'flex', justifyContent: 'end' }}>
				<Box>
					<Button disabled={isSaving} onClick={() => callback(false)} variant="outlined">
						{intl.formatMessage({ id: 'common.cancel' })}
					</Button>
					<LoadingButton
						loading={isSaving}
						disabled={isSaving}
						onClick={handleSave}
						variant="contained"
						sx={{ ml: '10px' }}
					>
						{intl.formatMessage({ id: 'common.save' })}
					</LoadingButton>
				</Box>
			</Paper>
		</>
	);
};

export default UserInfoEditionTab;
