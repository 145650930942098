import * as React from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { useMobile } from '../hooks/use-mobile';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

type Order = 'asc' | 'desc';

export type Column = {
	id: string;
	label: string | any;
	width?: string;
	align?: 'center' | 'left' | 'right';
	format?: (value: number) => string;
	mobile?: boolean;
	orderable?: boolean;
};

export interface ValueRenderCell {
	value: any;
	render: React.ReactNode;
}

const isValueRenderCell = (value: any): value is ValueRenderCell => !!value?.render;

interface PaginatableTableProps {
	columns: Column[];
	rows: any[];
	isEmptyDataSource: boolean;
	emptyDataSourceMessage: string;
	noResultsMessage: string;
	rowsPerPage?: number;
	disablePagination?: boolean;
	orderBy?: string;
	orderByDirection?: 'asc' | 'desc';
	overflowHidden?: boolean;
}

const PaginatableTable = (props: PaginatableTableProps) => {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);
	const [order, setOrder] = React.useState<'asc' | 'desc'>(props.orderByDirection ?? 'desc');
	const [orderBy, setOrderBy] = React.useState<string>(props.orderBy ?? 'date');
	const isMobile = useMobile();
	const intl = useIntl();
	const overflowHidden = props.overflowHidden ?? true;

	const setOrderable = (column: string) => {
		return (
			<Tooltip title="" style={{ marginLeft: '5px' }}>
				<IconButton onClick={() => handleRequestSort(column)}>
					<ArrowUpwardIcon
						style={{
							transform: `rotate(${order === 'desc' && orderBy === column ? '180deg' : '0deg'})`,
							transition: 'transform 0.5s ease',
							height: '13px',
							width: '13px',
						}}
					/>
				</IconButton>
			</Tooltip>
		);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleRequestSort = (property: string) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const stableSort = (rows: any[], comparator: (a: any, b: any) => number) => {
		const stabilizedThis = rows.map((row, index) => [row, index] as [any, number]);

		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});

		return stabilizedThis.map((row) => row[0]);
	};

	React.useEffect(() => {
		setPage(0);
	}, [props.rows]);

	React.useEffect(() => {
		setRowsPerPage(props.rowsPerPage ?? 20);
	}, [props.rowsPerPage]);

	const getDateComparator = (order: 'asc' | 'desc', columnName = 'date') => {
		return (a: any, b: any) => {
			const dateA = a[columnName] !== '-' ? new Date(a[columnName].split('/').reverse().join('/')).getTime() : 0;
			const dateB = b[columnName] !== '-' ? new Date(b[columnName].split('/').reverse().join('/')).getTime() : 0;
			return order === 'desc' ? dateB - dateA : dateA - dateB;
		};
	};

	function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
		const aVal = isValueRenderCell(a[orderBy])
			? (a[orderBy] as ValueRenderCell).value
			: typeof a[orderBy] === 'string'
			? (a[orderBy] as string).toString().toLowerCase()
			: '';
		const bVal = isValueRenderCell(b[orderBy])
			? (b[orderBy] as ValueRenderCell).value
			: typeof b[orderBy] === 'string'
			? (b[orderBy] as string).toString().toLowerCase()
			: '';

		if (aVal === '-' && bVal !== '-') return -1;
		if (bVal === '-' && aVal !== '-') return 1;

		if (bVal < aVal) {
			return -1;
		}

		if (bVal > aVal) {
			return 1;
		}

		return 0;
	}

	function getComparator<Key extends keyof any>(
		order: Order,
		orderBy: Key
	): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	const renderRow = (row: any, disabled = false) => {
		const tableRow = (
			<TableRow
				key={`innerpage-${page}-${Math.random() * 21345}`}
				sx={disabled ? { opacity: 0.3, cursor: 'default' } : row.onClickUrl || row.onClick ? { cursor: 'pointer' } : {}}
				hover={!disabled}
				tabIndex={-1}
				onClick={() =>
					disabled
						? null
						: row.onClickUrl
						? window.open(row['onClickUrl'], '_blank')
						: row.onClick
						? row.onClick()
						: null
				}
			>
				{props.columns
					.filter((column) => !isMobile || column.mobile !== false)
					.map((column) => {
						const value = isValueRenderCell(row[column.id]) ? row[column.id].render : row[column.id];
						return (
							<TableCell
								sx={{
									borderBottom: row.cellBorder === false ? '0' : '1',
								}}
								style={overflowHidden ? {
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									backgroundColor: '#fff0',
								} : {}}
								key={column.id}
								align={column.align}
							>
								<Typography variant='body2' className='font-medium text-[#2f2f3c]'>
									{column.format && typeof row[column.id] === 'number' ?
										column.format(row[column.id]) :
										value ?? '-'}
								</Typography>
							</TableCell>
						);
					})}
			</TableRow>
		);

		if (row.disabled) {
			return (
				<Tooltip
					title={row.disabledMessage ?? intl.formatMessage({ id: 'common.table.disabled_row_message' })}
					slotProps={{
						popper: {
							modifiers: [
								{
									name: 'offset',
									options: {
										offset: [0, -14],
									},
								},
							],
						},
					}}
				>
					{tableRow}
				</Tooltip>
			);
		}

		return tableRow;
	};

	const getOrderedRows = () => {
		let rows = [];

		switch (orderBy) {
			case 'date':
				rows = stableSort(props.rows, getDateComparator(order));
				break;
			default:
				rows = stableSort(props.rows, getComparator(order, orderBy));
				break;
		}

		return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
			return renderRow(row, row.disabled);
		});
	};

	return (
		<Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
			<TableContainer sx={{ minHeight: '10vh' }}>
				<Table style={{ tableLayout: 'fixed' }}>
					<TableHead>
						<TableRow key={`page-${page}`}>
							{props.columns
								.filter((column) => !isMobile || column.mobile !== false)
								.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{
											width: column.width,
											fontSize: '13px',
											fontWeight: 500,
											color: '#8D8D98',
										}}
									>
										<Box display="flex" alignItems="center" justifyContent={column.align || 'left'}>
											<Tooltip arrow placement="top" title={column.label}>
												<Box
													display="flex-block"
													style={ overflowHidden ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } : {} }
												>
													{column.label}
												</Box>
											</Tooltip>
											{(column.id === 'date' || column.orderable) && setOrderable(column.id)}
										</Box>
									</TableCell>
								))}
						</TableRow>
					</TableHead>
					<TableBody>
						{props.rows.length ? (
							getOrderedRows()
						) : (
							<TableRow hover tabIndex={-1} key={`extrainnerpage-${Math.random() * 21345}`}>
								<TableCell
									align="center"
									colSpan={isMobile ? props.columns.filter((col) => col.mobile !== false).length : props.columns.length}
								>
									<Typography>
										{props.isEmptyDataSource ? props.emptyDataSourceMessage : props.noResultsMessage}
									</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{props.disablePagination ?? (
				<TablePagination
					component="div"
					count={props.rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					rowsPerPageOptions={[rowsPerPage]}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelDisplayedRows={({ from, to, count }) => `${from} - ${to} ${'de'} ${count}`}
					sx={{ mt: '20px' }}
				/>
			)}
		</Paper>
	);
};

export { PaginatableTable };
