import { atom } from "recoil"

const agreementsOptionsState = atom({
  key: 'agreementsOptionsState',
  default: {
    agreementsOptions: [],
    isLoading: true,
  },
});

export default agreementsOptionsState;
