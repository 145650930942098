import { Box, Button, Divider, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { StackedData, User } from '../../../types';
import { construct } from '../organization/main-settings';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useMobile } from '../../../hooks/use-mobile';
import { useIntl } from 'react-intl';
import { blue } from '@mui/material/colors';
import GenericTabFrame from '../../base-components/generic-tab-frame';
import StackDataDisplay from '../../base-components/stack-data-display';
import EditButton from '../../nuqlea-components/edit-button';

interface generalTabProps {
	callback: (isEditionMode: boolean) => void;
	user: User;
}

const UserInfoTab: React.FC<generalTabProps> = ({ callback, user }) => {
	const intl = useIntl();
	const isMobile = useMobile();

	const data: StackedData[] = [
		{
			title: intl.formatMessage({ id: 'settings.my-account.general.mail' }),
			description: user.email,
		},
		{
			title: intl.formatMessage({ id: 'settings.my-account.general.full_name' }),
			description: user.name,
		},
		{
			title: intl.formatMessage({ id: 'settings.my-account.general.phone' }),
			description: user.phone,
		},
		{
			title: intl.formatMessage({ id: 'settings.my-account.general.area' }),
			description: user.area ? intl.formatMessage({ id: `settings.my-account.area.${user.area}` }) : '',
		},
	];

	return (
		<GenericTabFrame
			title={intl.formatMessage({ id: 'settings.my-account.general.title' })}
			subtitle={intl.formatMessage({ id: 'settings.my-account.general.subtitle' })}
			buttons={[<EditButton onClick={() => callback(true)} text={intl.formatMessage({ id: 'settings.company.purchases_tab.edit' })}></EditButton>]}
		>
			<StackDataDisplay data={data}></StackDataDisplay>
		</GenericTabFrame>
	);
};

export default UserInfoTab;
