import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { InputLabel, MenuItem, styled, Alert, Autocomplete, Chip, Checkbox, Select } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FileType, Document, ListDetails, SellerListDetails, User } from '../../../types';
import FileUpload from 'react-material-file-upload';
import FullScreenLoading from '../../full-screen-loading';
import client from '../../../clients/client';
import DatePicker from '../../date-picker';

const SeparatedLabel = styled(InputLabel)`
	margin-top: 15px;
	margin-bottom: 5px;
`;

const FileUploadWrapper = styled('div')(({ theme }) => ({
	'& > *': {
		border: `2px dashed  ${theme.palette.divider} !important`,
		backgroundColor: theme.palette.background.default,
	},
	'& .MuiFormControl-root': {
		flexDirection: 'row',
		alignItems: 'center',
		gap: 8,
		'& .MuiTypography-root': {
			fontSize: '1em',
			paddingTop: 20,
			paddingBottom: 20,
		},
		'& .MuiSvgIcon-root': {
			fill: '#0d6efd',
			fontSize: 30,
			marginBottom: 4,
			marginLeft: 30,
		},
		'& .MuiButtonBase-root': {
			position: 'absolute',
			background: 'transparent',
			color: 'transparent',
			width: '100%',
			height: '10vh',
			marginBottom: -8,
			marginLeft: 0,
		},
	},
}));

interface Props {
	open: boolean;
	types: FileType[];
	documents: Document[];
	dataConstructor?: ListDetails;
	dataSeller?: SellerListDetails;
	user: User;
	onClose: (event?: object | null, reason?: string, newFile?: Document) => void;
	tokenization?: boolean;
	redeemRequest?: boolean;
	dataTokenization?: any;
	dataRedeemRequest?: any;
	selectedType?: number;
}

const FileUploadDialog = (props: Props) => {
	const intl = useIntl();
	const {
		open,
		types,
		documents,
		dataConstructor,
		dataSeller,
		user,
		onClose,
		tokenization,
		redeemRequest,
		dataTokenization,
		dataRedeemRequest,
		selectedType
	} = props;

	const [type, setType] = useState<number>(selectedType ? selectedType : 0);
	const [fileNumber, setFileNumber] = useState<string>();
	const [files, setFiles] = useState<File[]>([]);
	const [relations, setRelations] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>();
	const [error, setError] = useState<string>();
	const [fileLabel, setFileLabel] = useState<string>('');
	const [fileRestrictionsLabel, setFileRestrictionsLabel] = useState<string>(
		'list.details.file_upload_dialog.file_restrictions'
	);
	const [date, setDate] = useState<Date | undefined>();
	
	const handleSubmit = () => {
		setLoading(true);
		setError(undefined);

		const file = files[0];

		if (!type) return;

		if (date) {
			date?.setHours(24);
		}

		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve((reader.result as string).split(',').pop());
			reader.onerror = (error) => reject(error);
		}).then((data) => {
			const functionName = (
				redeemRequest ? 'uploadRedeemRequestDriveFile' : tokenization ? 'uploadTokenDriveFile' : 'uploadDriveFile'
			) as string;

			(client as any)
				[functionName]({
					fileName: file.name,
					fileType: file.type,
					fileData: data,
					type: fileLabel,
					user,
					relatedFiles: relations,
					listId: dataConstructor ? dataConstructor?.metadata.list_id : dataSeller?.metadata.list_id,
					listMigrationId: dataConstructor
						? dataConstructor.metadata.quotation_id
						: dataSeller?.metadata.list_migration_id,
					orderId: tokenization
						? dataTokenization?.order_id
						: dataConstructor
						? dataConstructor?.metadata.order_id
						: dataSeller?.quotation.order_id,
					orderMigrationId: dataConstructor
						? dataConstructor?.metadata.order_migration_id
						: dataSeller?.quotation.order_migration_id,
					listEnvironment: dataConstructor ? dataConstructor?.metadata.environment : dataSeller?.metadata.env_list,
					filesFolderId: redeemRequest
						? dataRedeemRequest?.files_folder_id
						: tokenization
						? dataTokenization?.files_folder_id
						: dataConstructor
						? dataConstructor?.metadata.files_folder_id
						: dataSeller?.metadata.files_folder_id,
					documentId: fileNumber,
					documentDate: date ? date?.toISOString().split('T')[0] : null,
					sskId: dataTokenization?.id,
					redeemRequestId: dataRedeemRequest?.id,
					redeemType: dataRedeemRequest?.type,
				})
				.then((response: any) => {
					onClose(null, 'success', {
						file_id: tokenization ? response.fileKey : response.file_key,
						id_doc: fileNumber ?? '',
						list_id: dataConstructor
							? dataConstructor.metadata.list_id
							: dataSeller
							? dataSeller.metadata.list_id
							: null,
						order_id: '',
						comment: null,
						file_type_id: type,
						file_path: '',
						drive_file_id: response.fileId || response.file_id,
						label_file_type: fileLabel,
						created_by: user.email,
						created_by_name: user.email.includes('@nuqlea.com') ? 'NUQLEA' : user.name,
						parent_documents_ids: relations.join(','),
						timestamp: response.dateLoaded || response.date_loaded,
						constructor_id: user.type_external,
						children: [],
						created_at: response.createdAt,
					});

					// Reset form
					setType(0);
					setFileNumber(undefined);
					setFileLabel('');
					setFiles([]);
				})
				.catch((error: any) => setError(error.message))
				.finally(() => setLoading(false));
		});
	};

	const icon = <CheckBoxOutlineBlankIcon color="inherit" />;
	const checkedIcon = <CheckBoxIcon sx={{ color: '#000' }} />;

	const handleFileTypeToLabel = (type: number) => {
		setType(type);
		setRelations([]);
		switch (type) {
			case 1:
				setFileLabel('Pedido de cotización original');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions_4');
				break;
			case 2:
				setFileLabel('Planos de cotización');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions_4');
				break;
			case 3:
				setFileLabel('Cronograma de entrega');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions_4');
				break;
			case 4:
				setFileLabel('Presupuesto de referencia');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions_4');
				break;
			case 6:
				setFileLabel('Factura');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions');
				break;
			case 7:
				setFileLabel('Orden de compra');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions');
				break;
			case 8:
				setFileLabel('Proforma');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions_3');
				break;
			case 9:
				setFileLabel('Comprobante de pago');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions_3');
				break;
			case 10:
				setFileLabel('Comprobante de retenciones');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions_3');
				break;
			case 11:
				setFileLabel('Órden de pago');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions_3');
				break;
			case 13:
				setFileLabel('Nota de crédito');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions');
				break;
			case 14:
				setFileLabel('Recibo de pago');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions_3');
				break;
			case 15:
				setFileLabel('Nota de débito');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions');
				break;
			case 22:
				setFileLabel('Cronograma de pago');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions_4');
				break;
			default:
				setFileLabel('Otro');
				setFileRestrictionsLabel('list.details.file_upload_dialog.file_restrictions_4');
				break;
		}
	};

	const handleFileLoad = (files: File[]) => {
		setError('');

		if (files.length) {
			const FILES_MAX_SIZE = 4;

			if (files[0].size / 1024 / 1024 >= FILES_MAX_SIZE) {
				setError(`El archivo es demasiado grande y supera el máximo de 4MB`);
				return;
			}

			if ([6, 7, 13, 15].includes(type) && files[0].type !== 'application/pdf') {
				setError('Solamente se aceptan los tipos de archivos PDF');
				return;
			} else if (
				[8, 9, 10, 11, 14].includes(type) &&
				files[0].type !== 'application/pdf' &&
				!files[0].type.startsWith('image/')
			) {
				setError('Solamente se aceptan los tipos de archivos PDF o imágen');
				return;
			} else if (
				![1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 13, 14, 15].includes(type) &&
				files[0].type !== 'application/pdf' &&
				files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
				files[0].type !== 'application/vnd.ms-excel' &&
				!files[0].type.startsWith('image/')
			) {
				setError('Solamente se aceptan los tipos de archivos PDF, MS Excel o imágen');
				return;
			}
		}

		setFiles(files);
	};

	const onAutocompleteChange = (event: any, newValue?: Document | Document[] | null) => {
		console.log(newValue);
		if (newValue && Array.isArray(newValue)) {
			console.log(
				'isArray',
				newValue.map((opt) => opt.file_id)
			);
			setRelations(newValue.map((opt) => opt.file_id));
		} else {
			console.log('isNotArray', (newValue as any)?.file_id);
			if (newValue) setRelations([(newValue as any)?.file_id]);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" sx={{ '& .MuiPaper-root': { maxWidth: '500px' } }}>
			{loading && <FullScreenLoading />}
			<DialogTitle>{intl.formatMessage({ id: 'list.details.file_upload_dialog.title' })}</DialogTitle>
			<DialogContent>
				<SeparatedLabel sx={{ fontWeight: 600 }}>
					{intl.formatMessage({ id: 'list.details.file_upload_dialog.type_field_name' })}
				</SeparatedLabel>
				<Select
					fullWidth
					size="small"
					required
					value={type}
					onChange={(event) => handleFileTypeToLabel(event.target.value as number)}
				>
					{types.map((type) => (
						<MenuItem key={type.id} value={type.id}>
							{type.name}
						</MenuItem>
					))}
				</Select>

				{![3, 4].includes(type) && (
					<>
						<SeparatedLabel sx={{ fontWeight: 600 }}>
							{intl.formatMessage({ id: 'list.details.file_upload_dialog.file_number_field_name' }) +
								' ' +
								fileLabel.toLowerCase()}
							{[6, 8, 13, 14, 15].includes(type) && '*'}
						</SeparatedLabel>
						<TextField
							required={[6, 8, 13, 14, 15].includes(type)}
							fullWidth
							variant="outlined"
							size="small"
							value={fileNumber}
							onChange={(event) => setFileNumber(event.target.value)}
						/>
					</>
				)}

				{[6].includes(type) && (
					<>
						<SeparatedLabel sx={{ fontWeight: 600 }}>
							{intl.formatMessage({ id: 'list.details.file_upload_dialog.invoice_emitting_date' })}
						</SeparatedLabel>
						<DatePicker
							value={date}
							onChange={(value) => {
								setDate(value);
							}}
							variant="form"
						/>
					</>
				)}

				{[9, 10, 11, 13, 14, 15].includes(type) && documents?.length > 0 && (
					<>
						<SeparatedLabel sx={{ fontWeight: 600 }}>
							{intl.formatMessage({ id: 'list.details.file_upload_dialog.related_to_field_name' })}
						</SeparatedLabel>
						<Autocomplete
							multiple={![10, 13, 15].includes(type)}
							disableCloseOnSelect
							onChange={onAutocompleteChange}
							options={documents}
							getOptionLabel={(option) =>
								`${option.label_file_type}${option.id_doc ? ' N° ' + option.id_doc : ''} | ${intl.formatDate(
									option.timestamp,
									{ timeZone: 'UTC' }
								)}`
							}
							renderTags={(value: readonly Document[], getTagProps) =>
								value.map((option: Document, index: number) => (
									<Chip
										variant="filled"
										label={`${option.label_file_type}${option.id_doc ? ' N° ' + option.id_doc : ''} | ${intl.formatDate(
											option.timestamp,
											{ timeZone: 'UTC' }
										)}`}
										{...getTagProps({ index })}
									/>
								))
							}
							renderOption={(props, option, { selected }) => (
								<li {...props}>
									<Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
									{`${option.label_file_type}${option.id_doc ? ' N° ' + option.id_doc : ''} | ${intl.formatDate(
										option.timestamp,
										{ timeZone: 'UTC' }
									)}`}
								</li>
							)}
							renderInput={(params) => <TextField {...params} fullWidth variant="outlined" size="small" />}
						></Autocomplete>
					</>
				)}

				<SeparatedLabel sx={{ fontWeight: 600, display: 'flex', flexDirection: 'column' }}>
					{intl.formatMessage({ id: 'list.details.file_upload_dialog.file_field_name' })}
					<small style={{ fontSize: '.74em' }}>
						{intl.formatMessage({
							id: fileRestrictionsLabel,
						})}
					</small>
				</SeparatedLabel>
				<FileUploadWrapper>
					<FileUpload
						value={files}
						onChange={(files: File[]) => {
							handleFileLoad(files);
						}}
						multiple={false}
						buttonText={intl.formatMessage({ id: 'common.upload' })}
						title={intl.formatMessage({ id: 'list.details.file_upload_dialog.file_field_placeholder' })}
						sx={{
							borderRadius: 4,
							mt: 1,
							'& .MuiFormControl-root': {
								display: files && files.length ? 'none' : 'flex',
							},
						}}
					/>
				</FileUploadWrapper>
				{error && (
					<Alert severity="error" sx={{ mt: 2 }}>
						{error ?? 'Ocurrió un error.'}
					</Alert>
				)}
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', gap: 1 }}>
				<Button disabled={Boolean(error) || !!loading} onClick={() => onClose(null, 'cancelButtonClick')} variant="outlined" sx={{ width: '120px' }}>
					{intl.formatMessage({ id: 'common.close' })}
				</Button>
				<Button
					disabled={(!type ||
						files.length === 0 ||
						([6, 8, 13, 14, 15].includes(type) && !fileNumber) ||
						([13, 14, 15].includes(type) && !relations) ||
						(type === 6 && !date)) || !!loading
					}
					onClick={handleSubmit}
					sx={{ width: '120px' }}
					variant='contained'
				>
					{intl.formatMessage({ id: 'common.confirm' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FileUploadDialog;
