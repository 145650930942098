import React from 'react'
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Container,
	Grid,
	Paper,
	Typography,
	Box,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

const NewQuotationConfirmed = () => {
	const intl = useIntl();
	const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const listId = searchParams.get('listId') || '';

	return (
		<Container
			sx={{
				display: 'flex',
				pt: { xs: '100px', md: '86px', xl: '100px' },
			}}
			maxWidth="lg"
		>
			<Grid sx={{ ml: '5px' }} container spacing={2}>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Typography sx={{ pl: 2, fontSize: 32, fontWeight: 700 }} variant="h3" gutterBottom>
						{intl.formatMessage({ id: 'new_quotation.title' })}
					</Typography>
				</Box>
				<Paper
					elevation={0}
					sx={{
						padding: '25px',
						marginTop: '15px',
						width: '100%',
						borderRadius: 2,
						height: '100%',
					}}
				>
          <Card raised={false} elevation={0}>
            <CardHeader
              title={
                <Typography variant="h5" textAlign="center" sx={{ fontWeight: 'bold' }}>
                  {intl.formatMessage({ id: 'new_quotation.quotation_submitted_title' })}
                </Typography>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" textAlign="center" sx={{ maxWidth: '60%', margin: 'auto' }}>
                    {intl.formatMessage({ id: 'new_quotation.quotation_submitted_details_1' }, { listId })}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" textAlign="center" sx={{ maxWidth: '60%', margin: 'auto' }}>
                    {intl.formatMessage({ id: 'new_quotation.quotation_submitted_details_2' }, { listId })}
                  </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    onClick={() => navigate(`/list/${listId}`)}
                    variant="contained"
                  >
                    {intl.formatMessage({ id: 'new_quotation.go_to_quotation_button' })}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
				</Paper>
			</Grid>
		</Container>
	);
}

export default NewQuotationConfirmed