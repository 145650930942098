import PageContainer from '../page-container';
import EmptyState from '../empty-state/empty-state';
import { FeatureNames, Features } from '../../types';
import Tokens from './dashboard/tokens';
import { useState } from 'react';
import EmptyStatesClient from '../../clients/EmptyStatesClient';
import { useIntl } from 'react-intl';
import BasePageFrame from '../base-components/base-page-frame';
import userState from '../../atoms/user-state';
import { useRecoilValue } from 'recoil';
import useOneTimeNotification from '../../hooks/use-one-time-notifications';

interface Props {
	checkPageVisualization: boolean;
}

const MyTokensEmptyState = (props: Props) => {
	const intl = useIntl();
	const user = useRecoilValue(userState);
	useOneTimeNotification();
	const pageViewed = EmptyStatesClient.pageHasAlreadyViewed(FeatureNames.STOCKPILE, user);
	const [showEmptyState, setShowEmptyState] = useState<boolean>(!pageViewed);

	return showEmptyState ? (
		<BasePageFrame
			title={intl.formatMessage({ id: 'mytokens.empty_state.title' })}
			subtitle={intl.formatMessage({ id: 'mytokens.empty_state.subtitle' })}
			transparent
		>
			<EmptyState
				user={user}
				pageIdentifier={Features.EMPTY_STATE_STOCKPILE}
				banner={{
					title: intl.formatMessage({ id: 'mytokens.empty_state.banner_title' }),
					cta: {
						label: intl.formatMessage({ id: 'mytokens.empty_state.banner_cta' }),
						onClick: () => setShowEmptyState(false),
					},
					video: 'https://www.youtube.com/embed/nWswY7DseKk',
				}}
				items={[
					{
						title: intl.formatMessage({ id: 'mytokens.empty_state.banner.first_section.title' }),
						subtitle: intl.formatMessage({ id: 'mytokens.empty_state.banner.first_section.subtitle' }),
					},
					{
						title: intl.formatMessage({ id: 'mytokens.empty_state.banner.second_section.title' }),
						subtitle: intl.formatMessage({ id: 'mytokens.empty_state.banner.second_section.subtitle' }),
					},
					{
						title: intl.formatMessage({ id: 'mytokens.empty_state.banner.third_section.title' }),
						subtitle: intl.formatMessage({ id: 'mytokens.empty_state.banner.third_section.subtitle' }),
					},
				]}
				token={user.token}
			/>
		</BasePageFrame>
	) : (
		<Tokens toggleEmptyState={setShowEmptyState} />
	);
};

export default MyTokensEmptyState;
