import styled from '@emotion/styled';
import { Check, Circle, ErrorOutline } from '@mui/icons-material';
import {
	Grid,
	Step,
	StepConnector,
	StepIconProps,
	StepLabel,
	Stepper,
	Typography,
	stepConnectorClasses,
	Box,
	IconButton,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useMobile } from '../hooks/use-mobile';
import { useNavigate } from 'react-router-dom';
import Card from './card';
import { QuoteStatus } from '../types';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const StepWrapper = styled('div')((props: { backgroundColor: string; animated?: boolean }) => ({
	width: '38px',
	height: '38px',
	borderRadius: '50%',
	border: '1px solid #aaa',
	backgroundColor: props.backgroundColor,
	padding: '2px',
	animation: props.animated ? 'pulse-animation 2s infinite' : 'none',
}));

const StepCompletedIcon = styled(Check)`
	color: #fff;
	font-size: 32px;
`;

const StepErrorIcon = styled(ErrorOutline)`
	color: #fff;
	font-size: 32px;
`;

const StepActiveIcon = styled(Circle)`
	color: blue;
	font-size: 16px;
	margin: 8px;
`;

const Connector = styled(StepConnector)(() => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: '18px',
		left: 'calc(-50% + 30px)',
		right: 'calc(50% + 30px)',
	},
	[`&.${stepConnectorClasses.vertical}`]: {
		marginLeft: '19px',
	},
}));

const StepIcon = (props: StepIconProps) => {
	const { active, completed, error } = props;

	if (error) {
		return (
			<StepWrapper backgroundColor="red">
				<StepErrorIcon />
			</StepWrapper>
		);
	}

	if (completed) {
		return (
			<StepWrapper backgroundColor="#000">
				<StepCompletedIcon />
			</StepWrapper>
		);
	}

	if (active) {
		return (
			<StepWrapper backgroundColor="#fff" animated>
				<StepActiveIcon />
			</StepWrapper>
		);
	}

	return <StepWrapper backgroundColor="#ccc"></StepWrapper>;
};

export interface Action {
	displayName: string;
	icon: React.ReactElement;
	onClick: () => void;
}

export interface Status {
	label: string;
	warning?: string;
	active: boolean;
	completed: boolean;
}

interface Props {
	title?: string;
	titleLocation?: 'start' | 'center' | 'end';
	subtitleLines?: number | 1;
	leftSubtitle?: React.ReactElement;
	rightSubtitle?: React.ReactElement;
	cardbody?: React.ReactNode;
	actions?: Action[];
	statuses: Status[];
	orderStatus?: string;
	quotationId?: string;
	infoMessage?: React.ReactElement;
	loading?: boolean;
	backUrl?: string;
	elevation?: number;
}

const StatusCard = (props: Props) => {
	const {
		title,
		titleLocation,
		subtitleLines,
		leftSubtitle,
		rightSubtitle,
		actions,
		statuses,
		orderStatus,
		quotationId,
		infoMessage,
		cardbody,
		loading,
		backUrl,
		elevation = 1,
	} = props;
	const mobile = useMobile();
	const navigate = useNavigate();

	return (
		<Card title={title} orderStatus={orderStatus} titleLocation={titleLocation} actions={actions} loading={loading} elevation={elevation}>
			{loading ? (
				<Box>
					<Box
						sx={{
							position: 'relative',
							display: 'flex',
							justifyContent: 'space-evenly',
							mb: 4,
							maxWidth: '100%',
							mx: 'auto',
						}}
					>
						<Box
							sx={{
								width: '100%',
								maxWidth: '60%',
								mx: 'auto',
								background: '#ebebeb',
								height: '1px',
								top: '30%',
								position: 'absolute',
							}}
						></Box>
						{statuses.map((status) => (
							<Box
								sx={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', alignItems: 'center', gap: 1 }}
							>
								<Skeleton animation="wave" variant="circular" width={40} height={40} />
								<Skeleton animation="wave" width={60} height={20} />
							</Box>
						))}
					</Box>
				</Box>
			) : (
				<>
					{backUrl && (
						<Box sx={{ position: 'absolute', top: 26, left: 16 }}>
							<IconButton onClick={() => navigate(backUrl)}>
								<ArrowBackIosNewIcon fontSize="medium" />
							</IconButton>
						</Box>
					)}
					<Stepper
						alternativeLabel={!mobile}
						connector={<Connector />}
						orientation={mobile ? 'vertical' : 'horizontal'}
						sx={{
							pt: {
								md: 2,
								xs: 0,
							},
						}}
					>
						{statuses.map((status) => {
							if (!quotationId && status.label === QuoteStatus.EN_PROCESO_DE_COTIZACION) status.active = true;

							return (
								<Step key={status.label} active={status.active} completed={status.completed}>
									<StepLabel StepIconComponent={StepIcon} error={Boolean(status.warning)}>
										<Typography variant="subtitle1">{status.label}</Typography>
										<Typography variant="caption">{status.warning}</Typography>
									</StepLabel>
								</Step>
							);
						})}
					</Stepper>
					{cardbody}
					<Grid container sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
						<Grid item xs={12} md={5}>
							{infoMessage && <div>{infoMessage}</div>}
							{leftSubtitle}
						</Grid>
						{subtitleLines === 2 && <Grid item xs={0} md={3.5}></Grid>}
						<Grid item xs={12} md={subtitleLines === 1 ? 5 : 3.5} sx={{ textAlign: 'center' }}>
							{rightSubtitle}
						</Grid>
					</Grid>
				</>
			)}
		</Card>
	);
};

export default StatusCard;
