/* eslint-disable import/no-anonymous-default-export */
import axios, { AxiosResponse } from 'axios';
import {
	Action,
	ListDetails,
	Lists,
	Role,
	FilesList,
	DeliveriesList,
	Delivery,
	Document,
	User,
	UserResponse,
	SellerListDetails,
	Payment,
	Availability,
	CommentsList,
	Projects,
	FileWithContent,
	Location,
	AddressFromUrlResult,
	SellerWithDocuments,
	Collaborator,
	CollaboratorStatus,
	CollaboratorRole,
	Collaborators,
	RedeemRequestData,
	RedeemTokenViewDataProduct,
	RedeemTokenViewDataInfo,
	ConstructionAddressResponse,
	Seller,
	AgreementProduct,
} from '../types';
import { Notification } from '../atoms/one-time-notification-state';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export default {
	list: ({
		externalId,
		type,
		listStatus,
		migrationId,
		projectId,
		startDate,
		endDate,
		orderStatus,
		deliveryStatus,
		orderBy,
		desc,
		user,
	}: {
		type: Role;
		externalId?: string;
		listStatus?: string;
		migrationId?: string;
		projectId?: number;
		startDate?: string;
		endDate?: string;
		orderStatus?: string;
		deliveryStatus?: string;
		orderBy?: string;
		desc?: Boolean;
		user: User;
	}) => {
		let params: any = {
			id_external: externalId,
			type_external: type,
			list_status: listStatus,
			migration_id: migrationId,
			project_id: projectId,
			start_date: startDate,
			end_date: endDate,
			order_status: orderStatus,
			delivery_status: deliveryStatus,
			order_by: orderBy,
			desc: desc,
		};
		return axios
			.get<Lists>('/lists', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
				params,
			})
			.then(responseBody);
	},
	getAgreement: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>(`/constructor/agreements/${agreementId}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}`, 'Cache-Control': 'no-cache' },
			})
			.then(responseBody),
	getConstructorBusinessNames: ({ token }: { token: string }) =>
		axios
			.get<any>(`/constructor/agreements/business-names`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	getAgreementConditions: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>(`/agreements-conditions`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	getSellerId: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>(`/constructor/agreements/seller-id`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	getAgreementsState: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>('/constructor/agreements', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	getAgreements: ({ token }: { token: string }) =>
		axios
			.get<any>('/constructor/agreements/picker', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	getSellerAgreements: ({ token }: { token: string }) =>
		axios
			.get<any>('/seller/agreements/seller-picker', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	getAgreementsConditionsAndEquivalences: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>('/seller/agreements/conditions-and-equivalences', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	getAgreementsInfo: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>('/seller/agreements/info', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	getTokensHistory: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>('/seller/agreements/history', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	getSellerAgreementProducts: ({ token, agreementId }: { token: string; agreementId: string }) =>
		axios
			.get<any>(`/seller/agreements/${agreementId}/products`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` }
			})
			.then(responseBody),
	getSellerAgreement: ({ token, agreementId }: { token: string; agreementId: string }) =>
		axios
			.get<any>(`/seller/agreements/${agreementId}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	toggleCollection: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.put<any>(
				'/seller/agreements/toggle-collection',
				{},
				{
					headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
					params: { agreement_id: agreementId },
				}
			)
			.then(responseBody),
	getConstructorTokens: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>('/constructor/agreements/amounts', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	getSellerTokens: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>('/seller/agreements/amounts', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	generateTokens: ({ token, fields }: { token: string; fields: object }) =>
		axios
			.post<any>('/seller/agreements/generate-tokens', fields, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	getSellerSalesProcess: ({ token }: { token: string }) =>
		axios
			.get<any>('/seller/agreements/status/sales', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	getSellerExchangeProcess: ({ token }: { token: string }) =>
		axios
			.get<any>('/seller/agreements/status/exchange-process', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	getSellerSold: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>('/seller/agreements/status/sold', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	getSellerExchanged: ({ token }: { token: string }) =>
		axios
			.get<any>('/seller/agreements/status/exchanged', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	getConstructorPurchaseProcess: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>('/constructor/agreements/status/purchase', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	getConstructorExchangeProcess: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>('/constructor/agreements/status/exchange-process', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	getConstructorPurchased: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>('/constructor/agreements/status/purchased', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	getConstructorExchanged: ({ token, agreementId }: { token: string; agreementId?: string }) =>
		axios
			.get<any>('/constructor/agreements/status/exchanged', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { agreement_id: agreementId },
			})
			.then(responseBody),
	getConstructorData: ({ token, id }: { token: string; id: string }) =>
		axios
			.get<any>(`/constructor/${id}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { id: id },
			})
			.then(responseBody),
	updateConstructorData: ({ data, token }: { token: string; data: any }) =>
		axios.post<any>(`/user/update`, data, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
		}),
	createPurchaseBehavior: ({ data, token }: { token: string; data: any }) =>
		axios.post<any>(`/constructor/createPurchaseBehavior`, data, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
		}),
	getPurchaseBehavior: ({ token, id }: { token: string; id: string }) =>
		axios.get<any>(`/constructor/getPurchaseBehavior/${id}`, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			params: { id: id },
		}),
	updatePurchaseBehavior: ({ data, token }: { token: string; data: any }) =>
		axios.post<any>(`/constructor/updatePurchaseBehavior`, data, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
		}),
	createOrganizationAddress: ({ data, token }: { token: string; data: any }) =>
		axios.post<any>(`/constructor/createConstructionAddress`, data, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
		}),
	updateOrganizationAddress: ({ data, token }: { token: string; data: any }) =>
		axios.post<any>(`/constructor/updateConstructionAddress`, data, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
		}),
	deleteOrganizationAddress: ({ data, token }: { token: string; data: any }) =>
		axios.post<any>(`/constructor/deleteConstructionAddress`, data, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
		}),
	getOrganizationAddresses: ({ token, id }: { token: string; id: string }) =>
		axios.get<any>(`/constructor/getOrganizationAddresses/${id}`, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			params: { id: id },
		}),
	getAddress: ({ token, id }: { token: string; id: string }) =>
		axios.get<any>(`/constructor/getAddress/${id}`, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			params: { id: id },
		}),
	getSellerAddress: ({ token, id }: { token: string; id: string }) =>
		axios.get<any>(`/constructor/getSellerAddress/${id}`, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			params: { id: id },
		}),

	buyTokens: ({ token, fields }: { token: string; fields: object }) =>
		axios
			.post<any>('/stock-safekeepings/buy', fields, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	useAgremeentConditions: ({
		token,
		stock_safekeeping_id,
		redeem_request_id,
		agreements_conditions,
	}: {
		token: string;
		stock_safekeeping_id?: string;
		redeem_request_id?: string;
		agreements_conditions: any[];
	}) =>
		axios
			.post<any>(
				'/agreements-conditions/use-conditions',
				{ stock_safekeeping_id, redeem_request_id, agreements_conditions },
				{
					headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				}
			)
			.then(responseBody),
	getStockSafekeeping: ({ token, sSkId, role }: { token: string; sSkId?: string; role?: string }) =>
		axios
			.get<any>(`/stock-safekeepings/${sSkId}${role ? '?role=' + role : ''}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	getAgreementConditionForSsk: ({ token, sSkId }: { token: string; sSkId?: string }) =>
		axios
			.get<any>(`/agreements-conditions/stock-safekeeping/${sSkId}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				params: { sskid: sSkId },
			})
			.then(responseBody),
	getStockSafekeepingOrder: ({ token, sSkId }: { token: string; sSkId?: string; role?: string }) =>
		axios
			.get<any>(`/stock-safekeepings/${sSkId}/order`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	get: ({ user, listId, orderId }: { user: User; listId?: string; orderId?: string }) =>
		axios
			.get<ListDetails>('/constructor/list', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
				params: { list_id: listId, order_id: orderId },
			})
			.then(responseBody),
	getSeller: ({ user, listId, quoteId }: { user: User; listId?: string; quoteId?: string }) =>
		axios
			.get<SellerListDetails>('/seller/list', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
				params: { list_id: listId, quote_id: quoteId },
			})
			.then(responseBody),
	existsConstructor: ({ cuit }: { cuit: number }) =>
		axios
			.get<any>(`constructor/exists`, {
				params: { cuit },
			})
			.then(responseBody),
	existsConstructorUser: ({ email }: { email: string }) =>
		axios
			.post<any>(`user/exists`, null, {
				params: { email },
			})
			.then(responseBody),
	createConstructor: ({ fields, cuit }: { fields: object; cuit: number }) =>
		axios.post<any>('/constructor/create', fields, { params: { cuit } }).then(responseBody),
	createConstructorUser: ({ fields, cuit }: { fields: object; cuit: number }) =>
		axios.post<any>('/user/create', fields, { params: { cuit } }).then(responseBody),
	createSeller: ({ fields }: { fields: object }) => axios.post<any>('/seller/create', fields).then(responseBody),
	resendVerificationEmail: ({ userId }: { userId: string }) =>
		axios.post<any>('/user/resend-verification-email', { userId }).then(responseBody),
	executeAction: ({
		listId,
		orderId,
		action,
		environment = 'prod',
		outdated = false,
		user,
		reason,
		comments,
		oc,
		winner_quotations,
		companyId
	}: {
		listId: string;
		orderId?: string;
		action: Action;
		environment?: string;
		outdated?: boolean;
		user: User;
		reason?: number;
		comments?: string;
		oc?: any;
		winner_quotations?: any;
		companyId?: string;
	}) =>
		axios
			.post<ListDetails>(
				'/event-action',
				{
					list_id: listId,
					order_id: orderId,
					user: JSON.stringify(user),
					reason,
					comments,
					action,
					is_outdated: outdated,
					env_lista: environment,
					oc,
					winner_quotations,
					company_id: companyId
				},
				{
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${user.token}`,
						'Content-Type': 'application/json',
					},
				}
			)
			.then(responseBody),
	getDriveFile: ({
		driveFileId,
		fileId,
		listId,
		user,
	}: {
		driveFileId: string;
		fileId: string;
		listId?: string;
		user: User;
	}) => {
		const body = {
			file_id: driveFileId,
			id_lista: listId,
			user: JSON.stringify(user),
			where: fileId,
		};
		return axios
			.post<any>('/drive-get-file', body, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${user.token}`,
					'Content-Type': 'application/json',
				},
				responseType: 'blob',
			})
			.then(responseBody);
	},
	getDriveFileOnly: ({ driveFileId, user }: { driveFileId: string; user: User }) => {
		const body = {
			file_id: driveFileId,
			user: JSON.stringify(user),
		};
		return axios
			.post<any>('/drive-only-get-file', body, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${user.token}`,
					'Content-Type': 'application/json',
				},
				responseType: 'blob',
			})
			.then(responseBody);
	},
	getDriveFileName: ({ driveFileId, user }: { driveFileId: string; user: User }) => {
		const body = {
			file_id: driveFileId,
		};
		return axios
			.post<any>('/drive-get-file-name', body, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${user.token}`,
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.then(responseBody);
	},
	getListDocuments: ({ listId, orderId, user }: { listId?: string; orderId?: string; user: User }) =>
		axios
			.get<FilesList>('/constructor/list/documents', {
				params: { list_id: listId, order_id: orderId },
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
			})
			.then(responseBody),
	uploadTokenDriveFile: ({
		fileName,
		fileType,
		fileData,
		type,
		user,
		relatedFiles,
		listId,
		listMigrationId,
		orderId,
		orderMigrationId,
		listEnvironment,
		filesFolderId,
		documentId,
		deliveryId,
		documentDate,
		sskId,
	}: {
		fileName: string;
		fileType: string;
		fileData: any;
		type?: string;
		user?: User;
		relatedFiles: string[];
		listId?: string;
		listMigrationId?: string;
		orderId?: string;
		orderMigrationId?: number;
		listEnvironment?: string;
		filesFolderId?: string;
		documentId?: string;
		deliveryId?: string;
		documentDate?: string | null;
		sskId?: string;
	}) => {
		const body = {
			file: fileData,
			filename: fileName,
			mime_type: fileType,
			where: type,
			list_id: listId,
			list_migration_id: listMigrationId,
			order_id: orderId,
			order_migration_id: orderMigrationId,
			list_environment: listEnvironment,
			files_folder_id: filesFolderId,
			related_files: relatedFiles,
			document_id: documentId,
			delivery_id: deliveryId,
			document_date: documentDate,
			ssk_id: sskId,
		};

		return axios
			.post<any>('/stock-safekeepings/drive-upload-file', body, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${user?.token}`,
					'Content-Type': 'application/json',
				},
			})
			.then(responseBody);
	},
	uploadRedeemRequestDriveFile: ({
		fileName,
		fileType,
		fileData,
		type,
		user,
		relatedFiles,
		listId,
		listMigrationId,
		orderId,
		orderMigrationId,
		listEnvironment,
		filesFolderId,
		documentId,
		deliveryId,
		documentDate,
		sskId,
		redeemRequestId,
		redeemType,
	}: {
		fileName: string;
		fileType: string;
		fileData: any;
		type?: string;
		user?: User;
		relatedFiles: string[];
		listId?: string;
		listMigrationId?: string;
		orderId?: string;
		orderMigrationId?: number;
		listEnvironment?: string;
		filesFolderId?: string;
		documentId?: string;
		deliveryId?: string;
		documentDate?: string | null;
		sskId?: string;
		redeemRequestId?: string;
		redeemType?: string;
	}) => {
		const body = {
			file: fileData,
			filename: fileName,
			mime_type: fileType,
			where: type,
			list_id: listId,
			list_migration_id: listMigrationId,
			order_id: orderId,
			order_migration_id: orderMigrationId,
			list_environment: listEnvironment,
			files_folder_id: filesFolderId,
			related_files: relatedFiles,
			document_id: documentId,
			delivery_id: deliveryId,
			document_date: documentDate,
			ssk_id: sskId,
			redeem_request_id: redeemRequestId,
			redeem_type: redeemType,
		};

		return axios
			.post<any>('/redeem-requests/drive-upload-file', body, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${user?.token}`,
					'Content-Type': 'application/json',
				},
			})
			.then(responseBody);
	},
	uploadDriveFile: ({
		fileName,
		fileType,
		fileData,
		type,
		user,
		relatedFiles,
		listId,
		listMigrationId,
		orderId,
		orderMigrationId,
		listEnvironment,
		filesFolderId,
		documentId,
		deliveryId,
		documentDate,
	}: {
		fileName: string;
		fileType: string;
		fileData: any;
		type?: string;
		user?: User;
		relatedFiles: string[];
		listId?: string;
		listMigrationId?: string;
		orderId?: string;
		orderMigrationId?: number;
		listEnvironment?: string;
		filesFolderId?: string;
		documentId?: string;
		deliveryId?: string;
		documentDate?: string | null;
	}) => {
		const body = {
			file: fileData,
			filename: fileName,
			mime_type: fileType,
			where: type,
			list_id: listId,
			list_migration_id: listMigrationId,
			order_id: orderId,
			order_migration_id: orderMigrationId,
			list_environment: listEnvironment,
			files_folder_id: filesFolderId,
			related_files: relatedFiles,
			document_id: documentId,
			delivery_id: deliveryId,
			document_date: documentDate,
		};

		return axios
			.post<any>('/drive-upload-file', body, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${user?.token}`,
					'Content-Type': 'application/json',
				},
			})
			.then(responseBody);
	},
	me: ({ token }: { token: string }) =>
		axios
			.get<UserResponse>('/me', { headers: { Authorization: `Bearer ${token}` } })
			.then(responseBody)
			.then((response) => response.user),
	silentMe: ({ token }: { token: string }) =>
		axios.get<any>('/silent-login', { headers: { Authorization: `Bearer ${token}` } }).then((response) => response),
	oneTimeNotification: ({ token }: { token: string }) =>
		axios.get<{ notifications: Notification[]}>('/user/one-time-notification', { headers: { Authorization: `Bearer ${token}` } }).then((response) => response),
	getSellerListDocuments: ({ listId, orderId, user }: { listId?: string; orderId?: string; user: User }) =>
		axios
			.get<FilesList>('/seller/list/documents', {
				params: { list_id: listId, order_id: orderId },
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
			})
			.then(responseBody),
	getTokenDocuments: ({ orderId, sSkId, user }: { orderId?: string; sSkId?: string; user: User }) =>
		axios
			.get<FilesList>('/stock-safekeepings/token-documents', {
				params: { order_id: orderId, ssk_id: sSkId },
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
			})
			.then(responseBody),
	getListDeliveries: ({ orderId, user }: { orderId?: string; user: User }) =>
		axios
			.get<DeliveriesList>(`/${user.type_external}/list/delivery`, {
				params: { order_id: orderId },
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
			})
			.then(responseBody),
	createDelivery: ({ fields, user }: { fields: object; user?: User }) =>
		axios
			.post<any>(`/seller/list/delivery`, fields, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${user?.token}` },
			})
			.then(responseBody),
	checkVersion: ({ fields }: { fields: object }) =>
		axios
			.post<any>('/check-version', fields, {
				headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
			})
			.then(responseBody),
	searchConstructor: ({ fields }: { fields: object }) =>
		axios
			.post<any>('/search-constructoras', fields, {
				headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
			})
			.then(responseBody),
	searchSeller: ({ fields }: { fields: object }) =>
		axios
			.post<any>('/search-sellers', fields, {
				headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
			})
			.then(responseBody),
	switchInfo: ({ fields, user }: { fields: object; user: User | null }) =>
		axios
			.post<any>('/switch-info', fields, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${user?.token}`,
				},
			})
			.then(responseBody),
	switchInfoSellers: ({ fields, user }: { fields: object; user: User | null }) =>
		axios
			.post<any>('/switch-info-sellers', fields, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${user?.token}`,
				},
			})
			.then(responseBody),
	acceptTerms: ({ user }: { user: User }) =>
		axios
			.post<any>(
				'/accept-terms',
				{ user },
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				}
			)
			.then(responseBody),
	updateDelivery: ({ deliveryId, fields, user }: { deliveryId: string; fields: object; user?: User }) =>
		axios
			.patch<any>(`/seller/list/delivery/${deliveryId}`, fields, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${user?.token}` },
			})
			.then(responseBody),
	rejectQuotation: ({
		listId,
		environment = 'prod',
		quotationId,
		user,
		reason,
		comments,
	}: {
		listId: string;
		environment?: string;
		quotationId?: string;
		user: User;
		reason?: number;
		comments?: string;
	}) =>
		axios
			.post<any>(
				'/desestimar-cotizacion',
				{
					user: JSON.stringify(user),
					list_id: listId,
					cotizacion_id: quotationId,
					motivo: reason,
					comentario: comments,
					environment,
				},
				{
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${user.token}`,
						'Content-Type': 'application/json',
					},
				}
			)
			.then(responseBody),
	extendQuotation: ({
		date,
		environment = 'prod',
		quotationId,
		user,
	}: {
		date?: Date;
		environment?: string;
		quotationId?: string;
		user: User;
	}) =>
		axios
			.post<any>(
				'/extender-vigencia-seller',
				{
					user: JSON.stringify(user),
					cotizacion_id: quotationId,
					environment,
					fecha_validez: date?.toLocaleDateString('es-AR'),
				},
				{
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${user.token}`,
						'Content-Type': 'application/json',
					},
				}
			)
			.then(responseBody),
	sendQuotation: ({
		form,
		file,
		environment = 'prod',
		user,
		quotationId,
		listId,
	}: {
		form: {
			date?: Date;
			payment?: Payment[];
			availability?: Availability;
			comments?: string;
			includesShipping: Boolean;
		};
		file: { base64?: any; name?: string; type?: string; size?: number };
		environment?: string;
		user: User;
		quotationId?: string;
		listId?: string;
	}) =>
		axios
			.post<any>(
				'/send-cotizacion-seller',
				{
					user: JSON.stringify(user),
					id_cotizacion: quotationId,
					id_lista: listId,
					form: {
						fecha_validez: form.date?.toLocaleDateString('es-AR'),
						tipoFinanciamiento: [form.payment],
						disponibilidad: form.availability,
						comments: form.comments,
						shipping_request_quotation: form.includesShipping,
					},
					file: {
						base64: file.base64,
						name: file.name,
						mime_type: file.type,
						size: file.size,
					},
					environment,
				},
				{
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${user.token}`,
						'Content-Type': 'application/json',
					},
				}
			)
			.then(responseBody),
	getListComments: ({ listId, orderId, user }: { listId?: string; orderId?: string; user: User }) =>
		axios
			.get<CommentsList>('/constructor/list/comments', {
				params: { list_id: listId, order_id: orderId },
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
			})
			.then(responseBody),
	sendListComment: ({
		listId,
		orderId,
		message,
		user,
	}: {
		listId?: string;
		orderId?: string;
		message: string;
		user: User;
	}) =>
		axios
			.post<any>(
				'/constructor/list/comments',
				{ message },
				{
					params: { list_id: listId, order_id: orderId },
					headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
				}
			)
			.then(responseBody),
	setAsReadListComment: ({ comment_ids, user }: { comment_ids: string[]; user: User }) =>
		axios
			.patch<any>(
				'/constructor/list/comments/read',
				{ comment_ids },
				{
					headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
				}
			)
			.then(responseBody),
	listProjects: ({ user }: { user: User }) =>
		axios
			.post<Projects>(
				'/search-proyectos',
				{
					q: '',
					user: JSON.stringify(user),
				},
				{
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${user.token}`,
						'Content-Type': 'application/json',
					},
				}
			)
			.then(responseBody),
	submitQuotation: ({
		project,
		date,
		shipping,
		address,
		financing,
		comments,
		requestedQuotationFiles,
		referenceQuotationFiles,
		deliveryScheduleFiles,
		quotationPlanFiles,
		user,
	}: {
		project: any;
		date?: Date;
		shipping?: string;
		address?: string;
		financing?: string;
		comments?: string;
		requestedQuotationFiles?: FileWithContent[];
		referenceQuotationFiles?: FileWithContent[];
		deliveryScheduleFiles?: FileWithContent[];
		quotationPlanFiles?: FileWithContent[];
		user: User;
	}) =>
		axios
			.post<{ id_lista: string }>(
				'/send-cotizacion',
				{
					user: JSON.stringify(user),
					form: {
						proyecto: project.id_proyecto,
						nombreProyecto: project.nombre_proyecto,
						fechaRequeridaEntrega: date?.toLocaleDateString('es-AR'),
						requiereEntrega: shipping,
						direccionEntrega: address,
						locationExtras: { place: address },
						requiereFinanciacion: financing,
						comments,
					},
					files: {
						cotizacion: {
							columnName: 'Archivo de Pedido Original',
							fileTypeId: 1,
							hasFile: requestedQuotationFiles && requestedQuotationFiles.length > 0,
							files: (requestedQuotationFiles || []).map((file) => ({
								base64: file.base64,
								name: file.name,
								mime_type: file.type,
								size: file.size,
							})),
						},
						presupuesto: {
							columnName: 'Presupuesto de referencia',
							fileTypeId: 4,
							hasFile: referenceQuotationFiles && referenceQuotationFiles.length > 0,
							files: (referenceQuotationFiles || []).map((file) => ({
								base64: file.base64,
								name: file.name,
								mime_type: file.type,
								size: file.size,
							})),
						},
						cronograma: {
							columnName: 'Cronograma de entrega',
							fileTypeId: 3,
							hasFile: deliveryScheduleFiles && deliveryScheduleFiles.length > 0,
							files: (deliveryScheduleFiles || []).map((file) => ({
								base64: file.base64,
								name: file.name,
								mime_type: file.type,
								size: file.size,
							})),
						},
						planos: {
							columnName: 'Planos para cotizar',
							fileTypeId: 2,
							hasFile: quotationPlanFiles && quotationPlanFiles.length > 0,
							files: (quotationPlanFiles || []).map((file) => ({
								base64: file.base64,
								name: file.name,
								mime_type: file.type,
								size: file.size,
							})),
						},
					},
				},
				{
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${user.token}`,
						'Content-Type': 'application/json',
					},
				}
			)
			.then(responseBody),
	generateAddressUrl: ({ location, user }: { location?: Location; user: User }) =>
		axios
			.post<{ short_url: string }>(
				'/shorten-url',
				{
					user: JSON.stringify(user),
					address: location?.address,
					lat: location?.coordinates?.lat,
					lng: location?.coordinates?.lng,
				},
				{
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${user.token}`,
						'Content-Type': 'application/json',
					},
				}
			)
			.then(responseBody)
			.then((data) => data.short_url),
	getAddressFromUrl: ({ url, user }: { url: string; user: User }) =>
		axios
			.post<AddressFromUrlResult>(
				'/url-to-pin',
				{
					url,
					user: JSON.stringify(user),
				},
				{
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${user.token}`,
						'Content-Type': 'application/json',
					},
				}
			)
			.then(responseBody),

	getSellerWithDocuments: ({ id, user }: { id: string; user: User }) =>
		axios
			.get<SellerWithDocuments>(`/seller/${id}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
			})
			.then(responseBody),

	getSellerWithoutDocuments: ({ sellerId, token }: { sellerId: string; token: string }) =>
		axios
			.get<Seller>(`/seller/no-documents/${sellerId}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	getConstructorRemainingTokens: ({ token, sellerId }: { token: string; sellerId: string }) =>
		axios
			.get<any>(`/constructor/agreements/remain?seller_id=${sellerId}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	listCollaborators: ({ status, user }: { status: CollaboratorStatus; user: User }) =>
		axios
			.get<Collaborators>('/collaborators', {
				params: { status },
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
			})
			.then(responseBody),

	invitedCollaborators: ({ user }: { user: User }) =>
		axios
			.get<any>('/collaborators/invited', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
			})
			.then(responseBody),
	revokeInvitation: ({ email, user }: { email: string; user: User }) =>
		axios
			.post<any>(`/collaborators/invited/revoke`, { email }, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
			})
			.then(responseBody),
	getCollaboratorsCount: ({ user }: { user: User }) =>
		axios
			.get<[{ total_collaborators: number }]>('/collaborators/count', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
			})
			.then(responseBody),

	updateCollaborator: ({
		id,
		status,
		role,
		user,
	}: {
		id: string;
		status: CollaboratorStatus;
		role?: CollaboratorRole;
		user: User;
	}) =>
		axios
			.patch<Collaborator>(
				`/collaborators/${id}`,
				{ status, role },
				{
					params: { status },
					headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
				}
			)
			.then(responseBody),

	fetchAgreementProducts: (token: string, agreementId: string) =>
		axios
			.get<RedeemTokenViewDataProduct[]>(`/constructor/agreements/agreement-products?agreement_id=${agreementId}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),

	fetchAgreementInfo: (token: string, agreementId: string) =>
		axios
			.get<{data: RedeemTokenViewDataInfo[]}>(`/constructor/agreements/exchange-info?agreement_id=${agreementId}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),

	fetchAgreementAvailableTokens: (token: string, agreementId: string) =>
		axios
			.get<{ data: string }[]>(`/constructor/agreements/available-tokens?agreement_id=${agreementId}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),

	fetchReedeemableTokens: (token: string, agreementId?: string, constructorId?: string) =>
		axios
			.post<{ data: string }[]>(
				'/agreements-conditions/reedeemable-tokens',
				{
					agreement_id: agreementId,
					wallet_id: constructorId,
				},
				{
					headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
				}
			)
			.then(responseBody),

	fetchAgreementSellerAddress: (token: string, agreementId: string) =>
		axios
			.get<{ data: string }[]>(`/constructor/agreements/address?agreement_id=${agreementId}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),

	fetchSellerRedeemInfo: (token: string, redeemRequestId: string) =>
		axios
			.get<any>(`/redeem-requests/info?rr_id=${redeemRequestId}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	fetchRedeemRequest: (token: string, redeemRequestId: string) =>
		axios
			.get<RedeemRequestData>(`/redeem-requests/${redeemRequestId}`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),

	fetchRedeemRequestProducts: (token: string, redeemRequestId: string) =>
		axios
			.get<{ redeem_request_products: RedeemTokenViewDataProduct[] }>(`/redeem-requests/${redeemRequestId}/products`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),

	fetchRedeemRequestFiles: (token: string, redeemRequestId: string) =>
		axios
			.get<{ redeem_request_files: any[] }>(`/redeem-requests/${redeemRequestId}/files`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),

	fetchRedeemRequestShippings: (token: string, redeemRequestId: string) =>
		axios
			.get<{ redeem_request_shippings: Delivery[] }>(`/redeem-requests/${redeemRequestId}/shippings`, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	createShipping: ({ fields, user }: { fields: object; user?: User }) =>
		axios
			.post<any>(`/redeem-requests/shipping`, fields, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${user?.token}` },
			})
			.then(responseBody),
	createRedeem: ({ fields, token }: { fields: object; token: string }) =>
		axios
			.post<any>(`/redeem-requests/create`, fields, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	redeemAction: ({ fields, token }: { fields: object; token: string }) =>
		axios
			.post<any>(`/redeem-requests/actions`, fields, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	redeemConstructorAction: ({ fields, token }: { fields: object; token: string }) =>
		axios
			.post<any>(`/redeem-requests/constructor-actions`, fields, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	updateShipping: ({ deliveryId, fields, user }: { deliveryId: string; fields: object; user?: User }) =>
		axios
			.post<any>(`/redeem-requests/shipping/${deliveryId}`, fields, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${user?.token}` },
			})
			.then(responseBody),
	endRedeem: ( token: string, redeem_request_id: string) =>
		axios
			.post<any>('/redeem-requests/end-redeem', { redeem_request_id }, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	downloadGCSFile: (gcsFilePath: string, encoding: string, idExternal: string, token: string) =>
		axios
			.get<any>(
				`/file/download?path=${encodeURIComponent(
					gcsFilePath
				)}&encoding=${encoding}&id_external=${idExternal}&prevent_download_content=true`,
				{
					headers: { Accept: 'application/json', Authorization: `Bearer ${token}`, 'Cache-Control': 'no-cache' },
					baseURL: process.env.REACT_APP__STORAGE_API_BASE_URL,
				}
			)
			.then(responseBody),
	downloadGCSBinaryFile: (gcsFilePath: string, idExternal: string, token: string) =>
		axios
			.post<any>(
				`/file/download-binary`,
				{ path: gcsFilePath, id_external: idExternal },
				{
					headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
					baseURL: process.env.REACT_APP__STORAGE_API_BASE_URL,
					responseType: 'blob',
				}
			)
			.then(responseBody),
	getReferencePricesList: ({ token }: { token: string }) =>
		axios
			.get<any>('/reference-prices/complete-data', {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),

	inviteCollaborator: ({ token, fields }: { token: string; fields: object	}) => 
		axios
			.post<any>(`/collaborators/invite`, fields, {
				headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
			})
			.then(responseBody),
	updateUserFeature: ({ data, token }: { token: string; data: any }) =>
		axios.post<any>(`/user/updateFeature`, data, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
		}),

	updateUserPassword: ({ data, token }: { token: string; data: any }) =>
		axios.post<any>(`/user/updatePassword`, data, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
		}),

	updateUserPersonalInformation: ({ data, token }: { token: string; data: any }) =>
		axios.post<any>(`/user/updateUserData`, data, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
		}),

	resetUserPassword: ({ data, token }: { token: string; data: any }) =>
		axios.post<any>(`/user/resetPassword`, data, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
		}),
	updateOneTimeNotification: ({ id, token }: { token: string; id: any }) =>	
		axios.post<any>(`/user/one-time-notification-seen`, { id }, {
			headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
		}),
};
