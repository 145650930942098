import React from 'react';
import emotionStyled from '@emotion/styled';
import { Alert, Box, Grid, LinearProgress, MenuItem, Tooltip, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { SelectInputConfirmation, TooltipQuestionMarkIcon } from './confirmation-step';
import { AgreementContent } from '../dashboard/tokens';
import { SET_SELECTED_AGREEMENT_ID } from './reducer/actionNames';
import { AgreementCondition } from './reducer';
import { Agreement } from '../../../types';
import { useMobile } from '../../../hooks/use-mobile';
import { useRecoilValue } from 'recoil';
import agreementsOptionsState from '../../../atoms/agreements-options-state';
import InfoMarker from '../../info-marker';

interface Props {
	benefit?: AgreementCondition | null;
	dispatch: React.Dispatch<any>;
	isLoading?: boolean;
	noAvailableTokensToBuy?: boolean;
	selectedAgreementId: string;
	selectedAgreement?: Agreement;
	timeValidity: string;
}

const AgreementSelect = (props: Props) => {
	const {
		benefit,
		dispatch,
		isLoading,
		noAvailableTokensToBuy,
		selectedAgreement,
		selectedAgreementId,
		timeValidity,
	} = props;
	const { agreementsOptions } = useRecoilValue<{
		agreementsOptions: AgreementContent[];
		isLoading: boolean;
	}>(agreementsOptionsState)

	const intl = useIntl();
	const isMobile = useMobile();

	return (
		<Grid
			container
			rowSpacing={1}
			columnSpacing={2}
			sx={{
				...(!selectedAgreement ? { marginBottom: '1rem' } : {}),
			}}
		>
			<Grid item xs={12} sx={{ color: 'grey.600' }}>
				{intl.formatMessage({ id: 'buy_tokens.select_agreement.title' })}
			</Grid>
			<Grid
				item
				xs={12}
				sm={7}
				sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start' }}
			>
				<SelectInputConfirmation
					value={selectedAgreementId}
					onChange={(event) => dispatch({ type: SET_SELECTED_AGREEMENT_ID, payload: event.target.value })}
					sx={{ width: '100%', color: 'grey.600' }}
				>
					{agreementsOptions.map((agreement) => (
						<MenuItem key={agreement.id} value={agreement.id} sx={{ color: 'grey.600' }}>
							{agreement.product_label}
						</MenuItem>
					))}
				</SelectInputConfirmation>
				{selectedAgreement && !isLoading && (
					<Typography sx={{ color: '#72727E', fontSize: '12px', margin: '5px 0px 0px 2px' }}>
						{timeValidity}
						{!isMobile && (
							<Tooltip
								title={
									<Typography sx={{ color: '#FFFFFF' }}>
										{intl.formatMessage({ id: 'buy_tokens.select_agreement.tooltip_collection_duration' })}
									</Typography>
								}
							>
								<TooltipQuestionMarkIcon sx={{ width: '10px' }} />
							</Tooltip>
						)}
					</Typography>
				)}
			</Grid>
			{benefit && !isLoading && (
				<Grid item xs={12} sm={5}>
					<InfoMarker message={benefit.description} icon='gift' colorScheme='success' sx={{ height: { xs: '100%', lg: '56px'} }}></InfoMarker>
				</Grid>
			)}
			{noAvailableTokensToBuy && (
				<Grid item xs={12} sm={5}>
					<Alert
						severity="error"
						sx={{
							height: '100%',
							borderRadius: '5px',
						}}
					>
						<Typography sx={{ color: 'inherit', fontWeight: '500' }}>
							{intl.formatMessage({ id: 'mytokens.constructor.dashboard.dialog.redeem.considerations_three' })}
						</Typography>
					</Alert>
				</Grid>
			)}
			{isLoading && (
				<Grid item xs={12}>
					<Box sx={{ width: '100%', marginTop: 5 }}>
						<LinearProgress color="inherit" />
					</Box>
				</Grid>
			)}
		</Grid>
	);
};

export default AgreementSelect;
