import React, { useEffect, useState } from 'react';
import {
	Alert,
	Box,
	Button,
	Fade,
	Grid,
	InputLabel,
	LinearProgress,
	Link,
	MenuItem,
	Modal,
	Paper,
	Snackbar,
	Stack,
	Typography,
} from '@mui/material';
import { useMobile } from '../../../hooks/use-mobile';
import { useIntl } from 'react-intl';
import TextFieldWithPlaceholder from '../../text-field-with-placeholder';
import SelectWithPlaceholder from '../../select-with-placeholder';
import NuqleaFileUpload from '../../nuqlea-file-uploader';
import { BusinessDetails, BusinessFile, BusinessName, DocumentationTypes, User } from '../../../types';
import businessNameClient, { FileUploadRequest } from '../../../clients/businessNameClient';
import CreditProfileTerms from './credit-profile-terms';
import { randomizeFileNames } from '../../../helpers/filesHelper';
import { appendFile } from './utils/files-utils';
import filesClient from '../../../clients/filesClient';

interface CreditProfileEditionTabProps {
	callback: (isEditionMode: boolean) => void;
	showRequiredError: (show: boolean) => void;
	updateBusinessDetailsList: () => void;
	businessNamesList?: BusinessName[];
	businessDetailToEdit?: BusinessDetails;
	creditProfiles: BusinessDetails[] | undefined;
	organizationId: string;
	token: string;
	creator: User;
}

const CreditProfileEditionTab: React.FC<CreditProfileEditionTabProps> = ({
	callback,
	showRequiredError,
	updateBusinessDetailsList,
	businessNamesList,
	businessDetailToEdit,
	creditProfiles,
	organizationId,
	token,
	creator,
}) => {
	const isMobile = useMobile();
	const intl = useIntl();

	const [error, setError] = useState('');
	const [errorPayload, setErrorPayload] = useState<string>('');
	const [businessNameCuit, setBusinessNameCuit] = useState(
		businessNamesList?.length ? Number(businessNamesList[0]?.cuit) : 0
	);
	const [commercialReferencesText, setCommercialReferencesText] = useState(
		businessDetailToEdit?.commercial_reference ?? ''
	);
	const [mainSuppliersText, setMainSuppliersText] = useState(businessDetailToEdit?.supplier ?? '');
	const [mainCustomersText, setMainCustomersText] = useState(businessDetailToEdit?.customer ?? '');

	const [lastBalancesDoc1, setLastBalancesDoc1] = useState<File[]>([]);
	const [lastBalancesDoc2, setLastBalancesDoc2] = useState<File[]>([]);
	const [balancePostSalesDoc, setBalancePostSalesDoc] = useState<File[]>([]);
	const [commercialReferencesDoc, setCommercialReferencesDoc] = useState<File[]>([]);
	const [mainSuppliersDoc, setMainSuppliersDoc] = useState<File[]>([]);
	const [mainCustomersDoc, setMainCustomersDoc] = useState<File[]>([]);

	const [toggleCommerciarReferences, setToggleCommerciarReferences] = useState(
		businessDetailToEdit?.files?.find((i: any) => Number(i.type) === DocumentationTypes.COMMERCIAL_REF) ? true : false
	);
	const [toggleMainSuppliers, setToggleMainSuppliers] = useState(
		businessDetailToEdit?.files?.find((i: any) => Number(i.type) === DocumentationTypes.MAIN_SUPPLIERS) ? true : false
	);
	const [toggleMainCustomers, setToggleMainCustomers] = useState(
		businessDetailToEdit?.files?.find((i: any) => Number(i.type) === DocumentationTypes.MAIN_CUSTOMERS) ? true : false
	);

	const [firstSubmit, setFirstSubmit] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [showSecondBalance, setShowSecondBalance] = useState(false);
	const [showNoDataMessage, setShowNoDataMessage] = useState(false);
	const [showTermsModal, setShowTermsModal] = useState(false);

	const handleSave = async () => {
		setFirstSubmit(true);

		if (checkDataPresent() === false) {
			setShowNoDataMessage(true);
		} else {
			showRequiredError(false);
			businessDetailToEdit ? updateBusinessDetails() : createBusinessDetails();
		}
	};

	const createBusinessDetails = async () => {
		const newBusinessDetails: BusinessDetails = {
			bussiness_name_id: businessNamesList?.filter((item) => item.cuit === businessNameCuit)[0].id ?? 'none',
			commercial_reference: commercialReferencesText ?? null,
			supplier: mainSuppliersText ?? null,
			customer: mainCustomersText ?? null,
			created_by: creator.email,
		};

		try {
			const data = newBusinessDetails as any;
			data.userId = creator.id;
			setIsSaving(true);

			const uploadedFiles = await uploadFiles();

			if (!uploadedFiles || uploadedFiles.success === false) {
				setIsSaving(false);
				setError(intl.formatMessage({ id: 'common.upload_files_error' }));
				return;
			}

			data.id = uploadedFiles.businessDetailId;
			data.files = uploadedFiles.files;

			const result = await businessNameClient.createBusinessDetails({ data: data, user: creator });

			if (result.success) {
				updateBusinessDetailsList();
				callback(false);
			}
		} catch (error) {
			console.error(error);
			setError(intl.formatMessage({ id: 'common.error_message' }));
		} finally {
			setIsSaving(false);
		}
	};

	const updateBusinessDetails = async () => {
		let filesToDelete: string[] = [];

		const allCertificates = [
			...lastBalancesDoc1,
			...lastBalancesDoc2,
			...balancePostSalesDoc,
			...commercialReferencesDoc,
			...mainSuppliersDoc,
			...mainCustomersDoc
		];

		//Check if original files are present in current files, if not, mark to delete
		businessDetailToEdit?.files?.forEach((bf: BusinessFile) => {
			// Cuando los archivos ya existen, en type se guarda el id
			let keep = allCertificates?.find((uf) => uf.type === bf.id);
			if (keep == undefined) {
				filesToDelete.push(String(bf.id));
			}
		});

		const uploadedFiles = await uploadFiles();

		if (!uploadedFiles || uploadedFiles.success === false) {
			setIsSaving(false);
			setError(intl.formatMessage({ id: 'common.upload_files_error' }));
			return;
		}

		const updatedBusinessDetails: BusinessDetails = {
			id: businessDetailToEdit?.id,
			bussiness_name_id: businessDetailToEdit?.bussiness_name_id ?? null,
			commercial_reference: commercialReferencesText ?? null,
			supplier: mainSuppliersText ?? null,
			customer: mainCustomersText ?? null,
			created_by: creator.email,
			removedFilesIds: filesToDelete,
		};

		try {
			const data = updatedBusinessDetails as any;
			data.userId = creator.id;
			setIsSaving(true);
			
			data.files = uploadedFiles.files;

			const result = await businessNameClient.updateBusinessDetails({ data: data, user: creator });

			if (result.success) {
				updateBusinessDetailsList();
				callback(false);
			}
		} catch (error: any) {
			console.error(error);
			if (error.response?.status === 413) {
				setErrorPayload(intl.formatMessage({ id: 'common.payloadTooLarge' }));
			} else {
				setError(intl.formatMessage({ id: 'common.error_message' }));
			}
		} finally {
			setIsSaving(false);
		}
	};

	const makeFilesArrays = async () => {
		const files: FileUploadRequest[] = [];

		const renamedLastBalancesDoc1Files = randomizeFileNames([...lastBalancesDoc1.filter((file) => file.size > 0)]);
		const renamedLastBalancesDoc2Files = randomizeFileNames([...lastBalancesDoc2.filter((file) => file.size > 0)]);
		const renamedBalancePostSalesDocFiles = randomizeFileNames([...balancePostSalesDoc.filter((file) => file.size > 0)]);
		const renamedCommercialReferencesDocFiles = randomizeFileNames([...commercialReferencesDoc.filter((file) => file.size > 0)]);
		const renamedMainSuppliersDocFiles = randomizeFileNames([...mainSuppliersDoc.filter((file) => file.size > 0)]);
		const renamedMainCustomersDocFiles = randomizeFileNames([...mainCustomersDoc.filter((file) => file.size > 0)]);

		appendFile(files, renamedLastBalancesDoc1Files, DocumentationTypes.BALANCE);
		appendFile(files, renamedLastBalancesDoc2Files, DocumentationTypes.BALANCE, '1');
		appendFile(files, renamedBalancePostSalesDocFiles, DocumentationTypes.BALANCE_POST_SALES);
		appendFile(files, renamedCommercialReferencesDocFiles, DocumentationTypes.COMMERCIAL_REF);
		appendFile(files, renamedMainSuppliersDocFiles, DocumentationTypes.MAIN_SUPPLIERS);
		appendFile(files, renamedMainCustomersDocFiles, DocumentationTypes.MAIN_CUSTOMERS);

		const originalFiles = [
			...renamedLastBalancesDoc1Files,
			...renamedLastBalancesDoc2Files,
			...renamedBalancePostSalesDocFiles,
			...renamedCommercialReferencesDocFiles,
			...renamedMainSuppliersDocFiles,
			...renamedMainCustomersDocFiles,
		];

		return { files, originalFiles }
	};

	const uploadFiles = async () => {
		try {
			const { files, originalFiles } = await makeFilesArrays();

			const preparedFiles = await businessNameClient.prepareBusinessDetailsUpload({
				user: creator,
				entityId: businessDetailToEdit?.id || undefined,
				files,
			});

			if (!preparedFiles) {
				return {
					success: true,
					files: [],
					businessDetailId: undefined,
				}
			}

			const uploadPromises = originalFiles.flatMap((file) => {
				const fileIndex = preparedFiles.files.findIndex((f: { originalName: string; }) => f.originalName === file?.name);
				if (fileIndex >= 0) {
					const fileUrl = preparedFiles.files[fileIndex].upload_url;

					return filesClient.uploadFileWithUrl(fileUrl, file);
				}
				return [];
			});

			const responses = await Promise.all(uploadPromises);

			if (responses.some((response) => !response.success)) {
				throw new Error('Error uploading files');
			}

			return preparedFiles;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	const checkDataPresent = (): boolean => {
		if (
			lastBalancesDoc1.length > 0 ||
			balancePostSalesDoc.length > 0 ||
			commercialReferencesDoc.length > 0 ||
			mainSuppliersDoc.length > 0 ||
			mainCustomersDoc.length > 0 ||
			commercialReferencesText !== '' ||
			mainCustomersText !== '' ||
			mainSuppliersText !== ''
		) {
			return true;
		}
		return false;
	};

	const onSnackBarClose = () => {
		setShowNoDataMessage(false);
	};

	useEffect(() => {
		const getStoragedFiles = async () => {
			if (businessDetailToEdit?.files?.length > 0) {
				for (const file of businessDetailToEdit?.files) {
					const blob = new Blob();
					const filename = file.name;
					const newFile = new File([blob], filename, { type: file.id });

					switch (Number(file.type)) {
						case DocumentationTypes.BALANCE:
							if (filename.includes('_1')) {
								setLastBalancesDoc2([newFile]);
								setShowSecondBalance(true);
								break;
							}
							setLastBalancesDoc1([newFile]);
							break;
						case DocumentationTypes.BALANCE_POST_SALES:
							setBalancePostSalesDoc([newFile]);
							break;
						case DocumentationTypes.COMMERCIAL_REF:
							setCommercialReferencesDoc([newFile]);
							break;
						case DocumentationTypes.MAIN_SUPPLIERS:
							setMainSuppliersDoc([newFile]);
							break;
						case DocumentationTypes.MAIN_CUSTOMERS:
							setMainCustomersDoc([newFile]);
							break;
						default:
							break;
					}
				}
			}
		};
		getStoragedFiles();
	}, []);

	return (
		<Paper elevation={0} sx={{ minHeight: isMobile ? null : '100%', maxHeight: isMobile ? null : 2000 }}>
			<Grid pl={'15px'} minHeight={'100%'} display={'flex'} container spacing={2}>
				{!isMobile && (
					<Grid item xs={12} md={7} lg={6} xl={6}>
						<Box padding={'10px 130px 0 60px'}>
							<Typography variant="h6" fontWeight={700}>
								{intl.formatMessage({ id: 'settings.company.credit_profile_tab.title' })}
							</Typography>
							<Typography fontWeight={400} fontSize={'14px'} color={'#72727E'}>
								{intl.formatMessage({ id: 'settings.company.credit_profile_tab.subtitle' })}
							</Typography>
						</Box>
					</Grid>
				)}

				<Grid
					minHeight={'450px'}
					mt={isMobile ? 0 : '10px'}
					mb={'10px'}
					px={isMobile ? '15px' : '40px'}
					item
					xs={12}
					md={5}
					lg={6}
					xl={6}
				>
					<Stack justifyContent={'space-between'} width={'100%'} minHeight={'550px'}>
						{!businessDetailToEdit && (
							<Box pb={'20px'}>
								<SelectWithPlaceholder
									label={intl.formatMessage({ id: 'settings.company.choose_business_name' })}
									placeholder="ninguno"
									fullWidth
									value={businessNameCuit}
									onChange={(event) => setBusinessNameCuit(Number(event.target.value))}
									required
								>
									{businessNamesList?.map((item) => {
										return <MenuItem value={Number(item.cuit)}>{item.business_social + ' - ' + item.cuit}</MenuItem>;
									})}
								</SelectWithPlaceholder>
							</Box>
						)}
						<Box pb={'20px'}>
							<InputLabel>
								{intl.formatMessage({ id: 'settings.company.credit_profile_tab.last_balance_sheets_input' })}
							</InputLabel>
							<NuqleaFileUpload value={lastBalancesDoc1} onChange={setLastBalancesDoc1} />
							{lastBalancesDoc1.length > 0 && showSecondBalance === false && (
								<Button onClick={() => setShowSecondBalance(true)}>Añadir archivo</Button>
							)}
						</Box>

						{showSecondBalance && (
							<Box pb={'20px'}>
								<NuqleaFileUpload
									value={lastBalancesDoc2}
									onChange={(files: File[]) => {
										setLastBalancesDoc2(files);
										files.length === 0 && setShowSecondBalance(false);
									}}
								/>
							</Box>
						)}

						<Box pb={'20px'}>
							<InputLabel>
								{intl.formatMessage({ id: 'settings.company.credit_profile_tab.sales_after_balance_input' })}
							</InputLabel>
							<NuqleaFileUpload value={balancePostSalesDoc} onChange={setBalancePostSalesDoc} />
						</Box>

						<Box pb={'20px'}>
							{toggleCommerciarReferences ? (
								<Box>
									<InputLabel>
										{intl.formatMessage({ id: 'settings.company.credit_profile_tab.commercial_reference' })}
									</InputLabel>
									<NuqleaFileUpload value={commercialReferencesDoc} onChange={setCommercialReferencesDoc} />
								</Box>
							) : (
								<TextFieldWithPlaceholder
									sx={{ width: '100%' }}
									label={intl.formatMessage({ id: 'settings.company.credit_profile_tab.commercial_reference' })}
									multiline
									value={commercialReferencesText}
									placeholder={intl.formatMessage({
										id: 'settings.company.credit_profile_tab.commercial_reference_placeholder',
									})}
									onChange={(event) => setCommercialReferencesText(event.target.value)}
								></TextFieldWithPlaceholder>
							)}
							<Button onClick={() => setToggleCommerciarReferences(!toggleCommerciarReferences)} variant="text">
								{toggleCommerciarReferences
									? intl.formatMessage({
										id: 'settings.company.credit_profile_tab.complete_as_text',
									})
									: intl.formatMessage({
										id: 'settings.company.credit_profile_tab.attach_file',
									})}
							</Button>
						</Box>

						<Box pb={'20px'}>
							{toggleMainSuppliers ? (
								<Box>
									<InputLabel>
										{intl.formatMessage({ id: 'settings.company.credit_profile_tab.main_suppliers' })}
									</InputLabel>
									<NuqleaFileUpload value={mainSuppliersDoc} onChange={setMainSuppliersDoc} />
								</Box>
							) : (
								<TextFieldWithPlaceholder
									sx={{ width: '100%' }}
									label={intl.formatMessage({ id: 'settings.company.credit_profile_tab.main_suppliers' })}
									multiline
									placeholder={intl.formatMessage({
										id: 'settings.company.credit_profile_tab.main_suppliers_placeholder',
									})}
									value={mainSuppliersText}
									onChange={(event) => setMainSuppliersText(event.target.value)}
								></TextFieldWithPlaceholder>
							)}
							<Button onClick={() => setToggleMainSuppliers(!toggleMainSuppliers)} variant="text">
								{toggleMainSuppliers
									? intl.formatMessage({
										id: 'settings.company.credit_profile_tab.complete_as_text',
									})
									: intl.formatMessage({
										id: 'settings.company.credit_profile_tab.attach_file',
									})}
							</Button>
						</Box>

						<Box pb={'20px'}>
							{toggleMainCustomers ? (
								<Box>
									<InputLabel>
										{intl.formatMessage({ id: 'settings.company.credit_profile_tab.main_customers' })}
									</InputLabel>
									<NuqleaFileUpload value={mainCustomersDoc} onChange={setMainCustomersDoc} />
								</Box>
							) : (
								<TextFieldWithPlaceholder
									sx={{ width: '100%' }}
									label={intl.formatMessage({ id: 'settings.company.credit_profile_tab.main_customers' })}
									multiline
									placeholder={intl.formatMessage({
										id: 'settings.company.credit_profile_tab.main_customers_placeholder',
									})}
									value={mainCustomersText}
									onChange={(event) => setMainCustomersText(event.target.value)}
								></TextFieldWithPlaceholder>
							)}
							<Button onClick={() => setToggleMainCustomers(!toggleMainCustomers)} variant="text">
								{toggleMainCustomers
									? intl.formatMessage({
										id: 'settings.company.credit_profile_tab.complete_as_text',
									})
									: intl.formatMessage({
										id: 'settings.company.credit_profile_tab.attach_file',
									})}
							</Button>
						</Box>
					</Stack>
					{isSaving && <LinearProgress sx={{ mt: '30px' }}></LinearProgress>}
				</Grid>
				<Grid item xs={isMobile ? 12 : 6}></Grid>
				<Grid item xl={6} lg={6} xs={12} height={'10%'}>
					<Button disabled={isSaving || !creditProfiles?.length} onClick={() => callback(false)} variant="outlined">
						{intl.formatMessage({ id: 'common.cancel' })}
					</Button>
					<Button disabled={isSaving} onClick={handleSave} variant="contained" sx={{ ml: '10px' }}>
						{intl.formatMessage({ id: 'common.save' })}
					</Button>

					<Box sx={{ my: '1rem' }}>
						<Typography>
							{intl.formatMessage({ id: 'settings.company.credit_profile.termns_conditions_link' })}
						</Typography>
						<Link href="#" onClick={() => setShowTermsModal(true)} underline="always" color="inherit">
							{intl.formatMessage({ id: 'buy_tokens.agreement_details.link_terms_and_conditions' })}
						</Link>
					</Box>
				</Grid>
			</Grid>
			<Snackbar
				open={!!error}
				onClose={() => setError('')}
				autoHideDuration={8000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert severity="error" variant="filled">
					{error}
				</Alert>
			</Snackbar>
			<Snackbar
				open={!!errorPayload}
				onClose={() => setErrorPayload('')}
				autoHideDuration={8000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert severity="error" variant="filled">
					{errorPayload}
				</Alert>
			</Snackbar>
			<Snackbar
				open={showNoDataMessage}
				autoHideDuration={4000}
				onClose={onSnackBarClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				message="No hay datos que guardar"
				ContentProps={{ sx: { display: 'flex', justifyContent: 'center' } }}
			/>
			<Modal open={showTermsModal} onKeyDown={(event) => event.key === 'Escape' && setShowTermsModal(false)}>
				<Fade in={showTermsModal}>
					<Box>
						<CreditProfileTerms onclick={() => setShowTermsModal(false)}></CreditProfileTerms>
					</Box>
				</Fade>
			</Modal>
		</Paper>
	);
};

export default CreditProfileEditionTab;
