import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { Button, MenuItem, Select, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AgreementCondition, ShippingZone } from './reducer';
import { Dispatch, useEffect, useState } from 'react';
import { SET_SELECTED_DELIVERY } from './reducer/actionNames';
import { useIntl } from 'react-intl';

interface Props {
	deliveriesConditions: (AgreementCondition | null)[];
	dispatch?: Dispatch<{ type: string; payload?: any }>;
	open: boolean;
	onClose: () => void;
	zones: ShippingZone[];
	setZone: (zone: ShippingZone | undefined) => void;
	initialZone?: ShippingZone;
	isReadOnly?: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-container > .MuiPaper-root': {
		borderRadius: '10px',
		width: '90%',
		height: '90%',
	},
	'& .MuiDialogContent-root': {
		padding: theme.spacing(0),
	},
}));

const MapsDialog = (props: Props) => {
	const { deliveriesConditions, dispatch, open, onClose, zones, setZone, initialZone, isReadOnly } = props;
	const [selectedZone, setSelectedZone] = useState<ShippingZone | undefined>(initialZone || zones[0]);
	const [search, setSearch] = useState<string>('');
	const baseTheme = useTheme();
	const isDownMd = useMediaQuery(baseTheme.breakpoints.down('md'));
	const intl = useIntl();

	useEffect(() => {
		setSelectedZone(initialZone || zones[0]);
	}, [zones, initialZone, open]);

	return (
		<BootstrapDialog
			onClose={onClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			sx={{
				'& .MuiDialog-container > .MuiPaper-root': {
					maxWidth: {
						md: '90%',
						lg: '1200px',
					},
				},
			}}
		>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					paddingBottom: 0,
					borderBottom: '0px',
					px: 1,
				}}
			>
				<Box>
					<Typography sx={{ fontSize: '1.2em', fontWeight: 800 }}>
						{intl.formatMessage({ id: 'mytokens.acquisition_details.map.delivery_zones' })}
					</Typography>
					{!isReadOnly ? <Typography sx={{ fontSize: '.9em', display: { xs: 'none', sm: 'block' } }}>
						{intl.formatMessage({ id: 'mytokens.acquisition_details.map.delivery_zones_details' })}
					</Typography> : null}
				</Box>
				<Box>
					<Select
						value={selectedZone?.zone_label || ''}
						onChange={(e) => setSelectedZone(zones.find((zone) => zone.zone_label === e.target.value))}
						displayEmpty
						inputProps={{ 'aria-label': 'Without label' }}
						sx={{ width: '300px', '& .MuiSelect-select': { paddingY: 1 } }}
						disabled={isReadOnly}
					>
						{zones.map((zone, index) => (
							<MenuItem key={`${zone?.zone_id}-${index}`} value={zone.zone_label}>
								{zone?.zone_label}
							</MenuItem>
						))}
					</Select>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					overflow: 'hidden',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-around',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: isDownMd ? 'column' : 'row',
						width: '100%',
						height: '86%',
						paddingBottom: '16px',
					}}
				>
					{isReadOnly ? null : <Box sx={{ flex: isDownMd ? 1 : 2, maxHeight: '65vh', overflowY: 'auto' }}>
						<Box sx={{ width: '100%' }}>
							<TextField
								variant="outlined"
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								placeholder="Buscar localidad..."
								inputProps={{ style: { padding: '10px 12px' } }}
								sx={{ width: '100%', px: 3, '& .MuiOutlinedInput-root': { borderRadius: '4px' } }}
							/>
						</Box>
						<ul style={{ columns: isDownMd ? 1 : 2 }}>
							{/* @ts-ignore */}
							{selectedZone?.localities
								?.split(';')
								.filter((loc) => loc?.toLowerCase().includes(search?.toLowerCase()))
								.map((locality, i) => (
									<li key={i} style={{ paddingRight: '10px' }}>
										{locality}
									</li>
								))}
						</ul>
					</Box>}
					<Box sx={{ height: '100%', flex: 3, pr: 2 }}>
						<Box
							id="zonesMap"
							sx={{
								width: '100%',
								height: '100vh',
								maxHeight: '114%',
								mt: {
									xs: '-88px',
									md: '-70px',
								},
								clipPath: {
									xs: 'inset(88px 0 16px 0)',
									md: 'inset(70px 0 16px 0)',
								},
								overflow: 'hidden',
							}}
						>
							<iframe
								frameBorder={0}
								style={{ width: '100%', height: '100%', margin: isDownMd ? '20px 0 0 0' : '0 0 0 24px' }}
								title="map"
								src={selectedZone?.zone_url}
							/>
						</Box>
					</Box>
				</Box>

				<Box sx={{ display: 'flex' }}>
					<Button
						onClick={onClose}
						variant="outlined"
						sx={{
							width: '100%',
							mr: '10px',
							px: { xs: 2, sm: 4 },
						}}
					>
						{intl.formatMessage({ id: 'common.close' })}
					</Button>
					{
						!isReadOnly && 
						<Button
							sx={{
								width: '100%',
								px: { xs: 2, sm: 4 },
							}}
							onClick={() => {
								if (initialZone !== selectedZone) {
									setZone(selectedZone);
									const newSelectedDelivery = deliveriesConditions.find(
										(delivery) => delivery?.id === selectedZone?.agreement_condition_id
									);
									dispatch && dispatch({ type: SET_SELECTED_DELIVERY, payload: newSelectedDelivery });
								}
								onClose();
							}}
							variant="contained"
						>
							{intl.formatMessage({ id: 'common.apply' })}
						</Button>
					}
				</Box>
			</DialogContent>
		</BootstrapDialog>
	);
};

export default MapsDialog;
