import { useState } from 'react';
import styled from '@emotion/styled';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TableSortLabel,
	Box,
	Collapse,
	IconButton,
	CardProps,
} from '@mui/material';
import { Role, User } from '../types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Counter from './counter';
import { IntlShape, useIntl } from 'react-intl';
import Card, { Action } from './card';

const TableWrapper = styled.div`
	overflow-y: overlay;
	overflow-x: hidden;
`;
const NoPaddingCell = styled(TableCell)`
	padding: 5px;
`;
const CellContent = styled('div')`
	overflow: hidden;
	text-overflow: ellipsis;
`;
export interface DataColumn {
	displayName?: string;
	supportsMobile?: boolean;
	headerStyle?: any;
	sort?: (a: DataRow, b: DataRow) => number;
	backgroundColor?: (row: DataRow) => any;
	render: (row: DataRow, intl: IntlShape, user?: User, listId?: string, callback?: any) => any;
	showWarning?: boolean;
	editDeliveryColumn?: boolean;
}
export interface DataRow {
	[key: string]: any;
}
interface Props extends CardProps {
	title: string;
	layoutColumns?: number;
	borderRadius?: number;
	subtitle?: React.ReactElement | string;
	actions?: Action[];
	columns: DataColumn[];
	relatedColumns?: DataColumn[];
	rows?: DataRow[];
	avatar?: boolean;
	loading?: boolean;
	error?: boolean;
	emptyMessage?: string;
	onRetry?: () => void;
	mobileOnClick?: (row: DataRow) => void;
	budgetAcceptanceBlock?: React.ReactElement;
}
const getSortedRows = (columns: DataColumn[], direction: 'asc' | 'desc', rows?: DataRow[], orderBy?: string) => {
	if (!rows) {
		return rows;
	}
	if (!orderBy) {
		return rows;
	}
	const column = columns.filter((column) => column.displayName === orderBy)[0];
	if (!column) {
		return rows;
	}
	const sort = column.sort;
	if (!sort) {
		return rows;
	}
	return rows.sort((a, b) => (direction === 'asc' ? sort(a, b) : sort(b, a)));
};
const TableCardTreeView = (props: Props) => {
	const {
		title,
		layoutColumns,
		subtitle,
		borderRadius,
		actions,
		columns,
		relatedColumns,
		rows,
		avatar,
		loading,
		error,
		emptyMessage,
		onRetry,
		mobileOnClick,
		budgetAcceptanceBlock,
		...rest
	} = props;
	const intl = useIntl();
	const [orderBy, setOrderBy] = useState<string>();
	const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

	const handleChangeSorting = (column?: string) => {
		if (!column) {
			return;
		}
		if (orderBy !== column) {
			setOrderBy(column);
			setDirection('asc');
		} else if (direction === 'asc') {
			setDirection('desc');
		} else {
			setDirection('asc');
		}
	};

	const tableHasEditDelivery = rows?.some((row) => row.user?.type_external === Role.SELLER && row.status === 1);
	const filteredColumns = columns.filter((column) => (column?.editDeliveryColumn ? tableHasEditDelivery : true));
	const sortedRows = getSortedRows(filteredColumns, direction, rows, orderBy);
	const Row = ({ row }: any) => {
		const [open, setOpen] = useState<boolean>(true);

		return (
			<>
				<TableRow
					id="collapsible-file-row"
					sx={{
						'& > *': { borderBottom: 'unset', color: '#7E7E82!important' },
						cursor: { xs: mobileOnClick ? 'pointer' : 'auto', md: row.children?.length ? 'pointer' : 'auto' },
					}}
					key={row.file_id + '0'}
					onClick={(e) => {
						if (row.children?.length) setOpen(!open);
					}}
				>
					{filteredColumns.map((column, index) => (
						<NoPaddingCell
							key={`cell-${index}`}
							sx={{
								display: { xs: column.supportsMobile ? 'table-cell' : 'none', md: 'table-cell' },
								background: column.backgroundColor ? column.backgroundColor(row) : 'inherit',
								maxWidth: {
									md: index === 0 ? '103.5px' : 'unset',
									xs: index === 0 ? '45px' : 'unset',
								},
							}}
						>
							<CellContent>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									{index === 0 && row.children?.length ? (
										<IconButton size="small" onClick={() => setOpen(!open)}>
											{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
										</IconButton>
									) : (
										''
									)}
									<Box sx={{ width: '100%' }}>
										{column.render(row, intl) != null ? <span>{column.render(row, intl)}</span> : '-'}
									</Box>
								</Box>
							</CellContent>
						</NoPaddingCell>
					))}
				</TableRow>
				<TableRow
					sx={{
						'& > *': { borderBottom: 'unset', color: '#7E7E82!important' },
						cursor: { xs: mobileOnClick ? 'pointer' : 'auto', md: 'auto' },
					}}
					key={row.file_id + '1'}
				>
					<NoPaddingCell
						colSpan={filteredColumns?.length}
						sx={{
							display: 'table-cell',
						}}
						style={{ padding: 0 }}
					>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Table
								stickyHeader
								sx={{
									width: '100%',
									tableLayout: {
										md: 'fixed',
										xs: 'auto',
									},
								}}
							>
								<TableBody>
									{row.children?.map((child: any) => (
										<TableRow
											sx={{
												'& > *': { borderBottom: 'unset', color: '#7E7E82!important' },
												cursor: { xs: mobileOnClick ? 'pointer' : 'auto', md: 'auto' },
												'& > .MuiTableCell-root': {
													boder: 'none',
												},
											}}
											key={child.file_id}
											onClick={
												mobileOnClick
													? () => {
															mobileOnClick(child);
													  }
													: undefined
											}
										>
											{relatedColumns?.map((column, index) => (
												<NoPaddingCell
													key={`innercell-${index}`}
													sx={{
														display: { xs: column.supportsMobile ? 'table-cell' : 'none', md: 'table-cell' },
														background: column.backgroundColor ? column.backgroundColor(child) : 'inherit',
														width: {
															md: column.displayName
																? '100%'
																: index === relatedColumns.length - 1 && column.headerStyle
																? '90px'
																: layoutColumns === 12
																? '19%'
																: '44%',
															xs: index === 0 ? '19.6%' : '100%',
														},
														maxWidth: {
															md: index === 0 ? '103.5px' : '100%',
															xs: index === 0 ? '45px' : 'unset',
														},
													}}
												>
													<CellContent>
														{column.render(child, intl, row.user) != null ? (
															<Box
																sx={{
																	width: '100%',
																	paddingLeft: {
																		xs: index === 0 ? '32px' : 'unset',
																	},
																}}
															>
																{column.render(child, intl, row.user, row.list_id)}
															</Box>
														) : (
															'-'
														)}
													</CellContent>
												</NoPaddingCell>
											))}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Collapse>
					</NoPaddingCell>
				</TableRow>
			</>
		);
	};
	return (
		<Card
			title={title}
			subtitle={subtitle}
			borderRadius={borderRadius}
			loading={loading}
			error={error}
			onRetry={onRetry}
			avatar={avatar && <Counter value={rows?.length || 0} />}
			actions={actions}
			{...rest}
		>
			<Box sx={{ minHeight: '160px', maxHeight: '520px', overflowY: 'overlay' }}>
				<TableWrapper>
					<Table
						stickyHeader
						sx={{
							width: '100%',
							tableLayout: {
								md: 'fixed',
								xs: 'auto',
							},
						}}
					>
						<TableHead>
							<TableRow>
								{!!rows?.length &&
									filteredColumns.map((column, index) => (
										<NoPaddingCell
											key={`collapsedcell-${index}`}
											colSpan={1}
											sx={{
												...column.headerStyle,
												display: { xs: column.supportsMobile ? 'table-cell' : 'none', md: 'table-cell' },
											}}
										>
											{column.displayName && (
												<CellContent>
													<Typography
														variant="body2"
														className='font-bold'
														sx={{
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															width: '100%',
															whiteSpace: 'nowrap',
														}}
													>
														{column.sort && (
															<TableSortLabel
																active={orderBy === column.displayName}
																direction={orderBy === column.displayName ? direction : 'asc'}
																onClick={() => handleChangeSorting(column.displayName)}
															>
																{column.displayName}
															</TableSortLabel>
														)}
														{!column.sort && <span>{column.displayName}</span>}
													</Typography>
												</CellContent>
											)}
										</NoPaddingCell>
									))}
							</TableRow>
						</TableHead>
						<TableBody>
							{!!sortedRows?.length && sortedRows?.map((row, i) => <Row key={row.file_id} row={row} />)}
							{!sortedRows?.length && (
								<TableRow key={'nodata'}>
									<NoPaddingCell
										colSpan={filteredColumns.length}
										align="center"
										sx={{
											borderBottom: 'none',
											color: '#7E7E82',
										}}
									>
										<CellContent>
											<Typography variant='body1' mt={4}>
												{loading && intl.formatMessage({ id: 'common.loading' })}
												{!loading && (emptyMessage || intl.formatMessage({ id: 'common.table.no_files' }))}
											</Typography>
										</CellContent>
									</NoPaddingCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableWrapper>
				{budgetAcceptanceBlock}
			</Box>
		</Card>
	);
};
export default TableCardTreeView;
