import axios, { AxiosResponse } from 'axios';
import { FileWithContent, User } from '../types';

const customAxios = (user?: User) => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP__STORAGE_API_BASE_URL + '/file',
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers['Accept'] = 'application/json';
            if (user) {
                config.headers['Authorization'] = `Bearer ${user.token}`;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

interface PresignedUrlUploadResponse {
    success: boolean;
    message: string;
    path: string;
    url: string;
}

interface UploadFileWithUrlResponse {
    success: boolean;
    message: string;
    data?: any;
}

const client = {
    requestPresignedUrlUpload: async (user: User, bucketName: string, filename: string, contentType: string): Promise<PresignedUrlUploadResponse> => {
        try {
            const client = customAxios(user);
            const response = await client.get(`/upload/signed-url`, {
                params: {
                    bucketName,
                    filename,
                    contentType
                }
            });

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    uploadFileWithUrl: async (url: string, file: File): Promise<UploadFileWithUrlResponse> => {

        if (!file) {
            return {
                success: false,
                message: 'file is empty',
            };
        }

        try {
            const client = axios.create({
                baseURL: 'storage.googleapis.com',
                headers: {
                    'Content-Type': file.type,
                }
            });
            const response = await client.put(url, file);
            
            if (response.status === 200) {
                return {
                    success: true,
                    message: 'file uploaded',
                    data: response.data
                };
            } else {
                return {
                    success: false,
                    message: 'file not uploaded',
                    data: response.data
                };
            }
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    removeFile: async (user: User, filepath: string): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.delete('', {
                data: {
                    path: filepath,
                }
            });

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    downloadMetadataFile: async (filepath: string, user: User) => {
        try {
            if (!filepath) {
                return;
            }

            const client = customAxios(user);
            const response = await client
                .get<any>(
                    `/metadata?path=${encodeURIComponent(filepath)}`
                );
    
            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    downloadBinaryFile: async (fileUri: string) => {
        try {
            if (!fileUri) {
                return;
            }
    
            const response = await axios.get<any>(fileUri, { responseType: 'blob' });
    
            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    }
}

export default client;