import { Paper, PaperProps, Typography } from '@mui/material';
import { deepOrange, red, blue, amber } from '@mui/material/colors';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface informationBarProps extends PaperProps {
	message: string;
	icon: 'error' | 'info' | 'warning';
}

const InformationBar: React.FC<informationBarProps> = ({ message, icon, ...props }) => {
	let selectedIcon;
	let color;

	switch (icon) {
		case 'error':
			selectedIcon = <ErrorOutlineIcon sx={{ ml: '3rem' }} />;
			color = deepOrange.A400;
			break;
		case 'warning':
			selectedIcon = <WarningAmberIcon sx={{ ml: '3rem' }} />;
			color = amber[500];
			break;
		case 'info':
			selectedIcon = <InfoOutlinedIcon sx={{ ml: '3rem' }} />;
			color = blue[500];
			break;
		default:
			selectedIcon = <ErrorOutlineIcon sx={{ ml: '3rem' }} />;
	}

	return (
		<Paper
			elevation={0}
			{...props}
			sx={{
				display: 'flex',
				alignItems: 'center',
				height: '3.5rem',
				borderLeft: `solid 0.6rem ${color}`,
				color: color,
				mt: '1rem',
				...props.sx,
			}}
		>
			{selectedIcon}
			<Typography ml={'1.3rem'} variant="body2">
				{message}
			</Typography>
		</Paper>
	);
};

export default InformationBar;
