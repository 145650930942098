import { ReactElement } from "react";

export interface Row {
	sku: string; // codigo del producto
	rubro: string;
	name: string;
  price: number;
  formattedPrice?: string;
  created_at: string;
  date?: string;
  onClickOpenModal?: ReactElement;
}

const isSameCategory = (categoryToFilter: string, row: Row): boolean => {
  return row.rubro === categoryToFilter;
};

const searchTextMatchesAColumn = (text: string, row: Row): boolean => {
  return (
    row.sku?.toLowerCase().includes(text.toLowerCase()) ||
    row.name?.toLowerCase().includes(text.toLowerCase()) ||
    row.rubro?.toLowerCase().includes(text.toLowerCase())
  );
};

export {
  isSameCategory,
  searchTextMatchesAColumn
}