import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import HelpContent from './constants';

interface Props {
    open: boolean;
    onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(1),
    borderRadius: '10px',
    width: '80%',
    maxWidth: 'none',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    marginRight: '10px'
  },
  '& .MuiTimelineItem-root': {
    minHeight: '0px',
    height: 'auto'
  },
  '& .MuiTypography-root': {
    paddingTop: '0'
  },
  '& .MuiTimelineConnector-root': {
    height: '30px',
    minHeight: 'none',
    backgroundColor: 'black'
  },
  '& .MuiTimelineDot-root': {
    width: '25px', 
    height: '25px', 
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundColor: 'black',
    fontSize: '13px',
    lineHeight: 1,
    marginTop: 0, 
    marginBottom: 0
  }
}));

const container = (title: string, text: string, color: string) => (
  <Box>
    <Typography sx={{ color, fontSize: '1rem' }}>{title}</Typography>
    <Typography sx={{ fontSize: '0.8rem' }}>{text}</Typography>
  </Box>
);

const HelpDialog = (props: Props) => {
  const {open, onClose} = props
  const intl = useIntl();

  return (
    
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ alignSelf: 'center', justifyContent: 'center', fontSize: '1.5rem', fontWeight: 'bold', paddingBottom: 0, marginTop: '10px' }} >
            {intl.formatMessage({ id: "help_dialog.title" })}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography sx={{ fontSize: '1rem' }} gutterBottom>
            {intl.formatMessage({ id: "help_dialog.content.title" })}
          </Typography>
          <Typography sx={{ fontSize: '0.9rem', fontWeight: 'light', lineHeight: 1.3, color: '#555' }} gutterBottom>
            {intl.formatMessage({ id: "help_dialog.content.subtitle" })}
          </Typography>
          <Timeline
            sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
                },
                padding: 0
            }}
            >
              {HelpContent.map((item, index, row) => (
                <TimelineItem key={item.title}>
                  <TimelineSeparator>
                  <TimelineDot>
                      {item.symbol}
                  </TimelineDot>
                  {(index + 1 !== row.length) && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>{container(item.title, item.text, item.color)}</TimelineContent>
              </TimelineItem>
              ))}
            
            <TimelineItem sx={{ marginTop: '10px'}}>
                <TimelineSeparator>
                <TimelineDot>
                    !
                </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>{container(intl.formatMessage({ id: "help_dialog.content.not_done" }), intl.formatMessage({ id: "help_dialog.content.not_done_extended" }), '')}</TimelineContent>
            </TimelineItem>
            </Timeline>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius: 10, width: '25%' }} variant="contained">
                {intl.formatMessage({ id: 'common.understood' })}
            </Button>
        </DialogActions>
      </BootstrapDialog>
  );
}

export default HelpDialog;