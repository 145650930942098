import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Application from './components/application';
import { Auth0Provider } from '@auth0/auth0-react';
// import * as Sentry from '@sentry/react';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { StyledEngineProvider } from '@mui/material';
import './index.css';

// Sentry.init({
// 	dsn: 'https://5e2a317b6344fe1d99aecdee9e6fa5f9@o919032.ingest.sentry.io/4505992232566784',
// 	environment: window.location.hostname === 'studio.nuqlea.com' ? 'production' : 'dev',
// 	tracesSampleRate: 1.0,
// 	replaysSessionSampleRate: 0.1,
// 	replaysOnErrorSampleRate: 1.0,
// 	integrations: [new Sentry.Replay()],
// });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<Auth0Provider
			domain="auth.nuqlea.com"
			clientId="qqVd1qIQ1XprWc0lNpgMny84OQE06tb0"
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: 'https://dev-0sko7xezczdona1g.us.auth0.com/api/v2/',
				scope: 'openid profile email user_metadata app_metadata',
			}}
		>
			<RecoilRoot>
				<StyledEngineProvider injectFirst>
					<Application />
				</StyledEngineProvider>
			</RecoilRoot>
		</Auth0Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
