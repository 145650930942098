import { Business } from '@mui/icons-material';
import { Box, Grid, Skeleton, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { FormattedMessage, useIntl } from 'react-intl';
import GeneralEditionTab from './general-edition-tab';
import GeneralTab from './general-tab';
import { ConstructionAddress, ConstructionAddressResponse, BusinessName, BusinessDetails } from '../../../types';
import client from '../../../clients/client';
import businessNameClient from '../../../clients/businessNameClient';
import InformationBar from '../../information-bar';
import PurchasesTab from './purchases-tab';
import PurchasesEditionTab from './purchases-edition-tab';
import AddressesEditionTab from './addresses-edition-tab';
import AddressesTab from './addresses-tab';
import useStorage from '../../../hooks/use-storage';

import { useRecoilState, useRecoilValue } from 'recoil';
import organizationState from '../../../atoms/organization-state';
import TaxProfileEditionTab from './tax-profile-edition-tab';
import TaxProfileTab from './tax-profile-tab';
import CreditProfileEditionTab from './credit-profile-edition-tab';
import CreditProfileTab from './credit-profile-tab';
import { getAddressesWitContacts } from './addressHelper';
import InfoMarker from '../../info-marker';
import BasePageFrame from '../../base-components/base-page-frame';
import userState from '../../../atoms/user-state';

export interface construct {
	clasificacion?: string;
	constancia_cuit_file_id?: string;
	cuit?: number;
	domicilio_comercial?: string | null;
	employee_quantity?: number | null;
	id_constructora?: string;
	image_url?: string | null;
	name_constructora?: string;
	nombre_owner?: string;
	owner?: number;
	perfil?: string | null;
	perfil_constructora?: string | null;
	project_type?: string | null;
	razon_social?: string;
	short_description?: string | null;
	social_media?: string | null;
	suscripta?: boolean;
	user_type?: string | null;
}

export interface purchaseBehavior {
	id: string;
	activities: string | null;
	vendor_type: string | null;
	payment_type: string | null;
	stockpilling_type: string | null;
	cautions: string | null;
	shipping_type: string | null;
}

const MainSettings: React.FC = () => {
	const intl = useIntl();
	const user = useRecoilValue(userState);
	const [value, setValue] = React.useState('1');
	const [organizationData, setOrganizationData] = useRecoilState(organizationState);
	const [savedUser, setSavedUser] = useStorage('user', () => ({ user, error: false }));
	const [purchaseBehavior, setPurchaseBehavior] = useState<purchaseBehavior>();
	const [addresses, setAddresses] = useState<ConstructionAddress[]>();
	const [taxProfile, setTaxProfile] = useState<BusinessName[]>();
	const [creditProfiles, setCreditProfiles] = useState<BusinessDetails[]>();
	const [generalEditMode, setGeneralEditMode] = useState(false);
	const [purchasesEditMode, setPurchasesEditMode] = useState(false);
	const [addressEditMode, setAddressEditMode] = useState(false);
	const [taxProfileEditMode, setTaxProfileEditMode] = useState(false);
	const [creditProfileEditMode, setCreditProfileEditMode] = useState(false);
	const [addressToEdit, setAddressToEdit] = useState<ConstructionAddress>();
	const [taxProfileToEdit, setTaxProfileToEdit] = useState<BusinessName>();
	const [creditProfileToEdit, setCreditProfileToEdit] = useState<BusinessDetails>();
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(intl.formatMessage({ id: 'common.error_required_fields' }));
	const [orgDataLoading, setOrgDataLoading] = useState(true);
	const [orgPurchasesLoading, setOrgPurchasesLoading] = useState(true);
	const [orgAddressesLoading, setOrgAddressesLoading] = useState(true);
	const [orgBusinessNamesLoading, setOrgBusinessNamesLoading] = useState(true);
	const [orgCreditProfilesLoading, setOrgCreditProfilesLoading] = useState(true);
	const [tabsToUpdateData, setTabsToUpdateData] = useState([1, 2, 3, 4, 5]);

	const menuTabs = [
		{ value: '1', label: intl.formatMessage({ id: 'settings.company.general_tab' }) },
		{ value: '2', label: intl.formatMessage({ id: 'settings.company.purchases_tab' }) },
		{ value: '3', label: intl.formatMessage({ id: 'settings.company.addresses_tab' }) },
		{ value: '4', label: intl.formatMessage({ id: 'settings.company.tax_profile_tab' }) },
		{ value: '5', label: intl.formatMessage({ id: 'settings.company.credit_profile_tab' }) },
	];

	useEffect(() => {
		const fetchOrganizationData = async () => {
			try {
				if (tabsToUpdateData.includes(1)) {
					setOrgDataLoading(true);
					const response = await client.getConstructorData({ token: user.token, id: user.id_external });
					setOrganizationData(response.constructor);
					setSavedUser({
						...savedUser,
						user: {
							...savedUser.user,
							name_external: response.constructor.name_constructora,
							image: response.constructor.image_url,
						},
					});
					setOrgDataLoading(false);
				}

				if (tabsToUpdateData.includes(2)) {
					setOrgPurchasesLoading(true);
					const responsePurchase = await client.getPurchaseBehavior({ token: user.token, id: user.id_external });
					setPurchaseBehavior(responsePurchase.data);
					setOrgPurchasesLoading(false);
				}

				if (tabsToUpdateData.includes(3)) {
					getAddressesAdapted();
				}

				if (tabsToUpdateData.includes(4)) {
					setOrgBusinessNamesLoading(true);
					const responseBusinessNames = await businessNameClient.getBusinessNames({
						user: user,
						constructorId: user.id_external,
					});
					setTaxProfile(responseBusinessNames);
					setOrgBusinessNamesLoading(false);
				}

				if (tabsToUpdateData.includes(5)) {
					setOrgCreditProfilesLoading(true);
					const responseBusinessDetails = await businessNameClient.getBusinessDetails({ user: user, id: user.id_external });
					setCreditProfiles(responseBusinessDetails);
					setOrgCreditProfilesLoading(false);
				}
				tabsToUpdateData.length < 0 && setTabsToUpdateData([]);
			} catch (error) {
				console.log(error);
			}
		};
		setTimeout(() => fetchOrganizationData(), tabsToUpdateData.length > 1 ? 0 : 2500);
	}, [tabsToUpdateData]);

	const toggleGeneralEdit = async (isEditionMode: boolean) => {
		setShowError(false);
		setGeneralEditMode(isEditionMode);
	};

	const togglePurchasesEdit = (isEditionMode: boolean) => {
		setShowError(false);
		setPurchasesEditMode(isEditionMode);
	};

	const toggleAddressEdit = (isEditionMode: boolean, addressToEdit?: ConstructionAddress) => {
		setShowError(false);
		if (isEditionMode) {
			setAddressToEdit(addressToEdit);
			setAddressEditMode(isEditionMode);
		} else {
			setAddressEditMode(isEditionMode);
		}
	};

	const toggleTaxProfileEdit = (isEditionMode: boolean, taxProfileToEdit?: BusinessName) => {
		setShowError(false);
		if (isEditionMode) {
			setTaxProfileToEdit(taxProfileToEdit);
			setTaxProfileEditMode(isEditionMode);
		} else {
			setTaxProfileEditMode(isEditionMode);
		}
	};

	const toggleCreditProfileEdit = (isEditionMode: boolean, creditProfileToEdit?: BusinessDetails) => {
		if (isEditionMode) {
			setCreditProfileToEdit(creditProfileToEdit);
			setCreditProfileEditMode(isEditionMode);
		} else {
			setCreditProfileEditMode(isEditionMode);
		}
	};

	const showRequiredError = (show: boolean, message?: string) => {
		message ? setErrorMessage(message) : setErrorMessage(intl.formatMessage({ id: 'common.error_required_fields' }));
		setShowError(show);
	};

	const updatePurchasesDataCreated = (data: any) => {
		setOrgPurchasesLoading(true);
		setTabsToUpdateData([2]);
	};

	const setEmptyAddress = (isEmpty: boolean) => {
		isEmpty && setAddresses(undefined);
	};

	const getAddressesAdapted = async () => {
		setOrgAddressesLoading(true);
		const responseAddresses: ConstructionAddressResponse[] | any = await client.getOrganizationAddresses({
			token: user.token,
			id: user.id_external,
		});
		const fullAddress = await getAddressesWitContacts(responseAddresses.data);
		setAddresses(fullAddress);
		setOrgAddressesLoading(false);
	};

	const setEmptyBusinessNames = (isEmpty: boolean) => {
		isEmpty && setTaxProfile(undefined);
	};

	const tabsContent = [
		{
			value: '1',
			content:
				organizationData && !generalEditMode ? (
					<GeneralTab callback={toggleGeneralEdit} constructorData={organizationData}></GeneralTab>
				) : (
					organizationData && (
						<GeneralEditionTab
							callback={toggleGeneralEdit}
							constructorData={organizationData}
							updateOrganizationData={() => {
								setOrgDataLoading(true);
								setTabsToUpdateData([1]);
							}}
							showRequiredError={showRequiredError}
							token={user.token}
						></GeneralEditionTab>
					)
				),
			isLoading: orgDataLoading,
		},
		{
			value: '2',
			content:
				purchaseBehavior && !purchasesEditMode ? (
					<PurchasesTab user={user} callback={togglePurchasesEdit} purchase={purchaseBehavior}></PurchasesTab>
				) : (
					<PurchasesEditionTab
						showRequiredError={showRequiredError}
						constructorId={user.id_external}
						callback={togglePurchasesEdit}
						purchaseBehavior={purchaseBehavior}
						token={user.token}
						updateDataShown={updatePurchasesDataCreated}
						user={user.email}
					></PurchasesEditionTab>
				),
			isLoading: orgPurchasesLoading,
		},
		{
			value: '3',
			content:
				addresses?.length && !addressEditMode ? (
					<AddressesTab
						editAddress={toggleAddressEdit}
						updateAddressList={() => {
							setOrgAddressesLoading(true);
							setTabsToUpdateData([3]);
						}}
						constructorAddresses={addresses}
						token={user.token}
						setEmptyAddress={setEmptyAddress}
					></AddressesTab>
				) : (
					<AddressesEditionTab
						constructorId={user.id_external}
						showRequiredError={showRequiredError}
						callback={toggleAddressEdit}
						addressToEdit={addressToEdit}
						updateAddressList={() => {
							setOrgAddressesLoading(true);
							setTabsToUpdateData([3]);
						}}
						token={user.token}
						creator={user.email}
					></AddressesEditionTab>
				),
			isLoading: orgAddressesLoading,
		},
		{
			value: '4',
			content:
				taxProfile && !taxProfileEditMode ? (
					<TaxProfileTab
						updateBusinessNamesList={() => {
							setOrgBusinessNamesLoading(true);
							setTabsToUpdateData([4, 5]);
						}}
						orgTaxProfiles={taxProfile}
						editTaxProfile={toggleTaxProfileEdit}
						token={user.token}
						setEmptyBusinessNames={setEmptyBusinessNames}
						user={user}
					></TaxProfileTab>
				) : (
					<TaxProfileEditionTab
						updateBusinessNamesList={() => {
							setOrgBusinessNamesLoading(true);
							setTabsToUpdateData([4]);
						}}
						businessNameToEdit={taxProfileToEdit}
						callback={toggleTaxProfileEdit}
						organizationId={user.id_external}
						showRequiredError={showRequiredError}
						token={user.token}
						creator={user}
					></TaxProfileEditionTab>
				),
			isLoading: orgBusinessNamesLoading,
		},
		{
			value: '5',
			content:
				creditProfiles && !creditProfileEditMode ? (
					<CreditProfileTab
						orphanBusinessNames={taxProfile?.filter(
							(item) => !creditProfiles?.some((subItem) => subItem.bussiness_name_id === item.id)
						)}
						updateCreditProfilesList={() => {
							setOrgCreditProfilesLoading(true);
							setTabsToUpdateData([5]);
						}}
						clearCreditProfilesToEdit={() => setCreditProfileToEdit(undefined)}
						credProfiles={creditProfiles}
						businessNames={taxProfile}
						editCreditProfile={toggleCreditProfileEdit}
						token={user.token}
						user={user}
					></CreditProfileTab>
				) : (
					<CreditProfileEditionTab
						//se utiliza esto para montar y desmontar el componente en la eliminacion
						key={`${creditProfiles?.length}`}
						businessNamesList={taxProfile?.filter(
							(item) => !creditProfiles?.some((subItem) => subItem.bussiness_name_id === item.id)
						)}
						creditProfiles={creditProfiles}
						updateBusinessDetailsList={() => {
							setOrgCreditProfilesLoading(true);
							setTabsToUpdateData([5]);
						}}
						businessDetailToEdit={creditProfileToEdit}
						callback={toggleCreditProfileEdit}
						organizationId={user.id_external}
						showRequiredError={showRequiredError}
						token={user.token}
						creator={user}
					></CreditProfileEditionTab>
				),
			isLoading: orgCreditProfilesLoading,
		},
	];

	const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
		setShowError(false);
		setValue(newValue);
	};

	return (
		<BasePageFrame
			title={user.name_external}
			subtitle={intl.formatMessage({ id: 'common.organization' })}
			image={
				organizationData?.image_url ? (
					<img alt="img" className='object-contain w-full h-full' src={organizationData.image_url}></img>
				) : (
					<Business sx={{ width: '3.8rem', height: '3.8rem', borderRadius: '5px' }}></Business>
				)
			}
			transparent
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
				<InfoMarker
					message={
						<FormattedMessage
							id="settings.info"
							values={{
								strong: (chunks) => <strong>{chunks}</strong>,
							}}
						/>
					}
					colorScheme="info"
					variation="banner"
					sx={{ p: '0.6rem 1.3rem' }}
				></InfoMarker>

				<Grid container sx={{ mt: '0.6rem', flex: 1 }}>
					<Grid item xs={12}>
						<Box sx={{ width: '100%', height: '100%' }}>
							<TabContext value={value}>
								<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
									<TabList
										scrollButtons="auto"
										variant="scrollable"
										onChange={handleChange}
										indicatorColor="primary"
										textColor="primary"
										allowScrollButtonsMobile
									>
										{menuTabs.map((item) => (
											<Tab
												className='pr-[2rem]'
												key={'menu' + item.value}
												value={item.value}
												label={item.label}
											></Tab>
										))}
									</TabList>
								</Box>
								{showError && <InformationBar icon="error" message={errorMessage}></InformationBar>}

								{tabsContent.map((item) => (
									<TabPanel key={item.value} value={item.value} sx={{ p: '1rem 0 0 0' }}>
										{item.isLoading ? (
											<Skeleton sx={{ mt: '0.6rem' }} variant="rectangular" height={'50vh'} animation="wave" />
										) : (
											item.content
										)}
									</TabPanel>
								))}
							</TabContext>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</BasePageFrame>
	);
};

export default MainSettings;
