import {
	AddressContactResponse,
	ConstructionAddress,
	ConstructionAddressResponse,
	ConstructionContact,
} from '../../../types';

export const getAddressesWitContacts = async (addresses: ConstructionAddressResponse[]) => {
	const clientAddresses = addresses.map((item: ConstructionAddressResponse) => {
		let adapted: ConstructionAddress = {
			id: item.id,
			organizationId: item.organization_id,
			alias: item.address_alias,
			address: item.address,
			city: item.city,
			province: item.province,
			country: item.country,
			placeUrl: item.place_url,
			latitude: item.lat,
			longitude: item.long,
			googlePlaceId: item.google_place_id,
			isDefault: item.default,
			availability: item.reception,
			gathering: item.stockpilling,
			comments: item.comments,
			contacts: item.contacts.map((item: AddressContactResponse) => {
				let contact: ConstructionContact = {
					id: item.id,
					addressId: item.address_id,
					name: item.contact,
					phone: item.phone,
				};
				return contact;
			}),
		};
		return adapted;
	});

	return clientAddresses;
};
