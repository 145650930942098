import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import { useIntl } from 'react-intl';
import { Alert, Box, Dialog, Grid, Typography, debounce } from '@mui/material';
import { useState } from 'react';
import client from '../../../../clients/client';
import userState from '../../../../atoms/user-state';
import { useRecoilValue } from 'recoil';

interface Props {
	open: boolean;
	onClose: () => void;
	redeemRequestId: string;
	refresh: () => void;
	tokensPendingToDeliver: number;
}

const EndRedeemDialog = (props: Props) => {
	const user = useRecoilValue(userState);
	const {	
		open,
		onClose,
		redeemRequestId,
		refresh,
		tokensPendingToDeliver
	} = props;
	
	const intl = useIntl();
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const handleEndRedeem = debounce(() => {
		client
			.endRedeem(user.token, redeemRequestId)
			.then(() => {
				onClose();
				refresh();
			})
			.catch(() => {
				setLoading(false);
				setError(intl.formatMessage({ id: 'common.error' }));
			})
	}, 2000);

	const handleOnClose = () => {
		setError('');
		onClose();
	};

	return (
		<Dialog
			onClose={!!loading ? () => { } : handleOnClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			fullWidth
			maxWidth="md"
		>
			<DialogTitle
				sx={{
					fontSize: '18px',
					fontWeight: 700,
					padding: '0 0 14px 0',
					marginBottom: '14px',
					borderBottom: '1px solid #C6C6C6',
				}}
			>
				{intl.formatMessage({ id: 'mytokens.redeem.end_dialog.title' })}
			</DialogTitle>

			<DialogContent className='flex flex-col items-center py-2'>
				{loading ? (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
							color: 'grey.500',
						}}
					>
						<CircularProgress color="inherit" />
					</Box>
				) : (
					<>
						<Grid container spacing={1} paddingX={1} className='flex justify-center'>
							<Grid item xs={12}>
								{tokensPendingToDeliver > 0 && (
									<Typography>
										{intl.formatMessage(
											{ id: tokensPendingToDeliver === 1 ? 'mytokens.redeem.end_dialog.pending_token' : 'mytokens.redeem.end_dialog.pending_tokens' },
											{ tokens: tokensPendingToDeliver }
										)}
									</Typography>
								)}
								<Typography>{intl.formatMessage({ id: 'mytokens.redeem.end_dialog.confirm_message' })}</Typography>
							</Grid>
							{error && (
								<Grid item xs={6}>
									<Alert severity="error">{error}</Alert>
								</Grid>
							)}
						</Grid>

					</>
				)}
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center', padding: '10px 16px!important' }}>
				<Button
					disabled={!!loading}
					onClick={handleOnClose}
					sx={{ width: '160px' }}
					variant="outlined"
				>
					{intl.formatMessage({ id: 'common.close' })}
				</Button>
				<Button
					disabled={!!loading}
					onClick={() => {setLoading(true); handleEndRedeem()}}
					sx={{ width: '160px' }}
					variant="contained"
				>
					{intl.formatMessage({ id: 'common.end' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EndRedeemDialog;