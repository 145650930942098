import axios, { AxiosResponse } from 'axios';
import { User } from '../../types';

const customAxios = (user: User) => {
	const axiosInstance = axios.create({
		baseURL: process.env.REACT_APP__STORAGE_API_BASE_URL + '/file',
	});

	axiosInstance.interceptors.request.use(
		(config) => {
			config.headers['Accept'] = 'application/json';
			config.headers['Authorization'] = `Bearer ${user.token}`;

			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	return axiosInstance;
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const projectsPath = 'organizations-documentation/organizations-project-documentation';

export interface IGCSFile {
	bucket: string;
	id: string;
	link: string;
	name: string;
	path: string;
}

const service = {
	getProjectFiles: async (user: User, projectId: number): Promise<any> => {
		console.log('getGCS', projectId);
		try {
			const client = customAxios(user);
			const response = await client.get<any>(`/secured-list?path=${projectsPath}/${user.id_external}/${projectId}`);

			return responseBody(response);
		} catch (error) {
			console.error('Ocurrió un error: ', error);
			throw error;
		}
	},
	uploadFile: async (user: User, projectId: number, base64file: string, filename: string): Promise<any> => {
		try {
			const client = customAxios(user);
			const response = await client.post<any>(`/private/upload`, {
				path: `${projectsPath}/${user.id_external}/${projectId}/${filename}`,
				user_id: user.id,
				content: base64file,
			});

			return responseBody(response);
		} catch (error) {
			console.error('Ocurrió un error: ', error);
			throw error;
		}
	},
	removeFile: async (user: User, filepath: string): Promise<any> => {
		try {
			const client = customAxios(user);
			const response = await client.delete('', {
				data: {
					path: filepath,
				},
			});

			return responseBody(response);
		} catch (error) {
			console.error('Ocurrió un error: ', error);
			throw error;
		}
	},
	downloadMetadataFile: async (fileUri: string, user: User) => {
		try {
			if (!fileUri) {
				return;
			}

			const encoding = 'base64';
			const client = customAxios(user);
			const response = await client.get<any>(
				`/download?path=${encodeURIComponent(fileUri)}&encoding=${encoding}&id_external=${
					user.id_external
				}&prevent_download_content=true`
			);

			return responseBody(response);
		} catch (error) {
			console.error('Ocurrió un error: ', error);
			throw error;
		}
	},
	downloadBinaryFile: async (fileUri: string, user: User) => {
		try {
			if (!fileUri) {
				return;
			}

			const client = customAxios(user);
			const response = await client.post<any>(
				`/download-binary`,
				{ path: fileUri, id_external: user.id_external },
				{ responseType: 'blob' }
			);

			return responseBody(response);
		} catch (error) {
			console.error('Ocurrió un error: ', error);
			throw error;
		}
	},
};

export default service;
