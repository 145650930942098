import { useNavigate, useParams } from "react-router-dom";
import BasePageFrame from "../../base-components/base-page-frame";
import { useIntl } from "react-intl";
import { Grid, Skeleton } from "@mui/material";
import { AgreementConditionTypes, AgreementProduct, Role } from "../../../types";
import { useEffect, useState } from "react";
import client from "../../../clients/client";
import { useRecoilValue } from "recoil";
import userState from "../../../atoms/user-state";
import CostBreakdownCardComponent from "./cost-breakdown-card";
import ConvertionTableCard from "./convertion-table-card";

const CostBreakdownComponent = () => {
	const { id } = useParams();
	const user = useRecoilValue(userState);
	const intl = useIntl();
	const navigate = useNavigate();

	const [isLoadingData, setIsLoadingData] = useState(true);
	const [isLoadingAgreement, setIsLoadingAgreement] = useState(true);
	const [sSks, setSSks] = useState<any>();
	const [stockSafekeepingOrder, setStockSafekeepingOrder] = useState<any>();
	const [bonusCondition, setBonusCondition] = useState<any>();
	const [shippingCondition, setShippingCondition] = useState<any>();
	const [financingCondition, setFinancingCondition] = useState<any>();
	const [agreement, setAgreement] = useState<any>();
	const [agreementProducts, setAgreementProducts] = useState<AgreementProduct[]>([]);

	const handleGoBack = () => {
		navigate(`/my-tokens/${id}`);
	};

	useEffect(() => {
		const fetchStockSafeKeepingsUsedConditions = async () => {
			return client.getAgreementConditionForSsk({
				token: user.token,
				sSkId: id,
			});
		};

		const fetchStockSafekeepingOrder = async () => {

			const result = client.getStockSafekeepingOrder({
				token: user.token,
				sSkId: id,
			});

			return result;
		};

		const fetchStockSafekeeping = async () => {
			const response = client.getStockSafekeeping({ token: user.token, sSkId: id, role: Role.SELLER });

			return response;
		};

		let isMounted = true;
		try {
			if (!id) return;

			setIsLoadingData(true);

			Promise.all([fetchStockSafekeeping(), fetchStockSafekeepingOrder(), fetchStockSafeKeepingsUsedConditions()])
				.then(([sSks, order, conditions]) => {

					if (!isMounted) return;

					setSSks(sSks.sSks);
					setStockSafekeepingOrder(order);
					const buyConditions: any[] = conditions.conditions;

					const bonusCondition = buyConditions.find(condition => condition.condition_type_id === AgreementConditionTypes.PRICE);
					setBonusCondition(bonusCondition);

					const shippingCondition = buyConditions.find(condition => condition.condition_type_id === AgreementConditionTypes.SHIPPING);
					setShippingCondition(shippingCondition);

					const financingCondition = buyConditions.find(condition => condition.condition_type_id === AgreementConditionTypes.FINANCING);
					setFinancingCondition(financingCondition);

					setIsLoadingData(false);
				});

		} catch (error) {
			console.error(error);
			setIsLoadingData(false);
		}

		return () => {
			isMounted = false; // Cleanup
		};

	}, [id, user.token]);

	useEffect(() => {
		if (!sSks) return;
		let isMounted = true;

		setIsLoadingAgreement(true);

		const fetchAgreement = async () => {
			const response = client.getSellerAgreement({ token: user.token, agreementId: sSks.agreement_id });
			return response;
		};

		const fetchAgreementProducts = async () => {
			const response = client.getSellerAgreementProducts({ token: user.token, agreementId: sSks.agreement_id });

			return response;
		};

		Promise.all([fetchAgreement(), fetchAgreementProducts()])
			.then(([agreement, products]) => {

				if (!isMounted) return;

				setAgreement(agreement.data.result);
				setAgreementProducts(products.data);

				setIsLoadingAgreement(false);
			});

		return () => {
			isMounted = false; // Cleanup
		};
	}, [sSks, user.token]);

	return (
		<BasePageFrame
			title={intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.title' })}
			returnAction={handleGoBack}
			transparent
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{isLoadingData ?
						<Skeleton variant="rectangular" height={300} /> :
						<CostBreakdownCardComponent
							stockSafekeepingOrder={stockSafekeepingOrder}
							sSks={sSks}
							bonusCondition={bonusCondition}
							shippingCondition={shippingCondition}
							financingCondition={financingCondition}
							elevation={0}
						></CostBreakdownCardComponent>
					}
				</Grid>

				<Grid item xs={12}>
					{isLoadingAgreement ?
						<Skeleton variant="rectangular" height={300} /> :
						<ConvertionTableCard
							stockSafekeepingOrder={stockSafekeepingOrder}
							agreement={agreement}
							agreementProducts={agreementProducts}
							bonusCondition={bonusCondition}
							shippingCondition={shippingCondition}
							elevation={0}
						></ConvertionTableCard>
					}
				</Grid>
			</Grid>
		</BasePageFrame>
	)
}

export default CostBreakdownComponent;