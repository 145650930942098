import { Box, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { User } from '../../../types';
import SellerEditDeliveryDialog from './seller-edit-delivery-dialog'

interface Props {
	delivery: any;
	user?: User;
}

const EditDeliveryButton = (props: Props) => {
	const { delivery, user } = props;

	const [sellerEditDeliveryDialog, setSellerEditDeliveryDialogOpen] = useState<boolean>(false);

	const handleClick = (e: any) => {
		e.preventDefault()
		e.stopPropagation()
		e.nativeEvent.stopImmediatePropagation()
		if(!sellerEditDeliveryDialog) setSellerEditDeliveryDialogOpen(true)
	}

	return (
		<Box sx={{ justifyContent: 'center'}}>
			<IconButton onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<SellerEditDeliveryDialog deliveryType="edit" open={sellerEditDeliveryDialog} delivery={delivery} user={user} onClose={() => setSellerEditDeliveryDialogOpen(false)} />
		</Box>
	)
};

export default EditDeliveryButton;
