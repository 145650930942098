import React, { useEffect, useState } from 'react';
import {Alert,Box,Button,Grid,InputLabel,LinearProgress,MenuItem,Snackbar,Stack} from '@mui/material';
import { useMobile } from '../../../../hooks/use-mobile';
import { useIntl } from 'react-intl';
import TextFieldWithPlaceholder from '../../../text-field-with-placeholder';
import SelectWithPlaceholder from '../../../select-with-placeholder';
import SelectMultiWithPlaceholder from '../../../select-multiple-with-placeholder';
import NuqleaFileUpload from '../../../nuqlea-file-uploader';
import { BusinessFile, BusinessName, DocumentationTypes, User } from '../../../../types';
import businessNameClient, { FileUploadRequest } from '../../../../clients/businessNameClient';
import filesClient from '../../../../clients/filesClient';
import { LoadingButton } from '@mui/lab';
import InformationBar from '../../../information-bar';
import { randomizeFileNames } from '../../../../helpers/filesHelper';
import { appendFile } from '../utils/files-utils';

interface TaxProfileFormProps {
	callback: (isEditionMode: boolean, changed?: boolean) => void;
	showRequiredError?: (show: boolean, message?: string) => void;
	updateBusinessNamesList?: () => void;
	businessNameToEdit?: BusinessName;
	organizationId: string;
	token: string;
	creator: User;
	isModal?: boolean;
}

const ivaOptions = [
	{ value: 'Monotributista' },
	{ value: 'Consumidor final' },
	{ value: 'Responsable inscripto' },
	{ value: 'Exento' },
];

const iibbOptions = [{ value: 'Convenio local' }, { value: 'Convenio multilateral' }, { value: 'Exento' }];

const perceptionOptions = [{ value: 'IIGG' }, { value: 'IVA' }, { value: 'IIBB' }, { value: 'SUSS' }];

const TaxProfileForm: React.FC<TaxProfileFormProps> = ({
	callback,
	showRequiredError,
	updateBusinessNamesList,
	businessNameToEdit,
	organizationId,
	token,
	creator,
	isModal = false,
}) => {
	const isMobile = useMobile();
	const intl = useIntl();

	const [error, setError] = useState('');
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(intl.formatMessage({ id: 'common.error_required_fields' }));

	const [cuit, setCuit] = useState(businessNameToEdit?.cuit ?? '');
	const [organizationName, setOrganizationName] = useState(businessNameToEdit?.business_social ?? '');
	const [taxResidence, setTaxResidence] = useState(businessNameToEdit?.invoice_address ?? '');
	const [ivaStatus, setIvaStatus] = useState(businessNameToEdit?.tax_status ?? '');
	const [iibbStatus, setIibbStatus] = useState(businessNameToEdit?.iibb_status ?? '');
	const [perceptionStatus, setPerceptionStatus] = useState<string[]>(businessNameToEdit?.perceptions?.split(',') ?? []);
	const [firstSubmit, setFirstSubmit] = useState(false);

	const [miPymeCertificate, setMiPymeCertificate] = useState<File[]>([]);
	const [ivaCertificate, setIvaCertificate] = useState<File[]>([]);
	const [ivaExemptionCertificate, setIvaExemptionCertificate] = useState<File[]>([]);
	const [iibbLocalCertificate, setIibbLocalCertificate] = useState<File[]>([]);
	const [iibbMultilateral01Certificate, setIibbMultilateral01Certificate] = useState<File[]>([]);
	const [iibbMultilateral05Certificate, setIibbMultilateral05Certificate] = useState<File[]>([]);
	const [iibbExemptionCertificate, setIibbExemptionCertificate] = useState<File[]>([]);
	const [iiggPerceptionCertificate, setIiggPerceptionCertificate] = useState<File[]>([]);
	const [ivaPerceptionCertificate, setIvaPerceptionCertificate] = useState<File[]>([]);
	const [iibbPerceptionCertificate, setIibbPerceptionCertificate] = useState<File[]>([]);
	const [sussPerceptionCertificate, setSussPerceptionCertificate] = useState<File[]>([]);

	const [isChecking, setIsChecking] = useState(false);

	const [isSaving, setIsSaving] = useState(false);

	let allFilesPresent = false;

	const showRequiredErrorDefault = (show: boolean, message?: string) => {
		message ? setErrorMessage(message) : setErrorMessage(intl.formatMessage({ id: 'common.error_required_fields' }));
		setShowError(show);
	};

	const handleShowError = (show: boolean, message?: string) => {
		if (showRequiredError) {
			showRequiredError(show, message);
		} else {
			showRequiredErrorDefault(show, message);
		}
	};

	const handleSave = async () => {
		allFilesPresent = checkRequiredFilesPresent();
		setFirstSubmit(true);
		let items = document.querySelectorAll(
			'.MuiInputBase-input, .MuiSelect-nativeInput'
		) as NodeListOf<HTMLInputElement>;
		let arrayItems = Array.from(items);
		//A little delay to ensure the state changes before evaluate it's aria-invalid attribute
		await new Promise((resolve) => setTimeout(resolve, 0));
		const anyInvalid = arrayItems?.some((item) => item.getAttribute('aria-invalid') === 'true');

		if (anyInvalid || !allFilesPresent) {
			handleShowError(true);
		} else {
			setIsChecking(true);
			let exists = businessNameToEdit ? false : await checkAlreadyExists(Number(cuit), organizationName);
			setIsChecking(false);
			if (!exists) {
				handleShowError(false);
				businessNameToEdit ? updateBusinessName() : createBusinessName();
			}
		}
	};

	const createBusinessName = async () => {
		const newBusinessName: BusinessName = {
			id_constructora: organizationId,
			business_social: organizationName,
			cuit: Number(cuit),
			invoice_address: taxResidence,
			perceptions: perceptionStatus.join(','),
			tax_status: ivaStatus,
			iibb_status: iibbStatus,
			created_by: creator.email,
		};

		try {
			const data = newBusinessName as any;
			data.userId = creator.id;
			setIsSaving(true);

			const uploadedFiles = await uploadFiles();
			
			if (!uploadedFiles || uploadedFiles.success === false) {
				setIsSaving(false);
				setError(intl.formatMessage({ id: 'common.upload_files_error' }));
				return;
			}
			
			data.id = uploadedFiles.businessNameId;
			data.files = uploadedFiles.files;

			const result = await businessNameClient.createBusinessName({ data: data, user: creator });
			
			if (result.success) {
				updateBusinessNamesList && updateBusinessNamesList();
				callback(false, true);
			}
		} catch (error) {
			console.error(error);
			setError(intl.formatMessage({ id: 'common.error_message' }));
		} finally {
			setIsSaving(false);
		}
	};

	const updateBusinessName = async () => {
		let filesToDelete: string[] = [];

		const allCertificates = [
			...miPymeCertificate,
			...ivaCertificate,
			...ivaExemptionCertificate,
			...iibbLocalCertificate,
			...iibbExemptionCertificate,
			...iibbMultilateral01Certificate,
			...iibbMultilateral05Certificate,
			...iiggPerceptionCertificate,
			...ivaPerceptionCertificate,
			...iibbPerceptionCertificate,
			...sussPerceptionCertificate
		];

		//Check if original files are present in current files, if not, mark to delete
		businessNameToEdit?.files?.forEach((bf: BusinessFile) => {
			// Cuando los archivos ya existen, en type se guarda el id
			let keep = allCertificates?.find((uf) => uf.type === bf.id); 
			if (keep == undefined) {
				filesToDelete.push(String(bf.id));
			}
		});
		
		const uploadedFiles = await uploadFiles();

		if (!uploadedFiles || uploadedFiles.success === false) {
			setIsSaving(false);
			setError(intl.formatMessage({ id: 'common.upload_files_error' }));
			return;
		}

		const updatedBusinessName: BusinessName = {
			id: businessNameToEdit?.id,
			id_constructora: organizationId,
			business_social: organizationName,
			cuit: Number(cuit),
			invoice_address: taxResidence,
			perceptions: perceptionStatus.join(','),
			tax_status: ivaStatus,
			iibb_status: iibbStatus,
			files: uploadedFiles.files,
			created_by: creator.email,
			default_business_name: businessNameToEdit?.default_business_name,
			main: businessNameToEdit?.main,
			removedFilesIds: filesToDelete,
			validInvoiceProfile: businessNameToEdit?.validInvoiceProfile,
		};

		try {
			const data = updatedBusinessName as any;
			data.userId = creator.id;
			setIsSaving(true);
			
			const result = await businessNameClient.updateBusinessName({ data: updatedBusinessName, user: creator });
			
			if (result.success) {
				updateBusinessNamesList && updateBusinessNamesList();
				callback(false, true);
			}
		} catch (error: any) {
			if (error.response?.status === 413) {
				setError(intl.formatMessage({ id: 'common.payloadTooLarge' }));
			} else {
				setError(intl.formatMessage({ id: 'common.error_message' }));
			}
		} finally {
			setIsSaving(false);
		}
	};

	const makeFilesArrays = async () => {
		const files: FileUploadRequest[] = [];
		
		const renamedMiPymeCertificateFiles = randomizeFileNames([...miPymeCertificate.filter((file) => file.size > 0)]);
		const renamedIvaCertificateFiles = randomizeFileNames([...ivaCertificate.filter((file) => file.size > 0)]);
		const renamedIvaExemptionCertificateFiles = randomizeFileNames([...ivaExemptionCertificate.filter((file) => file.size > 0)]);
		const renamedIibbLocalCertificateFiles = randomizeFileNames([...iibbLocalCertificate.filter((file) => file.size > 0)]);
		const renamedIibbMultilateral01CertificateFiles = randomizeFileNames([...iibbMultilateral01Certificate.filter((file) => file.size > 0)]);
		const renamedIibbMultilateral05CertificateFiles = randomizeFileNames([...iibbMultilateral05Certificate.filter((file) => file.size > 0)]);
		const renamedIibbExemptionCertificateFiles = randomizeFileNames([...iibbExemptionCertificate.filter((file) => file.size > 0)]);
		const renamedIiggPerceptionCertificateFiles = randomizeFileNames([...iiggPerceptionCertificate.filter((file) => file.size > 0)]);
		const renamedIvaPerceptionCertificateFiles = randomizeFileNames([...ivaPerceptionCertificate.filter((file) => file.size > 0)]);
		const renamedIibbPerceptionCertificateFiles = randomizeFileNames([...iibbPerceptionCertificate.filter((file) => file.size > 0)]);
		const renamedSussPerceptionCertificateFiles = randomizeFileNames([...sussPerceptionCertificate.filter((file) => file.size > 0)]);

		appendFile(files, renamedMiPymeCertificateFiles, DocumentationTypes.MI_PYME_CERT);
		appendFile(files, renamedIvaCertificateFiles, DocumentationTypes.CUIT_CERT);
		appendFile(files, renamedIvaExemptionCertificateFiles, DocumentationTypes.IVA_EXCL_CERT);
		appendFile(files, renamedIibbLocalCertificateFiles, DocumentationTypes.IIBB_LOCAL_CERT);
		appendFile(files, renamedIibbMultilateral01CertificateFiles, DocumentationTypes.IIBB_MULTILATERAL_01_CERT);
		appendFile(files, renamedIibbMultilateral05CertificateFiles, DocumentationTypes.IIBB_MULTILATERAL_05_CERT);
		appendFile(files, renamedIibbExemptionCertificateFiles, DocumentationTypes.IIBB_EXCL_CERT);
		appendFile(files, renamedIiggPerceptionCertificateFiles, DocumentationTypes.IIGG_PERC_CERT);
		appendFile(files, renamedIvaPerceptionCertificateFiles, DocumentationTypes.IVA_PERC_CERT);
		appendFile(files, renamedIibbPerceptionCertificateFiles, DocumentationTypes.IIBB_PERC_CERT);
		appendFile(files, renamedSussPerceptionCertificateFiles, DocumentationTypes.SUSS_PERC_CERT);
		
		const originalFiles = [
			...renamedMiPymeCertificateFiles, 
			...renamedIvaCertificateFiles, 
			...renamedIvaExemptionCertificateFiles, 
			...renamedIibbLocalCertificateFiles, 
			...renamedIibbMultilateral01CertificateFiles, 
			...renamedIibbMultilateral05CertificateFiles, 
			...renamedIibbExemptionCertificateFiles, 
			...renamedIiggPerceptionCertificateFiles, 
			...renamedIvaPerceptionCertificateFiles, 
			...renamedIibbPerceptionCertificateFiles, 
			...renamedSussPerceptionCertificateFiles, 
		];

		return {files, originalFiles}
	};

	const uploadFiles = async () => {
		try {
			const {files, originalFiles} = await makeFilesArrays();

			const preparedFiles = await businessNameClient.prepareBusinessNameUpload({
				user: creator,
				entityId: businessNameToEdit?.id || undefined,
				files,
			});

			if (!preparedFiles) {
				return {
					success: true,
					files: [],
					businessNameId: undefined,
				}
			}

			const uploadPromises = originalFiles.flatMap((file) => {
				const fileIndex = preparedFiles.files.findIndex((f: { originalName: string; }) => f.originalName === file?.name);
				if (fileIndex >= 0) {
					const fileUrl = preparedFiles.files[fileIndex].upload_url;

					return filesClient.uploadFileWithUrl(fileUrl, file);
				}
				return [];
			});

			const responses = await Promise.all(uploadPromises);

			if (responses.some((response) => !response.success)) {
				throw new Error('Error uploading files');
			}

			return preparedFiles;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	const checkAlreadyExists = async (cuit: Number, businessName: string) => {
		const data = { cuit: cuit, name: businessName };

		try {
			const result = await businessNameClient.checkBusinessNameExists({ data: data, user: creator });
			if (result) {
				if (result.cuit && result.name) {
					handleShowError(true, intl.formatMessage({ id: 'settings.company.tax_profile_tab.existent_cuit' }));
					return true;
				}
				if (result.name) {
					handleShowError(true, intl.formatMessage({ id: 'settings.company.tax_profile_tab.existent_business_name' }));
					return true;
				}
				handleShowError(true, intl.formatMessage({ id: 'settings.company.tax_profile_tab.existent_cuit' }));

				return true;
			} else {
				return false;
			}
		} catch (error) { 
			console.error(error);
			return false;
		}
	};

	const isShowFileRequired = (filePicker: File[]): boolean => {
		if (filePicker.length === 0 && firstSubmit) {
			allFilesPresent = true;
			return true;
		}
		return false;
	};

	const checkRequiredFilesPresent = () => {
		if (ivaStatus !== '') {
			if (ivaCertificate.length === 0) return false;
			if (ivaStatus === ivaOptions[3].value && ivaExemptionCertificate.length === 0) return false;
		}

		if (iibbStatus === iibbOptions[0].value && iibbLocalCertificate.length === 0) return false;

		if (
			iibbStatus === iibbOptions[1].value &&
			(iibbMultilateral01Certificate.length === 0 || iibbMultilateral05Certificate.length === 0)
		)
			return false;
		if (iibbStatus === iibbOptions[2].value && iibbExemptionCertificate.length === 0) return false;

		if (perceptionStatus.includes(perceptionOptions[0].value) && iiggPerceptionCertificate.length === 0) return false;
		if (perceptionStatus.includes(perceptionOptions[1].value) && ivaPerceptionCertificate.length === 0) return false;
		if (perceptionStatus.includes(perceptionOptions[2].value) && iibbPerceptionCertificate.length === 0) return false;
		if (perceptionStatus.includes(perceptionOptions[3].value) && sussPerceptionCertificate.length === 0) return false;

		return true;
	};

	useEffect(() => {
		const getStoragedFiles = async () => {
			if (businessNameToEdit?.files?.length > 0) {
				for (const file of businessNameToEdit?.files) {
					const blob = new Blob();
					const filename = file.name;
					const newFile = new File([blob], filename, { type: file.id });
					

					switch (Number(file.type)) {
						case DocumentationTypes.MI_PYME_CERT:
							setMiPymeCertificate([newFile]);
							break;
						case DocumentationTypes.CUIT_CERT:
							setIvaCertificate([newFile]);
							break;
						case DocumentationTypes.IVA_EXCL_CERT:
							setIvaExemptionCertificate([newFile]);
							break;
						case DocumentationTypes.IIBB_LOCAL_CERT:
							setIibbLocalCertificate([newFile]);
							break;
						case DocumentationTypes.IIBB_MULTILATERAL_01_CERT:
							setIibbMultilateral01Certificate([newFile]);
							break;
						case DocumentationTypes.IIBB_MULTILATERAL_05_CERT:
							setIibbMultilateral05Certificate([newFile]);
							break;
						case DocumentationTypes.IIBB_EXCL_CERT:
							setIibbExemptionCertificate([newFile]);
							break;
						case DocumentationTypes.IIGG_PERC_CERT:
							setIiggPerceptionCertificate([newFile]);
							break;
						case DocumentationTypes.IVA_PERC_CERT:
							setIvaPerceptionCertificate([newFile]);
							break;
						case DocumentationTypes.IIBB_PERC_CERT:
							setIibbPerceptionCertificate([newFile]);
							break;
						case DocumentationTypes.SUSS_PERC_CERT:
							setSussPerceptionCertificate([newFile]);
							break;
						default:
							break;
					}
				}
			}
		};
		getStoragedFiles();
	}, []);

	return (
		<>
			<Grid container px={isModal ? '2rem' : 0}>
				{showError && (
					<Grid item xs={12}>
						<InformationBar className="mt-4" icon="info" message={errorMessage}></InformationBar>
					</Grid>
				)}

				<Grid item minHeight={'28rem'} xs={12}>
					<Stack spacing={2}>
						<TextFieldWithPlaceholder
							label={intl.formatMessage({ id: 'header.tax_id_label' })}
							placeholder={intl.formatMessage({ id: 'settings.company.tax_profile_tab.cuit_placeholder' })}
							required
							disabled={businessNameToEdit?.main ? true : false}
							fullWidth
							onChange={(event) => {
								const input = event.target.value;
								const numericInput = input.replace(/\D/g, '');
								setCuit(numericInput);
							}}
							value={cuit}
							error={firstSubmit && cuit === '' ? true : false}
						></TextFieldWithPlaceholder>

						<TextFieldWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.tax_profile_tab.organization_name_input' })}
							placeholder={intl.formatMessage({
								id: 'settings.company.tax_profile_tab.organization_name_placeholder',
							})}
							required
							fullWidth
							onChange={(event) => setOrganizationName(event.target.value as string)}
							value={organizationName}
							error={firstSubmit && organizationName === '' ? true : false}
						></TextFieldWithPlaceholder>

						<TextFieldWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.tax_profile_tab.tax_residence_input' })}
							placeholder={intl.formatMessage({ id: 'settings.company.tax_profile_tab.tax_residence_placeholder' })}
							fullWidth
							onChange={(event) => setTaxResidence(event.target.value as string)}
							value={taxResidence}
							required
							error={firstSubmit && taxResidence === '' ? true : false}
						></TextFieldWithPlaceholder>

						<Box>
							<InputLabel>
								{intl.formatMessage({ id: 'settings.company.tax_profile_tab.mipyme_certificate_input' })}
							</InputLabel>
							<NuqleaFileUpload value={miPymeCertificate} onChange={setMiPymeCertificate} />
						</Box>

						<SelectWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.tax_profile_tab.iva_status_input' })}
							placeholder={intl.formatMessage({ id: 'settings.company.select_placeholder' })}
							onChange={(event) => setIvaStatus(event.target.value as string)}
							fullWidth
							value={ivaStatus}
							required
							error={ivaStatus === '' && firstSubmit}
						>
							{ivaOptions.map((item) => (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							))}
						</SelectWithPlaceholder>

						{ivaStatus !== '' && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iva_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(ivaCertificate) ? true : false}
									value={ivaCertificate}
									onChange={setIvaCertificate}
								/>
							</Box>
						)}

						{ivaStatus === ivaOptions[3].value && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iva_exemption_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(ivaExemptionCertificate)}
									value={ivaExemptionCertificate}
									onChange={setIvaExemptionCertificate}
								/>
							</Box>
						)}

						<SelectWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.tax_profile_tab.iibb_status_input' })}
							placeholder={intl.formatMessage({ id: 'settings.company.select_placeholder' })}
							onChange={(event) => setIibbStatus(event.target.value as string)}
							fullWidth
							value={iibbStatus}
							required
							error={iibbStatus === '' && firstSubmit}
						>
							{iibbOptions.map((item) => (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							))}
						</SelectWithPlaceholder>

						{iibbStatus === iibbOptions[0].value && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iibb_local_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(iibbLocalCertificate)}
									value={iibbLocalCertificate}
									onChange={setIibbLocalCertificate}
								/>
							</Box>
						)}

						{iibbStatus === iibbOptions[1].value && (
							<>
								<Box>
									<InputLabel>
										{intl.formatMessage({
											id: 'settings.company.tax_profile_tab.iibb_multilateral01_certificate_input',
										})}
									</InputLabel>
									<NuqleaFileUpload
										showRequired={isShowFileRequired(iibbMultilateral01Certificate)}
										value={iibbMultilateral01Certificate}
										onChange={setIibbMultilateral01Certificate}
									/>
								</Box>
								<Box>
									<InputLabel>
										{intl.formatMessage({
											id: 'settings.company.tax_profile_tab.iibb_multilateral05_certificate_input',
										})}
									</InputLabel>
									<NuqleaFileUpload
										showRequired={isShowFileRequired(iibbMultilateral05Certificate)}
										value={iibbMultilateral05Certificate}
										onChange={setIibbMultilateral05Certificate}
									/>
								</Box>
							</>
						)}

						{iibbStatus === iibbOptions[2].value && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iibb_exemption_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(iibbExemptionCertificate)}
									value={iibbExemptionCertificate}
									onChange={setIibbExemptionCertificate}
								/>
							</Box>
						)}

						<SelectMultiWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.tax_profile_tab.perception_agent_input' })}
							placeholder={intl.formatMessage({ id: 'settings.company.select_multi_placeholder' })}
							onValueChangeCallback={setPerceptionStatus}
							value={perceptionStatus}
							fullWidth
						>
							{perceptionOptions.map((item) => (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							))}
						</SelectMultiWithPlaceholder>

						{perceptionStatus.includes(perceptionOptions[0].value) && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iigg_perception_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(iiggPerceptionCertificate)}
									value={iiggPerceptionCertificate}
									onChange={setIiggPerceptionCertificate}
								/>
							</Box>
						)}

						{perceptionStatus.includes(perceptionOptions[1].value) && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iva_perception_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(ivaPerceptionCertificate)}
									value={ivaPerceptionCertificate}
									onChange={setIvaPerceptionCertificate}
								/>
							</Box>
						)}

						{perceptionStatus.includes(perceptionOptions[2].value) && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iibb_perception_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(iibbPerceptionCertificate)}
									value={iibbPerceptionCertificate}
									onChange={setIibbPerceptionCertificate}
								/>
							</Box>
						)}

						{perceptionStatus.includes(perceptionOptions[3].value) && (
							<Box pb={'10px'}>
								<InputLabel sx={{ fontFamily: 'inherit', fontWeight: 500, color: '#4B4B4B' }}>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.suss_perception_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(sussPerceptionCertificate)}
									value={sussPerceptionCertificate}
									onChange={setSussPerceptionCertificate}
								/>
							</Box>
						)}
					</Stack>
					{isSaving && <LinearProgress sx={{ mt: '30px' }}></LinearProgress>}
				</Grid>

				<Grid item xs={12} sm={isMobile || isModal ? 12 : 6}>
					<Stack
						pt={'1.5rem'}
						direction="row"
						width={'100%'}
						justifyContent={isModal ? 'space-around' : 'left'}
						spacing={2}
					>
						<Button disabled={isSaving} onClick={() => callback(false, false)} variant="outlined">
							{intl.formatMessage({ id: 'common.cancel' })}
						</Button>
						<LoadingButton
							loading={isChecking}
							disabled={isSaving}
							onClick={handleSave}
							variant="contained"
							sx={{ ml: '10px' }}
						>
							{intl.formatMessage({ id: 'common.save' })}
						</LoadingButton>
					</Stack>
				</Grid>
			</Grid>
			<Snackbar
				open={!!error}
				onClose={() => setError('')}
				autoHideDuration={8000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert severity="error" variant="filled">
					{error}
				</Alert>
			</Snackbar>
		</>
	);
};

export default TaxProfileForm;
