export const categoryTypes = [
    { id: 1, label: 'Aberturas', value: 'openings' },
    { id: 2, label: 'Construcción en seco', value: 'dry_construction' },
    { id: 3, label: 'Corralón', value: 'building_supply_store' },
    { id: 4, label: 'Electricidad', value: 'electricity' },
    { id: 5, label: 'Hormigón', value: 'concrete' },
    { id: 6, label: 'Siderurgia', value: 'steel_industry' },
    { id: 7, label: 'Ladrillos', value: 'bricks' },
    { id: 8, label: 'Morteros', value: 'mortars' },
    { id: 9, label: 'Pinturas', value: 'paints' },
    { id: 10, label: 'Pisos', value: 'flooring' },
    { id: 11, label: 'Sanitarios y griferías', value: 'sanitary_faucets' },
    { id: 12, label: 'Otros', value: 'others' },
];

export const accountTypes: { id: number; label: string; value: string }[] = [
    { id: 1, label: 'Constructora', value: 'constructor' },
    { id: 2, label: 'Desarrolladora', value: 'developer' },
    { id: 3, label: 'Estudio de arquitectura', value: 'architect' },
    { id: 4, label: 'Gerenciadora', value: 'manager' },
    { id: 5, label: 'Vendedor / Proveedor', value: 'seller' },
];

export const workAreaTypes: { id: number; label: string; value: string }[] = [
    { id: 1, label: 'Compras', value: 'purchase' },
    { id: 2, label: 'Obras', value: 'works' },
    { id: 3, label: 'Administración y finanzas', value: 'administration_and_finance' },
    { id: 4, label: 'Oficina técnica y licitaciones', value: 'technical_office_and_tenders' },
];
