import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	Box,
	Button,
	Dialog,
	Divider,
	Grid,
	Typography,
	styled as styledMaterial,
	useMediaQuery,
	useTheme,
	Menu,
	MenuItem,
	debounce,
	LinearProgress,
	Tooltip,
	CircularProgress,
	Snackbar,
	Alert,
} from '@mui/material';
import emptyStateLogo from '../../../assets/wallet-empty-state.svg';
import { useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MyTokensTable from './MyTokensTable';
import TokensQuantity from './tokens-quantity';
import client from '../../../clients/client';
import { Role, User } from '../../../types';
import RouterLinkWithDisabled from '../../router-link-with-disabled';
import { useQuery } from '../../../hooks/use-query';
import BasePageFrame from '../../base-components/base-page-frame';
import InfoButton from '../../info-button';
import { blue } from '@mui/material/colors';
import { useRecoilValue } from 'recoil';
import userStateFromRecoil from '../../../atoms/user-state';
import agreementsOptionsState from '../../../atoms/agreements-options-state';

interface Props {
	toggleEmptyState: (showEmptyState: boolean) => void;
}

export interface AgreementContent {
	id: string | undefined;
	product_label: string;
	seller_name: string;
	seller_logo: string;
	unity?: string;
	token_validity?: number;
}

const EmptyStateImage = styled.img`
	width: 300px;
`;

export const StyledMyTokensDashboardDialog = styledMaterial(Dialog)(({ theme }) => ({
	'& .MuiPaper-root': {
		padding: theme.spacing(1),
		borderRadius: '10px',
		maxWidth: 'none',
	},
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(2),
		marginRight: '10px',
	},
	'& .MuiTimelineItem-root': {
		minHeight: '0px',
		height: 'auto',
	},
	'& .MuiTypography-root': {
		paddingTop: '0',
	},
	'& .MuiTimelineConnector-root': {
		height: '30px',
		minHeight: 'none',
		backgroundColor: 'black',
	},
	'& .MuiTimelineDot-root': {
		width: '25px',
		height: '25px',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'black',
		fontSize: '13px',
		lineHeight: 1,
		marginTop: 0,
		marginBottom: 0,
	},
}));

const renderAgreementsMenuItem = (menuContent: AgreementContent, style: any) => {
	return (
		<Box sx={style}>
			<Typography sx={{ textAlign: 'left', fontSize: '14px', color: 'grey.600' }}>
				{menuContent.product_label}
			</Typography>
		</Box>
	);
};

const Tokens = (props: Props) => {
	const location = useLocation();
	const state = location.state as { message?: string }; // Type assertion to ensure TypeScript knows the shape of state

	const { toggleEmptyState } = props;
	let query = useQuery();
	const user = useRecoilValue<User>(userStateFromRecoil);
	const { agreementsOptions, isLoading: isLoadingAgreementsOptions } = useRecoilValue<{
		agreementsOptions: AgreementContent[],
		isLoading: boolean
	}>(agreementsOptionsState);

	const navigate = useNavigate();
	const [userState, setUserState] = useState({ isStateEmpty: true, canBuy: false, canExchange: false });
	const [clocksLoading, setClocksLoading] = useState(false);
	const [selectedAgreement, setSelectedAgreement] = useState<AgreementContent>({
		id: undefined,
		product_label: '',
		seller_name: '',
		seller_logo: '',
	});
	const [isLoadingUserState, setisLoadingUserState] = useState(false);
	const [error, setError] = useState<string>(state?.message || '');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleAgreementSelection = (id?: string) => {
		setSelectedAgreement(agreementsOptions.find((item) => item.id === id) ?? selectedAgreement);
		handleClose();
	};

	const intl = useIntl();
	const theme = useTheme();

	const lessThanTablet = useMediaQuery(theme.breakpoints.down('md'));
	const lessThanXLarge = useMediaQuery(theme.breakpoints.down('xl'));

	const fetchAgreementState = debounce((agreementId) => {
		setisLoadingUserState(true);
		client
			.getAgreementsState({ token: user.token, agreementId })
			.then((data) => {
				setUserState({ canBuy: data.canBuy, isStateEmpty: data.emptyState, canExchange: false });
				if (query.has('agreement') && agreementId) {
					query.set('agreement', agreementId);
					window.history.replaceState(null, '', `/my-tokens?${query.toString()}`);
				} else {
					if (agreementId) {
						query.append('agreement', agreementId);
						window.history.replaceState(null, '', `/my-tokens?${query.toString()}`);
					}
				}
			})
			.catch((err) => {
				setError(intl.formatMessage({ id: 'common.error_message' }));
			})
			.finally(() => {
				setisLoadingUserState(false);
			});
	}, 250);

	useEffect(() => {
		const defaultAgreement = query.has('agreement')
			? query.get('agreement')
			: agreementsOptions.length > 0
				? agreementsOptions[0].id
				: undefined;
		if (defaultAgreement) {
			setSelectedAgreement(agreementsOptions.find((item) => item.id === defaultAgreement) ?? selectedAgreement);
		}
	}, [agreementsOptions]);

	useEffect(() => {
		if (!isLoadingUserState && selectedAgreement.id) {
			fetchAgreementState(selectedAgreement.id);
		}
	}, [selectedAgreement]);

	return (
		<BasePageFrame
			title={intl.formatMessage({ id: 'mytokens.constructor.dashboard.title' })}
			subtitle={intl.formatMessage({ id: 'mytokens.constructor.dashboard.subtitle' })}
			endAdornment={
				<InfoButton
					text={intl.formatMessage({ id: 'common.more_information' })}
					onClick={() => toggleEmptyState(true)}
				></InfoButton>
			}
		>
			<Grid container spacing={{ md: 2, xs: 0 }}>
				<Grid item xs={12}>
					{isLoadingAgreementsOptions && (
						<>
							<Box sx={{ width: '100%', position: 'absolute', zIndex: 1, top: 0, left: 0 }}>
								<LinearProgress color="inherit" />
							</Box>
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								{intl.formatMessage({ id: 'common.loading' })}
							</Box>
						</>
					)}
					{agreementsOptions.length === 0 && !isLoadingAgreementsOptions && (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '15.6rem',
							}}
						>
							<Typography sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }}>No hay acuerdos para mostrar</Typography>
						</Box>
					)}
					{agreementsOptions.length > 0 && !isLoadingAgreementsOptions && (
						<>
							<Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: lessThanTablet ? 'column' : 'row' }}>
								<Box
									sx={{
										display: 'flex',
										flex: 4,
										flexDirection: lessThanXLarge ? 'column' : 'row'
									}}
								>
									<Box sx={{ flex: 1 }} alignContent={'center'} paddingRight={1}>
										<Typography variant="h3">
											{intl.formatMessage({ id: 'mytokens.seller.dashboard.transactions.title' })}
										</Typography>
									</Box>
									<Box sx={{ flex: 1 }} alignContent={'center'}>
										<Button
											onClick={handleClick}
											color="inherit"
											endIcon={agreementsOptions.length !== 1 && <KeyboardArrowDownIcon />}
											sx={{
												justifyContent: 'space-between',
												flex: 2,
												height: '2.5rem',
												borderRadius: '4px',
												border: '1px solid #A5A5B3',
												right: 0,
												width: '100%',
											}}
										>
											{renderAgreementsMenuItem(selectedAgreement, null)}
										</Button>
										{agreementsOptions.length > 1 && (
											<Menu
												id="basic-menu"
												anchorEl={anchorEl}
												open={open}
												onClose={handleClose}
												container={document.body}
												sx={{ '& .MuiList-root': { paddingTop: 0, paddingBottom: 0 }, borderColor: '#D9D9D9' }}
											>
												{agreementsOptions
													.filter((option) => option !== selectedAgreement)
													.map((item: AgreementContent) => (
														<MenuItem
															key={item.id}
															onClick={() => handleAgreementSelection(item.id)}
															sx={{ backgroundColor: 'rgba(200, 200, 200, 0.30)' }}
														>
															{renderAgreementsMenuItem(item, { py: '0.4rem', px: '1rem' })}
														</MenuItem>
													))}
											</Menu>
										)}
									</Box>
								</Box>
								<Divider
									orientation={lessThanTablet ? 'horizontal' : 'vertical'}
									sx={{ height: 'auto', margin: lessThanTablet ? '1rem 0rem' : '0 1rem', borderColor: '#C6C6C666' }}
								/>
								<Box
									sx={{
										display: 'flex',
										flex: 1,
										gap: 2,
										justifyContent: 'center',
										flexWrap: lessThanTablet ? 'wrap' : 'nowrap',
										alignItems: 'center',
									}}
									alignContent={'center'}
								>
									<Tooltip
										placement="bottom"
										title={
											userState.canExchange
												? ''
												: intl.formatMessage({
														id: 'mytokens.constructor.dashboard.unavailable_exchange_tokens_tooltip',
												  })
										}
									>
										<RouterLinkWithDisabled
											disabled={
												userState.isStateEmpty || !userState.canExchange || isLoadingUserState || !selectedAgreement.id
											}
											path={`/my-tokens/new-redeem?agreementId=${selectedAgreement.id ?? ''}`}
										>
											<Button
												variant="contained"
												disabled={
													userState.isStateEmpty ||
													!userState.canExchange ||
													isLoadingUserState ||
													!selectedAgreement.id
												}
												sx={{
													width: '8.1rem',
													height: '2.5rem',
													color: '#406AFF',
													backgroundColor: blue[50],
													textTransform: 'none',
													borderRadius: '4px',
													'&:hover': { backgroundColor: blue[100] },
												}}
											>
												{intl.formatMessage({ id: 'mytokens.constructor.dashboard.exchange_tokens' })}
											</Button>
										</RouterLinkWithDisabled>
									</Tooltip>
									<Tooltip
										placement="bottom"
										title={
											userState.canBuy
												? ''
												: intl.formatMessage({
														id: 'mytokens.constructor.dashboard.unavailable_buy_tokens_tooltip',
												  })
										}
									>
										<span>
											<Button
												variant="contained"
												sx={{
													width: '8.1rem',
													height: '2.5rem',
													color: 'white',
													textTransform: 'none',
													borderRadius: '4px',
												}}
												onClick={() => navigate(`/my-tokens/buy?agreement=${selectedAgreement?.id}`)}
												disabled={!selectedAgreement.id || !userState.canBuy}
											>
												{intl.formatMessage({ id: 'mytokens.constructor.dashboard.buy_tokens' })}
											</Button>
										</span>
									</Tooltip>
								</Box>
							</Box>

							<Divider orientation="horizontal" sx={{ margin: '1rem 0', borderColor: '#C6C6C666' }} />
							<TokensQuantity
								type={Role.CONSTRUCTOR}
								token={user.token}
								agreementId={selectedAgreement.id}
								loading={clocksLoading || isLoadingUserState}
								stopLoading={(state: boolean) => setClocksLoading(state)}
								setCanExchange={(value: boolean) => setUserState((prevState) => ({ ...prevState, canExchange: value }))}
							/>
							{isLoadingUserState ? (
								<Box sx={{ display: 'flex', justifyContent: 'center', color: 'grey.500' }}>
									<CircularProgress color="inherit" />
								</Box>
							) : userState.isStateEmpty ? (
								<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
									<EmptyStateImage src={emptyStateLogo} alt="empty-state-logo" />
									<Typography
										variant="h5"
										sx={{
											marginBottom: '3rem',
											marginTop: '0.5rem',
											fontSize: '1.5rem',
											color: '#707070',
											width: '35%',
											textAlign: 'center',
										}}
									>
										{intl.formatMessage({ id: 'mytokens.constructor.dashboard.transactions.empty_state' })}
									</Typography>
									<Button
										variant="contained"
										sx={{
											textTransform: 'none',
											py: '1rem',
											px: '2rem',
											borderRadius: '2rem',
											marginTop: { xs: '1rem', md: 0 },
										}}
										startIcon={<AddIcon />}
										onClick={() => navigate(`/my-tokens/buy?agreement=${selectedAgreement?.id}`)}
										disabled={!selectedAgreement.id || !userState.canBuy}
									>
										{intl.formatMessage({ id: 'mytokens.constructor.dashboard.buy_tokens' })}
									</Button>
								</Box>
							) : (
								<MyTokensTable
									type={Role.CONSTRUCTOR}
									user={user}
									agreementId={selectedAgreement.id}
									isLoadingUserState={isLoadingUserState}
								/>
							)}
						</>
					)}
				</Grid>
				<Snackbar
					open={!!error}
					onClose={() => setError('')}
					autoHideDuration={6000}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					<Alert severity="error" variant="filled">
						{error}
					</Alert>
				</Snackbar>
			</Grid>
		</BasePageFrame>
	);
};

export default Tokens;
