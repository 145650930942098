import { Grid, Typography, debounce, Avatar, Skeleton, Tooltip, Box, FormControlLabel } from '@mui/material';
import { ChangeEvent, useEffect, useState, useCallback } from 'react';
import client from '../../clients/client';
import { Role, Lists, Response, ConstructionAddress } from '../../types';
import TextField from '@mui/material/TextField';
import { olderStartDateConstructores, showOlderListsConstructors, startDateConstructor } from '../../constants';
import { useIntl } from 'react-intl';
import { PaginatableTable } from '../table-paginatable';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import { useMobile } from '../../hooks/use-mobile';
import SelectWithCheck from '../select-with-custom-check';
import { Row } from '../../helpers/quotationsHelper';
import * as QuotHelper from '../../helpers/quotationsHelper';
import { startDateSeller } from '../../constants';
import { useRecoilValue } from 'recoil';
import userState from '../../atoms/user-state';
import BasePageFrame from '../base-components/base-page-frame';
import CustomSwitch from '../customSwitch';

const Quotations = () => {
	const [isLoading, setIsLoading] = useState(true);
	const isMobile: boolean = useMobile();
	const user = useRecoilValue(userState);
	const showOlderLists = showOlderListsConstructors.includes(user.id_external);
	const intl = useIntl();
	const dropdownOptions = QuotHelper.getDropDownOptions(user);
	const columns = QuotHelper.getColumns(user);
	const searcherPlaceholder = QuotHelper.getSearcherPlaceholderText(user);
	const [quotationsLists, setQuotationsLists] = useState<Response<Lists>>();
	const [rows, setRows] = useState<Row[]>([]);
	const [filteredRows, setFilteredRows] = useState<Row[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [dropDownSelected, setDropDownSelected] = useState<string>('Todas tus cotizaciones');
	const [hideNotCompleted, setHideNotCompleted] = useState<boolean>(true);

	const handleDropDownChange = (value: string) => {
		setDropDownSelected(value);
	};

	const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	};

	const handleToggleChange = () => {
		setHideNotCompleted(!hideNotCompleted);
	};

	const retrieve = (address: ConstructionAddress) => {
		console.log('retrieve');
	};

	const cancel = () => {
		console.log('cancel');
	};

	function capitalizeFirstLetter(str: string) {
		return str
			? str.toLowerCase().replace(/\b\w/g, (char, index, inputStr) => {
					return index === 0 || inputStr[index - 1] === ' ' ? char.toUpperCase() : char;
			  })
			: '';
	}

	const getLabeledStatus = (row: Row) => {
		return (
			<Tooltip placement="top" disableFocusListener title={row.statusLabelString} arrow>
				<Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', pl: isMobile ? '30%' : 0 }}>
					<Box sx={{
						borderRadius: '50%',
						width: '10px',
						minWidth: '10px',
						minHeight: '10px',
						height: '10px',
						backgroundColor: row.statusDotColor
					}}></Box>
					{!isMobile && (
						<Typography variant='body2' ml={'1rem'} className='font-semibold text-black truncate'>
							{row.statusLabelString}
						</Typography>
					)}
				</Box>
			</Tooltip>
		);
	};

	useEffect(() => {
		const fetchQuotationsConstructor = debounce(() => {
			client
				.list({
					type: Role.CONSTRUCTOR,
					startDate: showOlderLists ? olderStartDateConstructores : startDateConstructor,
					orderBy: 'reception_date',
					desc: false,
					user,
				})
				.then((data) => {
					data.lists = data.lists.map((list) => {
						list.list_migration_id = list.list_id;
						return list;
					});
					setQuotationsLists({ data });
				})
				.catch(() => setQuotationsLists({ error: true }))
				.finally(() => setIsLoading(false));
		}, 250);

		const fetchQuotationsSeller = debounce(() => {
			client
				.list({
					type: Role.SELLER,
					startDate: startDateSeller,
					orderBy: 'quotation_order_date',
					desc: true,
					user,
				})
				.then((data) => {
					setQuotationsLists({ data });
				})
				.catch(() => setQuotationsLists({ error: true }))
				.finally(() => setIsLoading(false));
		}, 250);

		user.type_external === 'constructor' ? fetchQuotationsConstructor() : fetchQuotationsSeller();
	}, []);

	useEffect(() => {
		const list = quotationsLists?.data?.lists;
		const processedData: Row[] = (list ?? []).map((item) => {
			const row: Row = {
				id: user.type_external === Role.CONSTRUCTOR ? item.whatsapp_list_id : item.list_migration_id,
				product: item.product_reference,
				proyect: item.project_name,
				seller: item.seller_label,
				date: item.reception_date,
				locality: <>{
					user.type_external === Role.CONSTRUCTOR ?
					item.locality_label :
						item.locality_label ? item.locality_label : (
						(item.address_label || item.address_city || item.address_province) ? 
							<Tooltip arrow placement="top" title={`${item.address_label ? item.address_label + ', ' : ''} ${item.address_city ? item.address_city + ', ' : ''} ${item.address_province ? item.address_province + ', ' : ''}`}><div className="truncate">{`${item.address_city ? item.address_city + ', ' : ''} ${item.address_province ? item.address_province + ', ' : ''}`}</div></Tooltip> : '-')
				}</>,
				client: item.client_name,
				status: capitalizeFirstLetter(item.list_status),
				statusLabel: '',
				statusLabelString: '',
				statusDotColor: '',
				statusForRole:
					user.type_external === Role.CONSTRUCTOR ? item.list_status_for_constructor : item.list_status_for_seller,
				orderStatus: item.order_status,
				quoteStatus: item.quote_status,
				quoteType: item.quoation_type,
				deliveryStatus: item.delivery_status_label,
				requester: user.type_external === Role.CONSTRUCTOR ? item.created_by_name ?? 'A' : item.client_name,
				requesterLabel: '',
				comparativeExpDate: item.comparative_expiration_date,
				onClickUrl:
					user.type_external === Role.CONSTRUCTOR
						? `${window.location.origin}/list/${item.list_id}/${item.order_id ?? ''}`
						: `${window.location.origin}/list/${item.list_id}/${item.quotation_id ?? ''}`,
			};
			return row;
		});

		processedData.forEach((item) => {
			user.type_external === 'constructor'
				? QuotHelper.setStatusAndColorForConstructor(item)
				: QuotHelper.setStatusAndColorForSeller(item);
		});

		const formattedRows = processedData.map((item: Row) => {
			let formattedDate = item.date ? new Date(item.date).toLocaleDateString('es-AR') : '-';
			let requesterAvatar = (
				<Tooltip title={item.requester}>
					<Avatar
						sx={{ width: 24, height: 24, fontSize: 13, display: 'flex', justifyContent: 'center', margin: 'auto' }}
					>
						{item?.requester && item?.requester.length > 0 ? item?.requester[0] : '-'}
					</Avatar>
				</Tooltip>
			);

			item.date = formattedDate;
			item.requesterLabel = requesterAvatar;
			// TODO: debería usarse ValueRenderCell
			item.statusLabel = getLabeledStatus(item);

			return item;
		});

		setRows(formattedRows);
		setFilteredRows(formattedRows);
	}, [quotationsLists]);

	const applyFilters = useCallback(() => {
		let filteredData = rows;

		if (searchText) {
			filteredData = QuotHelper.searchFilterLists(searchText, filteredData, user);
		}

		if (dropDownSelected !== 'Todas tus cotizaciones') {
			filteredData = QuotHelper.dropDownFilterLists(
				dropdownOptions.find((option) => option.value === dropDownSelected)?.label ?? '',
				filteredData,
				user
			);
		}

		if (hideNotCompleted) {
			filteredData = filteredData.filter((row) => row.statusForRole !== 'No concretada');
		}

		setFilteredRows(filteredData);
	}, [rows, searchText, dropDownSelected, hideNotCompleted, user, dropdownOptions]);

	useEffect(() => {
		applyFilters();
	}, [applyFilters]);

	return (
		<BasePageFrame
			title={intl.formatMessage({ id: 'common.my_quotations.title' })}
			subtitle={intl.formatMessage({ id: `common.my_quotations.${user.type_external}.subtitle` })}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={8}>
					<TextField
						fullWidth
						onChange={handleSearchInputChange}
						placeholder={isMobile ? 'Ingresa' : searcherPlaceholder}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				</Grid>

				<Grid item xs={12} lg={4}>
					<FormControl fullWidth>
						<SelectWithCheck
							options={dropdownOptions}
							defaultValue={dropdownOptions[0]?.value}
							onValueChangeCallback={handleDropDownChange}
						></SelectWithCheck>
					</FormControl>
					<FormControlLabel
						control={<CustomSwitch checked={hideNotCompleted} onChange={handleToggleChange} />}
						label={
							<Typography
								fontSize={{ md: '.87em', xs: '.88em' }}
								sx={{ color: (theme) => theme.palette.grey[500], ml: 1 }}
							>
								{intl.formatMessage({ id: 'common.my_quotations.quotationsNotCompleted' })}
							</Typography>
						}
						sx={{ marginLeft: 0, marginTop: 1 }}
					/>
				</Grid>

				<Grid item xs={12}>
					{isLoading ? (
						<Skeleton variant="rectangular" height={'50vh'} animation="wave" />
					) : (
						<PaginatableTable
							columns={columns}
							noResultsMessage={intl.formatMessage({ id: 'common.table.quotations.empty_search_message' })}
							isEmptyDataSource={filteredRows.length === 0}
							rows={filteredRows}
							emptyDataSourceMessage={intl.formatMessage({ id: 'common.table.quotations.empty_message' })}
						/>
					)}
				</Grid>
			</Grid>
		</BasePageFrame>
	);
};

export default Quotations;
