import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ModalContainer from '../../modals/modal-container';
import { useMobile } from '../../../hooks/use-mobile';

const CreditProfileTerms: React.FC<{ onclick: () => void }> = ({ onclick }) => {
	const intl = useIntl();
	const isMobile = useMobile();
	return (
		<ModalContainer>
			<Paper
				sx={{
					width: isMobile ? '350px' : '710px',
					maxHeight: '650px',
					px: '3rem',
					py: '2rem',
					overflow: 'auto',
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant="h6" color={'grey.600'} fontWeight={700}>
							{intl.formatMessage({ id: 'terms_dialog.title' })}
						</Typography>
						<Divider sx={{ width: '100%', my: '0.8rem' }}></Divider>
						<Typography color={'grey.600'} fontWeight={600}>
							{intl.formatMessage({ id: 'settings.company.credit_profile.termns_conditions_title' })}
						</Typography>
						<Typography sx={{ mt: '1.3rem' }} color={'grey.600'} whiteSpace={'pre-line'} fontWeight={500}>
							{intl.formatMessage({ id: 'settings.company.credit_profile.termns_conditions_body' })}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box sx={{ display: 'flex', justifyContent: 'center', pt: '2rem' }}>
							<Button sx={{ px: '20px' }} onClick={onclick} variant="contained">
								{intl.formatMessage({ id: 'common.close' })}
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</ModalContainer>
	);
};

export default CreditProfileTerms;
