const HelpContent = [
    {
        "title": "A cotizar",
        "line": "1",
        "symbol": "1",
        "color": "#000000",
        "text": "Pendiente de cotización."
    },
    {
        "title": "A recotizar",
        "line": "1",
        "symbol": "2",
        "color": "#000000",
        "text": "Pendiente de recotización."
    },
    {
        "title": "Cotización compartida",
        "line": "1",
        "symbol": "3",
        "color": "#000000",
        "text": "Ya le enviamos la cotización al cliente. Estamos esperando su respuesta."
    },
    {
        "title": "Aguardando OC",
        "line": "1",
        "symbol": "4",
        "color": "#000000",
        "text": "Estamos esperando que el comprador adjunte la OC para continuar con el proceso."
    },
    {
        "title": "Cargar factura",
        "line": "1",
        "symbol": "5",
        "color": "#000000",
        "text": "Es necesario adjuntar la o las facturas para avanzar con el proceso."
    },
    {
        "title": "Aguardando pago",
        "line": "1",
        "symbol": "6",
        "color": "#000000",
        "text": "Estamos esperando que el comprador adjunte el comprobante de pago para continuar con el proceso."
    },
    {
        "title": "Entrega pendiente",
        "line": "1",
        "symbol": "7",
        "color": "#000000",
        "text": "La compra está en proceso de entrega."
    },
    {
        "title": "Finalizada",
        "line": "",
        "symbol": "8",
        "color": "#000000",
        "text": "Se completó el proceso de compra y entrega."
    }
];

export default HelpContent;