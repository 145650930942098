import { Box, Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {MoreVert as MoreVertIcon, Visibility as VisibilityIcon} from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { Delivery, Role, User } from '../../../../types';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import userState from '../../../../atoms/user-state';
import { useDeliveryContext } from '../../../../contexts/deliveryContext';

interface Props {
	delivery: any;
}

const ToggleActionsButton = (props: Props) => {
	const { delivery } = props;
	const intl = useIntl();
	const user = useRecoilValue(userState);
	const { setDeliveryModal } = useDeliveryContext();

	const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(actionsAnchorEl);

	
	/**
	 * Avoid to mutate the original delivery object on the modal
	 * @param delivery 
	 * @returns delivery object
	 */
	const cloneDelivery = (delivery: Delivery) => JSON.parse(JSON.stringify(delivery));

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (user?.type_external !== Role.SELLER) {
			setDeliveryModal?.({ data: cloneDelivery(delivery), modalType: 'view' });
		} else {
			setActionsAnchorEl(event.currentTarget);
		}
	};
	const handleClose = () => {
		setActionsAnchorEl(null);
	};

	const actionsOptions = useMemo(() => {
		if (delivery.status === 1) { // Pending
			return [
				{
					displayName: intl.formatMessage({ id: 'common.view' }),
					onClick: () => {
						setDeliveryModal?.({ data: cloneDelivery(delivery), modalType: 'view' });
						handleClose();
					},
				},
				{
					displayName: intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.mark_as_completed' }),
					onClick: () => {
						setDeliveryModal?.({ data: cloneDelivery({ ...delivery, status: 2 }), modalType: 'edit' });
						handleClose();
					},
				},
				{
					displayName: intl.formatMessage({ id: 'common.edit' }),
					onClick: () => {
						setDeliveryModal?.({ data: cloneDelivery(delivery), modalType: 'edit' });
						handleClose();
					},
				}
			];
		} else {
			return [
				{
					displayName: intl.formatMessage({ id: 'common.view' }),
					onClick: () => {
						setDeliveryModal?.({ data: cloneDelivery(delivery), modalType: 'view' });
						handleClose();
					},
				},
				{
					displayName: intl.formatMessage({ id: 'common.edit' }),
					onClick: () => {
						setDeliveryModal?.({ data: cloneDelivery(delivery), modalType: 'edit' });
						handleClose();
					},
				}
			];
		}
		}, [delivery, user]);
	
	return (
		<Box>
			<IconButton onClick={handleClick}>
				{user?.type_external === Role.SELLER ? <MoreVertIcon /> : <VisibilityIcon />}
			</IconButton>
			{user?.type_external === Role.SELLER && (
				<Menu
					id="shippings-menu"
					anchorEl={actionsAnchorEl}
					container={document.body}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'shippings-button',
					}}
				>
					{actionsOptions.map((action) => (
						<MenuItem onClick={action.onClick} key={action.displayName} sx={{ paddingRight: 4 }}>
							{action.displayName}
						</MenuItem>
					))}
				</Menu>
			)}
		</Box>
	)
};

export default ToggleActionsButton;
