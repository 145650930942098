import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import axios from 'axios';

interface Props {
	onClose: () => void;
	open: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiPaper-root': {
		padding: theme.spacing(0),
		borderRadius: '10px',
		width: '60%',
		height: '60%',
		[theme.breakpoints.down('md')]: {
			height: '90%',
			width: '80%',
		},
		maxWidth: 'none',
	},
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(0),
		},
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(2),
		margin: 0,
	},
}));

const AcceptTermsDialog = (props: Props) => {
	const { open, onClose } = props;
	const intl = useIntl();

	const [content, setContent] = React.useState('');

	React.useEffect(() => {
		(async () => {
			await axios.get(`${window.location.origin}/assets/terms.md`).then((result) => {
				setContent(result.data);
			});
		})();
	}, []);

	return (
		<Box>
			<BootstrapDialog aria-labelledby="customized-dialog-title" open={open} onClose={onClose}>
				<DialogTitle
					sx={{
						alignSelf: 'center',
						justifyContent: 'center',
						fontSize: '1.5rem',
						fontWeight: 'bold',
						paddingTop: '8px',
						marginTop: '10px',
						borderBottom: '0px',
					}}
				>
					{intl.formatMessage({ id: 'terms_dialog.title' })}
				</DialogTitle>
				<DialogContent>
					<div
						style={{ paddingRight: '48px', paddingLeft: '48px' }}
						className="content"
						dangerouslySetInnerHTML={{ __html: content }}
					></div>
				</DialogContent>
				<DialogActions className="flex justify-center align-middle">
					<Box sx={{ width: { md: '25%', xs: '40%' }, marginRight: '15px' }}>
						<Button onClick={onClose} fullWidth variant="contained">
							{intl.formatMessage({ id: 'common.continue' })}
						</Button>
					</Box>
				</DialogActions>
			</BootstrapDialog>
		</Box>
	);
};

export default AcceptTermsDialog;
