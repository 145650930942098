import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Stack, Divider, Link } from '@mui/material';
import { useIntl } from 'react-intl';
import { formatCUIT, formatNumberWithCurrency } from '../../../utils/data-utils';
import { useNavigate } from 'react-router-dom';
import { useContextBuyTokens } from './buy-tokens';
import NuqleaAlert from '../../nuqlea-alert';
import SideModalContainer from '../../modals/side-modal-container';
import { useMobile } from '../../../hooks/use-mobile';
import TaxProfileForm from '../../settings/organization/shared-components/tax-profile-form';
import disableRootElementScrollState from '../../../atoms/disable-root-element-scroll-state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import userState from '../../../atoms/user-state';


const ConfirmedStep = () => {
    const intl = useIntl();
    const isMobile: boolean = useMobile();
    const navigate = useNavigate();
    const user = useRecoilValue(userState);
    const [state, dispatch, fetch, totalsToRender] = useContextBuyTokens();
    const [taxProfileForm, setTaxProfileForm] = useState<boolean>(false);
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);

    const {
        agreement,
        benefit,
        quantityString,
        selectedCompany,
        stockSafekeepingId
    } = state;

    const { firstSubtotal, discount, deliveryPrice, interest, finalTotal } = totalsToRender;
    const benefitIsApplied = benefit && discount > 0;

    useEffect(() => {
        setDisableRootElementScroll(taxProfileForm);
    }, [taxProfileForm]);

    const handleBuyAgain = () => {
        navigate(`/my-tokens/buy/quantity-select?agreement=${agreement?.id}`);
        fetch();
    }

    return (
        <>
            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12} sx={{ maxWidth: '390px !important' }}>
                    <Stack spacing={2}>
                        <Typography variant="h5" sx={{ paddingX: '15px', marginTop: { xs: '20px', sm: '0px' } }}>
                            {intl.formatMessage({ id: 'buy_tokens.confirmed.title' })}
                        </Typography>
                        <Divider orientation="horizontal" flexItem />
                        <Stack sx={{ paddingX: '15px' }}>
                            <Typography>
                                {`${selectedCompany?.business_social}. ${intl.formatMessage({ id: 'header.tax_id_label' })} ${formatCUIT(selectedCompany?.cuit || '')}`}
                            </Typography>
                            {!!selectedCompany?.invoice_address && (
                                <Typography>
                                    {`${selectedCompany?.invoice_address}.`}
                                </Typography>
                            )}
                        </Stack>
                        {selectedCompany?.validInvoiceProfile === false && (

                            <NuqleaAlert action={() => { setTaxProfileForm(true) }} actionTitle={intl.formatMessage({ id: 'common.complete_action' })}>
                                {intl.formatMessage({ id: 'buy_tokens.confirmed.invalid_invoice_profile' })}
                            </NuqleaAlert>

                        )}
                        <Divider orientation="horizontal" flexItem />
                        <Stack spacing={1} sx={{ paddingX: '15px' }}>
                            <Grid item sx={{ fontWeight: 'bold' }}>
                                {intl.formatMessage({ id: 'buy_tokens.confirmed.your_collection' })}
                            </Grid>
                            <Grid container sx={{ fontSize: '16px' }}>
                                <Grid item xs={6}>
                                    {intl.formatMessage({ id: 'buy_tokens.confirmed.purchased_tokens' })}
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                    {quantityString}
                                </Grid>
                            </Grid>
                        </Stack>
                    </Stack>
                    <Stack spacing={1} sx={{ marginTop: '15px', paddingX: '15px', paddingY: '20px', backgroundColor: '#F7F7F7', borderRadius: '5px' }}>
                        <Typography sx={{ fontWeight: 'bold', color: '#406AFF' }}>
                            {intl.formatMessage({ id: 'buy_tokens.confirmed.purchase_summary' })}
                        </Typography>
                        <Grid container sx={{ fontSize: '16px' }}>
                            <Grid item xs={6} >
                                {'Total tokens'}
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                {formatNumberWithCurrency(firstSubtotal, 2)}
                            </Grid>
                        </Grid>
                        {benefitIsApplied && (
                            <Grid container sx={{ fontSize: '16px', color: '#00B247' }}>
                                <Grid item xs={6}>
                                    {intl.formatMessage({ id: 'buy_tokens.agreement_details.discount' }, {
                                        value:
                                            benefit.value_type === 'percentage' ? `${benefit.value}%` : formatNumberWithCurrency(benefit?.value ?? 0, 2)
                                    })}
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                    {`-${formatNumberWithCurrency(discount, 2)}`}
                                </Grid>
                            </Grid>
                        )}
                        <Grid container sx={{ fontSize: '16px' }}>
                            <Grid item xs={6}>
                                {intl.formatMessage({ id: 'buy_tokens.agreement_details.label_payment_method' })}
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                {formatNumberWithCurrency(interest, 2)}
                            </Grid>
                        </Grid>
                        <Grid container sx={{ fontSize: '16px' }}>
                            <Grid item xs={6}>
                                {intl.formatMessage({ id: 'buy_tokens.agreement_details.label_delivery' })}
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            {deliveryPrice === 0 
                                ? formatNumberWithCurrency(deliveryPrice)
                                : formatNumberWithCurrency(deliveryPrice, 2)
                            }
                            </Grid>
                        </Grid>
                        <Grid container sx={{ marginBottom: '10px !important', fontSize: '16px', fontWeight: 'bold' }}>
                            <Grid item xs={6}>
                                {intl.formatMessage({ id: 'common.total' })}
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                {formatNumberWithCurrency(finalTotal, 2)}
                            </Grid>
                        </Grid>
                        <Typography sx={{ fontSize: 12 }}>{intl.formatMessage({ id: 'buy_tokens.agreement_details.clarifications.subtitle2' })}</Typography>
                        <Divider orientation="horizontal" flexItem />
                        <Grid container sx={{ marginTop: '18px !important', alignItems: 'center' }}>
                            <Grid item xs={6}>
                                <Link
                                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={handleBuyAgain}
                                >
                                    {intl.formatMessage({ id: 'buy_tokens.confirmed.repurchase' })}
                                </Link>
                            </Grid>
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                                <Button
                                    variant='contained'
                                    onClick={() => navigate(`/my-tokens/${stockSafekeepingId}`)}
                                >
                                    {intl.formatMessage({ id: 'buy_tokens.confirmed.view_purchase' })}
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
            <SideModalContainer
                sx={{ width: '430px' }}
                slideDirection={isMobile ? 'up' : 'left'}
                modalPosition={isMobile ? 'center' : 'right'}
                modalContentAlign={isMobile ? 'bottom' : undefined}
                isOpen={taxProfileForm}
                onClose={() => setTaxProfileForm(false)}
            >
                <TaxProfileForm
                    callback={() => setTaxProfileForm(false)}
                    businessNameToEdit={selectedCompany}
                    organizationId={user.id_external}
                    token={user.token}
                    creator={user}
                    isModal={true}
                ></TaxProfileForm>
            </SideModalContainer>
        </>
    )
}

export default ConfirmedStep;