import { useEffect } from 'react';
import client from '../clients/client';
import { useRecoilState, useRecoilValue } from 'recoil';
import agreementsOptionsState from '../atoms/agreements-options-state';
import userState from '../atoms/user-state';
import { Role } from '../types';

const useAgreements = () => {
	const user = useRecoilValue(userState);
	const [agreementsState, setAgreementsState] = useRecoilState(agreementsOptionsState);
	const { agreementsOptions, isLoading } = agreementsState;
    
	useEffect(() => {
		if (user.type_external !== Role.SELLER && user.token && agreementsOptions.length === 0 && isLoading) {
      client.getAgreements({ token: user.token })
				.then((res) => {
					const newAgreementsOptions = res.data
					setAgreementsState({ agreementsOptions: newAgreementsOptions, isLoading: false });
				})
				.catch((err) => {
					console.error({
						message: 'Error fetching agreements',
						payload: err,
					});
					setAgreementsState({ agreementsOptions: [], isLoading: false });
				});
    }
	}, [agreementsOptions, isLoading, setAgreementsState, user]);
};

export default useAgreements;
