import { TextField, TextFieldProps } from '@mui/material';

const FilterTextField = (props: TextFieldProps) => {
	return (
		<TextField
			label={props.label}
			placeholder={props.placeholder}
			variant="outlined"
			InputLabelProps={{
				shrink: true,
			}}
			error={props.error}
			helperText={props.helperText}
			InputProps={props.InputProps}
			type={props.type}
			disabled={props.disabled}
			fullWidth
			sx={{
				'& .MuiOutlinedInput-root': {
					borderRadius: 1,
					maxHeight: 50,
					borderColor: '#D9D9D9',
				},
				'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
					borderColor: '#D9D9D9',
				},
				'& .MuiFormLabel-root': {
					color: 'rgba(0, 0, 0, 0.6)!important',
					background: '#FFF',
					px: 1,
				},
				'& .Mui-focused': {
					color: 'rgba(0, 0, 0, 0.6)',
					background: '#FFF',
				},
				'& .Mui-disabled': {
					textFillColor: '#7E7E82 !important',
				},
				color: 'black',
			}}
			value={props.value}
			onChange={(event) => props.onChange && props.onChange(event)}
		/>
	);
};
export default FilterTextField;
