import { Button, Box } from '@mui/material';
import { IntlShape } from 'react-intl';
import { useState } from 'react';
import { User } from '../../../types';
import SellerDeliveryDialog from '../deliveries/seller-delivery-dialog'
import SellerIsLastDeliveryDialog from '../deliveries/seller-is-last-delivery-dialog';
import { useDeliveryContext } from '../../../contexts/deliveryContext';

interface Props {
	label: string;
	delivery: any;
	user?: User;
}

const SetExpresoButton = (props: Props, intl: IntlShape) => {
	const { label, delivery, user } = props;

	const { deliveriesCount } = useDeliveryContext();
	const [sellerDeliveryDialog, setSellerDeliveryDialogOpen] = useState<boolean>(false);
	const [sellerLastDeliveryDialog, setSellerLastDeliveryDialog] = useState<boolean>(false);

	const handleClick = (e: any) => {
		e.preventDefault()
		e.stopPropagation()
		e.nativeEvent.stopImmediatePropagation()

		if(deliveriesCount === 1) {
			setSellerLastDeliveryDialog(true)
		} else {
			if(!sellerDeliveryDialog) setSellerDeliveryDialogOpen(true)
		}
	}

	const handleOnCloseLastDelivery = (event?:any, reason?:string) => {
		if(reason === 'isLastDelivery') {
			setSellerLastDeliveryDialog(false)
			setSellerDeliveryDialogOpen(true)
		} else {
			setSellerLastDeliveryDialog(false)
		}
	}

	return (
		<Box sx={{ justifyContent: 'center'}}>
			<Button
				onClick={handleClick}
				variant="contained"
				sx={{ background: '#000', color: '#FFF', borderRadius: 10, maxWidth: '132px', fontSize: '.82em', lineHeight: '1.1em', textTransform: 'none' }}
			>
				{label}
			</Button>
			<SellerDeliveryDialog deliveryType="expreso" open={sellerDeliveryDialog} delivery={delivery} user={user} onClose={() => setSellerDeliveryDialogOpen(false)} />
			{
				deliveriesCount === 1 &&
				<SellerIsLastDeliveryDialog open={sellerLastDeliveryDialog} onClose={handleOnCloseLastDelivery} />
			}
		</Box>
	)
};

export default SetExpresoButton;
