import { atom } from "recoil"

export interface Notification {
    id: string;
	message: string;
	metadata?: {} | null;
}

const notificationState = atom({
    key: 'notificationState',
    default: [] as Notification[],
});

export default notificationState;
