import { Container } from '@mui/material';
import { useEffect } from 'react';

const PageContainer = ({ children }: { children: React.ReactNode }) => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<Container
			maxWidth='xl'
			className='flex py-16 px-4 lg:px-16 min-w-[320px]'
		>
			{children}
		</Container>
	);
};

export default PageContainer;
