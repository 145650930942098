import { atom } from "recoil"

export interface construct {
	clasificacion?: string;
	constancia_cuit_file_id?: string;
	cuit?: number;
	domicilio_comercial?: string | null;
	employee_quantity?: number | null;
	id_constructora?: string;
	image_url?: string | null;
	name_constructora?: string;
	nombre_owner?: string;
	owner?: number;
	perfil?: string | null;
	perfil_constructora?: string | null;
	project_type?: string | null;
	razon_social?: string;
	short_description?: string | null;
	social_media?: string | null;
	suscripta?: boolean;
	user_type?: string | null;
}

const organizationState = atom({
    key: 'organizationState',
    default: {} as construct,
});

export default organizationState;
