import { IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import logoPlaceholder from '../../../assets/logoPlaceholder.png';
import { StackedData, User } from '../../../types';
import { construct } from './main-settings';
import { useMobile } from '../../../hooks/use-mobile';
import GenericTabFrame from '../../base-components/generic-tab-frame';
import StackDataDisplay from '../../base-components/stack-data-display';
import EditButton from '../../nuqlea-components/edit-button';

interface generalTabProps {
	callback: (isEditionMode: boolean) => void;
	constructorData: construct;
}

const GeneralTab: React.FC<generalTabProps> = ({ callback, constructorData }) => {
	const intl = useIntl();
	const isMobile = useMobile();

	const data: StackedData[] = [
		{
			objectDesc: (
				<IconButton
					color="primary"
					sx={{
						width: '100px',
						height: '100px',
						border: '1px solid #D9D9D9',
						bgcolor: '#F7F7F7',
						borderRadius: '5px',
						mt: '5px',
						mb: '10px',
					}}
				>
					<img
						src={constructorData.image_url ?? logoPlaceholder}
						alt="Logo"
						style={{ width: '100%', height: '100%', objectFit: 'fill' }}
					/>
				</IconButton>
			),
			title: intl.formatMessage({ id: 'settings.company.general_tab.logo' }),
			description: '',
		},
		{
			title: intl.formatMessage({ id: 'settings.company.general_tab.profile_input' }),
			description: constructorData.user_type,
		},
		{
			title: intl.formatMessage({ id: 'settings.company.general_tab.project_type_input' }),
			description: constructorData.project_type?.replace(',', '\n'),
		},
		{
			title: intl.formatMessage({ id: 'settings.company.general_tab.principal_party_input' }),
			description: constructorData.perfil_constructora,
		},
		{
			title: intl.formatMessage({ id: 'settings.company.general_tab.address_input' }),
			description: constructorData.domicilio_comercial,
		},
		{
			title: intl.formatMessage({ id: 'settings.company.general_tab.collabs_input' }),
			description: constructorData.employee_quantity,
		},
	];

	return (
		<GenericTabFrame
			buttons={[
				<EditButton
					key={'edit-button'}
					text={intl.formatMessage({ id: 'settings.company.general_tab.edit' })}
					onClick={() => callback(true)}
				></EditButton>,
			]}
			title={intl.formatMessage({ id: 'settings.company.general_tab.title' })}
			subtitle={intl.formatMessage({ id: 'settings.company.general_tab.subtitle' })}
		>
			<StackDataDisplay minHeight={'31rem'} data={data}></StackDataDisplay>
		</GenericTabFrame>
	);
};

export default GeneralTab;
