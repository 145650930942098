import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
type EquivalenceDialogProps = {
	open: boolean;
	onClose: () => void;
	onClick: () => void;
};

const CollaboratorInvitationConfirm = ({ open, onClose, onClick }: EquivalenceDialogProps) => {
	const intl = useIntl();

	return (
		<Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="sm">
			<DialogContent sx={{ padding: '0 2rem', fontSize: '16px' }}>
				<Box sx={{ flex: 1 }}>
					<Typography variant="h5" textAlign="center">
						{intl.formatMessage({ id: 'collaborators.invite_confirmation_dialog_title' })}
					</Typography>
					<Typography variant="body1" sx={{ margin: '1rem 0', padding: 2, color: 'black' }}>
						{intl.formatMessage({ id: 'collaborators.invite_confirmation_dialog_content' })}
					</Typography>
				</Box>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center' }}>
				<Box sx={{ display: 'flex', gap: 2 }}>
					<Button
						onClick={() => {onClick(); onClose();}}
						sx={{
							bgcolor: 'white',
							borderRadius: '5px',
							color: 'white',
							background: '#3445F2',
							fontWeight: 500,
							width: '160px',
							justifyContent: 'center',
							margin: 'auto',
							cursor: 'pointer',
							'&:hover': {
								backgroundColor: '#3445F2',
							},
						}}
					>
						{intl.formatMessage({ id: 'common.finish' })}
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default CollaboratorInvitationConfirm;
