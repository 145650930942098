
const Plan = () => {
    const defaultSrc = "https://nuqlea.com/planificacion-form";
    return(
            <iframe
                id='plan'
                src={defaultSrc}
                title="Planificación"
                style={{borderStyle: 'none', width: '100%', height: `95vh`, marginTop: '64px', overflow: 'hidden', marginBottom: '-25px'}}
                scrolling="yes"
                allow="fullscreen *; geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb; xr-spatial-tracking;"
                allowFullScreen
                allowTransparency
                frameBorder="0"
            >
            </iframe>
    )
}

export default Plan;

