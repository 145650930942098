import { Box, Typography, Link } from '@mui/material';
import logo from '../assets/logo-black.svg';
import { useIntl } from 'react-intl';

const Maintenance = () => {
	const intl = useIntl();

    return(
            <Box sx={{backgroundColor: '#efefef', height: '100vh'}}>
                <Box sx={{ py: '50px', px: '85px'}}>
                <Box sx={{padding: '48px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <img src={logo} style={{width: '100%', maxWidth: '175px'}} />
                    <Typography sx={{textAlign: 'center', marginTop: '24px', marginBottom: '8px', fontSize: '26px'}}>
                        {intl.formatMessage({ id: 'maintenance.title' })}
                    </Typography>
                    <Typography sx={{textAlign: 'center', fontSize: '16px'}}>
                        {intl.formatMessage({ id: 'maintenance.first_sentence' })}
                    </Typography>
                    <Typography sx={{textAlign: 'center', fontSize: '16px', marginBottom: '16px'}}>
                        {intl.formatMessage({ id: 'maintenance.second_sentence' })}                
                    </Typography>
                    <Link sx={{textAlign: 'center', fontSize: '12px'}} href="https://nuqlea.com.ar" color="inherit">
                        {intl.formatMessage({ id: 'maintenance.redirection' })}
                    </Link>
                </Box>
                </Box>
                
            </Box>
    )
}

export default Maintenance;

