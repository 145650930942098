import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import {
	InputLabel,
	MenuItem,
	Typography,
	IconButton,
	styled,
	Alert,
	Autocomplete,
	Chip,
	Checkbox,
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
	FileType,
	DeliveryCompleteness,
	DeliveryType,
	Delivery,
	Document,
	ListDetails,
	SellerListDetails,
	User,
} from '../../../types';
import FileUpload from 'react-material-file-upload';
import FullScreenLoading from '../../full-screen-loading';
import client from '../../../clients/client';
import { useDeliveryContext } from '../../../contexts/deliveryContext';

const StyledAlert = styled(Alert)(({ theme }) => ({
	border: 'none',
	backgroundColor: 'transparent',
	borderRadius: '10px',
	color: '#0288d1',
}));

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
		top: 12,
	},
}));

const SeparatedLabel = styled(InputLabel)`
	margin-top: 15px;
	margin-bottom: 5px;
`;

const FileUploadWrapper = styled('div')(({ theme }) => ({
	'& > *': {
		border: `2px dashed  ${theme.palette.divider} !important`,
		backgroundColor: theme.palette.background.default,
	},
	'& .MuiFormControl-root': {
		flexDirection: 'row',
		alignItems: 'center',
		gap: 8,
		'& .MuiTypography-root': {
			fontSize: '1em',
			paddingTop: 20,
			paddingBottom: 20,
		},
		'& .MuiSvgIcon-root': {
			fill: '#0d6efd',
			fontSize: 30,
			marginBottom: 4,
			marginLeft: 30,
		},
		'& .MuiButtonBase-root': {
			position: 'absolute',
			background: 'transparent',
			color: 'transparent',
			width: '100%',
			height: '10vh',
			marginBottom: -8,
			marginLeft: 0,
		},
	},
}));

interface Props {
	open: boolean;
	delivery: Delivery;
	deliveryType: string;
	// dataConstructor?: ListDetails;
	// dataSeller?: SellerListDetails;
	user?: User;
	onClose: (event?: object | null, reason?: string, newFile?: any) => void;
}

const SellerDeliveryDialog = (props: Props) => {
	const intl = useIntl();
	const { open, delivery, deliveryType, user, onClose } = props;

	const { baseInfo, setMessage } = useDeliveryContext();

	const [type, setType] = useState<number>(13);
	const [fileNumber, setFileNumber] = useState<string>();
	const [files, setFiles] = useState<File[]>([]);
	const [loading, setLoading] = useState<boolean>();
	const [error, setError] = useState<string>('');
	const [fileLabel, setFileLabel] = useState<string>('Remito');
	const [availability, setAvailability] = useState<DeliveryCompleteness>();
	const [comments, setComments] = useState<string>();
	const [expresoName, setExpresoName] = useState<string | null>(delivery.express_name);
	const [expresoCode, setExpresoCode] = useState<string>();

	const availabilities = [
		{
			id: DeliveryCompleteness.COMPLETE,
			label: 'list.seller.details.deliveries.delivery_completeness.total',
		},
		{
			id: DeliveryCompleteness.PARTIAL,
			label: 'list.seller.details.deliveries.delivery_completeness.partial',
		},
	];

	const handleSubmit = () => {
		setLoading(true);
		setError('');

		let all_promises = [];

		const file = files[0];
		if (file) {
			all_promises.push(
				new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve((reader.result as string).split(',').pop());
					reader.onerror = (error) => reject(error);
				}).then(async (data) => {
					await client.uploadDriveFile({
						fileName: file.name,
						fileType: file.type,
						fileData: data,
						type: fileLabel,
						user,
						relatedFiles: [delivery.id],
						listId: baseInfo?.metadata?.list_id,
						listMigrationId: baseInfo?.metadata?.list_migration_id,
						orderMigrationId: baseInfo?.quotation?.order_migration_id,
						listEnvironment: baseInfo?.metadata?.env_list,
						filesFolderId: baseInfo?.metadata?.files_folder_id,
						documentId:
							fileNumber === undefined || fileNumber === '' ? String(delivery.children.length + 1) : fileNumber,
						deliveryId: delivery.id,
					});
				})
			);
		}

		if (deliveryType !== DeliveryType.REMITO) {
			all_promises.push(
				client.updateDelivery({
					deliveryId: delivery.id,
					fields: {
						completeness: availability,
						tracking_code: expresoCode,
						express_name: expresoName,
						comment: comments,
					},
					user,
				})
			);
		}

		Promise.all(all_promises)
			.then((response) => {
				setMessage({
					delivery,
					delivery_file: file
						? {
								delivery_id: delivery.id,
								document_id:
									fileNumber === undefined || fileNumber === '' ? String(delivery.children.length + 1) : fileNumber,
								document_file_id: deliveryType !== DeliveryType.REMITO ? response[1]?.file_id : response[0]?.file_id,
								document_date:
									deliveryType !== DeliveryType.REMITO ? response[1]?.date_loaded : response[0]?.date_loaded,
						  }
						: null,
					updated_fields: {
						completeness: availability,
						tracking_code: expresoCode,
						express_name: expresoName,
						comment: comments,
						status:
							deliveryType === DeliveryType.DELIVERED || deliveryType === DeliveryType.TAKEAWAY
								? 2
								: deliveryType === DeliveryType.EXPRESO
								? 3
								: delivery.status,
					},
				});

				onClose(null, 'success');

				// Reset form
				setFiles([]);
				setType(13);
				setFileNumber(undefined);
				setFileLabel('Remito');
			})
			.catch((error) => setError(error.message))
			.finally(() => setLoading(false));
	};

	const handleFileLoad = (files: File[]) => {
		setError('');

		if (files.length) {
			const FILES_MAX_SIZE = 4;

			if (files[0].size / 1024 / 1024 >= FILES_MAX_SIZE) {
				setError(`El archivo es demasiado grande y supera el máximo de 4MB`);
				return;
			}

			if (files[0].type !== 'application/pdf' && !files[0].type.startsWith('image/')) {
				setError('Solamente se aceptan los tipos de archivos PDF o imágen');
				return;
			}
		}

		setFiles(files);
	};

	const getTitle = (type: string) => {
		if (type === DeliveryType.DELIVERED) return 'list.seller.details.deliveries.action_button_label.set_delivered';
		else if (type === DeliveryType.TAKEAWAY) return 'list.seller.details.deliveries.action_button_label.set_retired';
		else if (type === DeliveryType.EXPRESO) return 'list.seller.details.deliveries.action_button_label.set_expreso';
		else if (type === DeliveryType.REMITO) return 'list.seller.details.deliveries.action_button_label.load_remito';
		else return 'Entregas';
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
			sx={{ '& .MuiPaper-root': { maxWidth: '500px' } }}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
		>
			{loading && <FullScreenLoading />}
			<DialogTitle>
				<Typography variant="h5">{intl.formatMessage({ id: getTitle(deliveryType) })}</Typography>
			</DialogTitle>
			<DialogContent>
				{deliveryType !== DeliveryType.REMITO && (
					<>
						<SeparatedLabel sx={{ fontWeight: 900 }}>
							{intl.formatMessage({
								id: 'list.seller.details.deliveries.delivery_dialog.delivered.delivery_completeness_field',
							})}
							<BootstrapTooltip
								placement="top"
								title={intl.formatMessage({
									id: 'list.seller.details.deliveries.delivery_dialog.delivered.delivery_completeness_field_tooltip',
								})}
								arrow
							>
								<IconButton disableRipple color="inherit" sx={{ mb: 0.5, cursor: 'default' }}>
									<HelpIcon />
								</IconButton>
							</BootstrapTooltip>
						</SeparatedLabel>
						<TextField
							select
							fullWidth
							required
							size="small"
							value={availability}
							onChange={(event) => setAvailability(event.target.value as DeliveryCompleteness)}
							sx={{ textTransform: 'capitalize' }}
						>
							{availabilities.map((availability) => (
								<MenuItem key={availability.id} value={availability.id} sx={{ textTransform: 'capitalize' }}>
									{intl.formatMessage({ id: availability.label })}
								</MenuItem>
							))}
						</TextField>
					</>
				)}

				{deliveryType === DeliveryType.EXPRESO && (
					<>
						<SeparatedLabel sx={{ fontWeight: 900 }}>
							{intl.formatMessage({ id: 'list.seller.details.deliveries.delivery_dialog.expreso.expreso_name_field' })}
						</SeparatedLabel>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={expresoName}
							onChange={(event) => setExpresoName(event.target.value)}
						/>

						<SeparatedLabel sx={{ fontWeight: 900 }}>
							{intl.formatMessage({
								id: 'list.seller.details.deliveries.delivery_dialog.expreso.expreso_tracking_code',
							})}
						</SeparatedLabel>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={expresoCode}
							onChange={(event) => setExpresoCode(event.target.value)}
						/>
					</>
				)}
				{deliveryType !== DeliveryType.REMITO && (
					<>
						<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
							{intl.formatMessage({ id: 'list.seller.details.quotation_dialog.comments_field_name' })}
						</SeparatedLabel>
						{availability === DeliveryCompleteness.PARTIAL && (
							<StyledAlert
								severity="info"
								sx={{ p: 0, mt: -2, ml: -1.2, '& .MuiAlert-icon': { fontSize: '1.4em', marginRight: '5px' } }}
							>
								{intl.formatMessage({ id: 'list.seller.details.deliveries.delivery_dialog.delivered.comments_hint' })}
							</StyledAlert>
						)}
						<TextField
							fullWidth
							multiline
							size="small"
							rows={2}
							placeholder={intl.formatMessage({
								id: 'list.seller.details.quotation_dialog.comments_field_placeholder',
							})}
							value={comments}
							onChange={(event) => setComments(event.target.value)}
							required={availability === DeliveryCompleteness.PARTIAL}
						/>
					</>
				)}

				<SeparatedLabel sx={{ fontWeight: 900 }}>
					{intl.formatMessage({ id: 'list.details.file_upload_dialog.file_number_field_name' }) +
						' ' +
						fileLabel.toLowerCase()}
				</SeparatedLabel>
				<TextField
					fullWidth
					variant="outlined"
					size="small"
					value={fileNumber}
					onChange={(event) => setFileNumber(event.target.value)}
				/>

				<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
					{intl.formatMessage({ id: 'list.seller.details.deliveries.delivery_dialog.delivered.file_field' })}
					{deliveryType === DeliveryType.REMITO ? '*' : ''}
				</SeparatedLabel>
				{deliveryType !== DeliveryType.REMITO && (
					<StyledAlert
						severity="info"
						sx={{ p: 0, mt: -2, ml: -1.2, '& .MuiAlert-icon': { fontSize: '1.4em', marginRight: '5px' } }}
					>
						{intl.formatMessage({ id: 'list.seller.details.deliveries.delivery_dialog.delivered.file_field_hint' })}
					</StyledAlert>
				)}
				<FileUploadWrapper>
					<FileUpload
						value={files}
						onChange={(files: File[]) => {
							handleFileLoad(files);
						}}
						multiple={false}
						buttonText={intl.formatMessage({ id: 'common.upload' })}
						title={intl.formatMessage({
							id: 'list.seller.details.deliveries.delivery_dialog.delivered.file_field_placeholder',
						})}
						sx={{
							borderRadius: 4,
							'& .MuiFormControl-root': {
								display: files && files.length ? 'none' : 'flex',
							},
						}}
					/>
				</FileUploadWrapper>
				<small style={{ fontSize: '.74em' }}>
					{intl.formatMessage({
						id: 'list.seller.details.deliveries.delivery_dialog.delivered.file_field_restrictions',
					})}
				</small>
				{error && (
					<Alert severity="error" sx={{ mt: 2 }}>
						{error ?? 'Ocurrió un error.'}
					</Alert>
				)}
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', gap: 1 }}>
				<Button
					onClick={() => onClose(null, 'cancelButtonClick')}
					variant="contained"
					sx={{ border: '1px solid #000', borderRadius: 10, width: '120px' }}
				>
					{intl.formatMessage({ id: 'common.cancel' })}
				</Button>
				<Button
					onClick={handleSubmit}
					variant="contained"
					disabled={
						(deliveryType !== DeliveryType.REMITO && !availability) ||
						(deliveryType === DeliveryType.REMITO && files.length === 0) ||
						(deliveryType === DeliveryType.EXPRESO && !expresoName) ||
						(availability === DeliveryCompleteness.PARTIAL && (!comments || comments === '')) ||
						(fileNumber !== undefined && fileNumber !== '' && files.length === 0)
					}
					sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius: 10, width: '120px' }}
				>
					{intl.formatMessage({ id: 'common.confirm' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SellerDeliveryDialog;
