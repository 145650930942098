import { KnockProvider, KnockFeedProvider, NotificationFeed, KnockI18nProvider } from '@knocklabs/react';
import '@knocklabs/react/dist/index.css';
import { Paper } from '@mui/material';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import userState from '../../atoms/user-state';
import BasePageFrame from '../base-components/base-page-frame';

const NotificationsInbox = () => {
	const user = useRecoilValue(userState);
	const intl = useIntl();

	return (
		<KnockProvider
			apiKey={process.env.KNOCK_PUBLIC_API_KEY || 'pk_test_6POGaa25pb3HQ5OFcybJQSNEiQEZR7ED2EDKFWwMy2Y'}
			userId={String(user?.id)}
			userToken={user?.knock_token}
		>
			<KnockFeedProvider feedId={process.env.KNOCK_FEED_ID || '71e66f64-5c82-4e65-9fda-39f52709a1d2'}>
				<KnockI18nProvider
					i18n={{
						translations: {
							emptyFeedTitle: 'Aún no tenés notificaciones',
							emptyFeedBody: 'Te vamos a avisar acá cuando haya algo que debas saber.',
							notifications: 'Notificaciones',
							poweredBy: 'Nuqlea',
							markAllAsRead: 'Marcar todo como leído',
							archiveNotification: '',
							all: 'Todas',
							unread: 'No leídas',
							read: 'Leídas',
							unseen: 'No vistas',
						},
						locale: 'es',
					}}
				>
					<BasePageFrame title={intl.formatMessage({ id: 'notifications.inbox.title' })}>
						<Paper
							elevation={0}
							sx={{
								width: '100%',
								borderRadius: 2,
								'& .rnf-empty-feed': {
									minHeight: '9rem',
								},
								'& .rnf-notification-feed__knock-branding': {
									display: 'none',
								},
								'& .rnf-notification-icon-button': {
									width: 'auto',
									height: 'auto',
								},
								'& .rnf-notification-icon-button svg': {
									width: '1.4rem',
									height: '1.4rem',
								},
							}}
						>
							<NotificationFeed />
						</Paper>
					</BasePageFrame>
				</KnockI18nProvider>
			</KnockFeedProvider>
		</KnockProvider>
	);
};

export default NotificationsInbox;
