import { Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography, debounce } from '@mui/material';
import { useIntl } from 'react-intl';
import InputContainer from '../my-tokens/dashboard/InputContainer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Collaborator, User } from '../../types';
import client from '../../clients/client';
import { LoadingButton } from '@mui/lab';
import { blue } from '@mui/material/colors';

type EquivalenceDialogProps = {
	collaborators: Collaborator[];
	open: boolean;
	onClose: () => void;
	openConfirmationDialog: () => void;
	user: User;
};

const NewCollaboratorDialog = ({
	collaborators,
	open,
	onClose,
	user,
	openConfirmationDialog,
}: EquivalenceDialogProps) => {
	const intl = useIntl();
	const [mail, setMail] = useState<string | null>(null);
	const [error, setError] = useState<string | null>();
	const [loading, setLoading] = useState(false);
	const [validMail, setValidMail] = useState<boolean>(true);

	const handleIsValidMail = useCallback(() => {
		const reg = new RegExp(
			/^([a-z0-9_\-\.])+\@(?!(?:[a-z0-9_\-\.]+\.)?([a-z]{2,4})\.\2)([a-z0-9_\-\.])+\.([a-z]{2,4})$/
		);
		const flag = mail ? reg.test(mail) : false;

		setValidMail(flag);
		return flag;
	}, [mail]);

	useEffect(() => {
		setError(null);
		setValidMail(true);
	}, [mail]);

	const handleClose = () => {
		setMail(null);
		setError(null);
		setValidMail(true);
		onClose();
	};

	const handleInvitation = debounce(() => {
		const body = {
			id_external: user.id_constructora,
			type_external: 'constructor',
			cuit: user.cuit_external,
			business_name_id: user.name_external,
			user_email: mail,
		};
		client
			.inviteCollaborator({ token: user.token, fields: body })
			.then((response: any) => {
				if (!response.exists) {
					onClose();
					openConfirmationDialog();
				} else {
					setError(response.message);
				}
			})
			.finally(() => setLoading(false));
	}, 2000);

	const onSubmit = async () => {
		if (!handleIsValidMail()) {
			return;
		}
		setError(null);
		setLoading(true);
		if (collaborators.some((item) => item.user_email === mail)) {
			setError(intl.formatMessage({ id: 'collaborators.invite_dialog_mail_error' }));
			setLoading(false);
			return;
		}
		handleInvitation();
	};
	return (
		<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="sm">
			<DialogContent sx={{ padding: '0 2rem', fontSize: '1rem' }}>
				<Box sx={{ flex: 1 }}>
					<Typography variant="h4" textAlign="center">
						{intl.formatMessage({ id: 'collaborators.invite_dialog_title' })}
					</Typography>
					<Typography
						variant="body1"
						sx={{ margin: '1rem 0', padding: 2, background: blue[50], borderRadius: '0.5rem' }}
					>
						{intl.formatMessage({ id: 'collaborators.invite_dialog_legend' })}
					</Typography>
					<InputContainer title={`${intl.formatMessage({ id: 'collaborators.invite_dialog_mail' })}*`}>
						<TextField
							value={mail || ''}
							error={!!mail && !validMail}
							placeholder={intl.formatMessage({ id: 'collaborators.invite_dialog_mail_placeholder' })}
							onChange={(e) => setMail(e.target.value.trim().toLowerCase())}
							onBlur={handleIsValidMail}
							helperText={
								mail && !validMail && intl.formatMessage({ id: 'collaborators.invite_dialog_mail_helper_text' })
							}
						/>
					</InputContainer>
				</Box>
				{Boolean(error) && (
					<Typography variant="body1" sx={{ fontSize: '12px', color: 'red', padding: '4px 0 0 4px' }}>
						{error}
					</Typography>
				)}
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', pt: '1.5rem' }}>
				<Box sx={{ display: 'flex', gap: 2 }}>
					<Button
						onClick={handleClose}
						variant="outlined"
						sx={{
							width: '10rem',
						}}
					>
						{intl.formatMessage({ id: 'common.cancel' })}
					</Button>
					<LoadingButton
						loading={loading}
						variant="contained"
						onClick={onSubmit}
						sx={{
							width: '10rem',
						}}
						disabled={!validMail}
					>
						{intl.formatMessage({ id: 'common.confirm' })}
					</LoadingButton>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default NewCollaboratorDialog;
