import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { Typography, Alert } from '@mui/material';

interface Props {
	open: boolean;
	onClose: (event?: object | null, reason?: string, newFile?: any) => void;
}

const SellerIsLastDeliveryDialog = (props: Props) => {
	const intl = useIntl();
	const { open, onClose } = props;

	const handleSubmit = () => {
		onClose(null, 'isLastDelivery')
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
			sx={{ '& .MuiPaper-root': { maxWidth: '500px', p: 3, borderRadius: 4 } }}
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
			}}
		>
			<DialogTitle sx={{ textAlign: 'center' }}>	
				<Typography variant="h5">{intl.formatMessage({ id: 'list.seller.details.deliveries.is_last_delivery.title' })}</Typography>
			</DialogTitle>
			<DialogContent>
				<Alert severity="info" sx={{ mt: 2 }}>
					{intl.formatMessage({ id: 'list.seller.details.deliveries.is_last_delivery.content' })}
				</Alert>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', gap: 1 }}>
				<Button
					onClick={() => onClose(null, 'cancelButtonClick')}
					variant="contained"
					sx={{ border: '1px solid #000', borderRadius: 10, width: '160px', textTransform: 'initial' }}
				>
					{intl.formatMessage({ id: 'list.seller.details.deliveries.is_last_delivery.button_no' })}
				</Button>
				<Button
					onClick={handleSubmit}
					variant="contained"
					sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius: 10, width: '160px', textTransform: 'initial' }}
				>
					{intl.formatMessage({ id: 'list.seller.details.deliveries.is_last_delivery.button_yes' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SellerIsLastDeliveryDialog;
