import { FileUploadRequest } from "../../../../clients/businessNameClient";
import { DocumentationTypes } from "../../../../types";

export const createFile = (type: number, format: string, name: string, originalName: string) => ({ type, format, name, originalName });

export const getFileName = (type: number, suffix?: string): string => {
    const documentationType = Object.keys(DocumentationTypes).find(
        (key) => DocumentationTypes[key as keyof typeof DocumentationTypes] === type
    );

    return `${documentationType}${suffix ? `_${suffix}` : ""}`;
};

export const appendFile = (files: FileUploadRequest[], certificate: File[], type: number, suffix?: string) => {
    if (certificate.length && certificate[0]) {
        files.push(createFile(type, certificate[0].type, getFileName(type, suffix), certificate[0].name));
    }
};