import UploadFileIcon from '@mui/icons-material/UploadFile';
import type { ButtonProps, TypographyProps } from '@mui/material';
import {
	Box,
	Button,
	Chip,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	SvgIcon,
	Typography,
	styled,
} from '@mui/material';
import type { SxProps, Theme } from '@mui/system';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { blue } from '@mui/material/colors';

export interface FileUploadProps extends Omit<DropzoneOptions, 'onDrop' | 'onDropAccepted'> {
	sx?: SxProps<Theme>;
	typographyProps?: TypographyProps;
	buttonProps?: Omit<ButtonProps, 'onClick'>;
	title?: string;
	buttonText?: string;
	specText?: string;
	acceptedFormats?: string[];
	value: File[];
	onChange?: (files: File[]) => void;
	onRemove?: (filename: string) => void;
	showRequired?: boolean;
}

interface FileListItemProps {
	name: string;
	onDelete: () => void;
}

const ListItem = styled('li')(({ theme }) => ({
	margin: theme.spacing(0.5),
}));

const FileListItem = ({ name, onDelete }: FileListItemProps) => (
	<ListItem>
		<Chip label={name} icon={<UploadFileIcon />} variant="outlined" sx={{ maxWidth: 200 }} onDelete={onDelete} />
	</ListItem>
);

const NuqleaFileUpload = ({
	value,
	onChange,
	onRemove,
	sx,
	title,
	buttonText,
	specText,
	acceptedFormats = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'],
	typographyProps,
	buttonProps,
	disabled,
	maxSize = (4 * 1024 * 1024), // 4MB
	showRequired,
	...options
}: FileUploadProps) => {
	const { fileRejections, getRootProps, getInputProps, open } = useDropzone({
		accept: acceptedFormats,
		...options,
		disabled,
		maxSize,
		onDropAccepted: onChange,
		noClick: true,
		noKeyboard: true,
	});
	const [showRequiredMessage, setShowRequiredMessage] = useState(false);
	const intl = useIntl();
	const isFileTooLarge = maxSize !== undefined && fileRejections.length > 0 && fileRejections[0].file.size > maxSize;
	let files = value?.map((file, i) => (
		<FileListItem key={file.name} name={file.name} onDelete={() => remove(i, file.name)} />
	));
	const primaryColor = useTheme().palette.primary.main;

	useEffect(() => {
		if (showRequired !== undefined && showRequiredMessage !== showRequired) {
			setShowRequiredMessage(showRequired);
		}
	}, [showRequired, showRequiredMessage]);

	const remove = (index: number, filename: string) => {
		const files = [...value];
		files.splice(index, 1);
		onChange && onChange(files);
		onRemove && onRemove(filename);
	};

	const validate = (): string => {
		if (fileRejections[0]?.errors[0]?.code === 'file-invalid-type') {
			return intl.formatMessage({ id: 'misc.file_not_supported_type' });
		} else if (fileRejections[0]?.errors[0]?.code === 'file-too-large') {
			return intl.formatMessage({ id: 'misc.file_exceeded_file_size' });
		} else if (showRequired) {
			return 'Debe seleccionar un archivo';
		}
		return 'No se ha podido cargar el archivo';
	};

	return files.length > 0 ? (
		<OutlinedInput
			size="small"
			fullWidth
			readOnly
			startAdornment={
				<InputAdornment sx={{ '& :hover': { cursor: 'default' } }} position="start">
					<InsertDriveFileIcon color="primary" />
				</InputAdornment>
			}
			value={value[0].name}
			endAdornment={
				<InputAdornment position="end">
					<Box display="flex" alignItems="end">
						<IconButton onClick={() => remove(0, value[0].name)}>
							<CloseIcon color="action" />
						</IconButton>
					</Box>
				</InputAdornment>
			}
			sx={{ bgcolor: blue[50], border: `1px solid ${primaryColor}`, pr: 0 }}
		></OutlinedInput>
	) : (
		<Box
			{...getRootProps()}
			sx={{
				border: 'dashed',
				bgcolor: '#F9F9F9',
				borderRadius: 1,
				borderColor: showRequiredMessage ? 'error.main' : '#CECECE',
				paddingY: 3,
				paddingX: 1,
				'&:hover': {
					borderColor: disabled || showRequired ? undefined : '#b5b5b5',
				},
				'&:focus-within': {
					borderColor: 'primary.main',
					borderWidth: 2,
				},
				...sx,
			}}
		>
			<FormControl
				error={isFileTooLarge}
				sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
			>
				<input {...getInputProps()} />
				<Box
					height={'60px'}
					width={'60px'}
					display={'flex'}
					justifyContent={'center'}
					alignItems={'center'}
					bgcolor={'white'}
					borderRadius={'50%'}
				>
					<SvgIcon sx={{ width: '60%', height: '60%' }} color={disabled ? 'disabled' : 'primary'}>
						<svg width="30" height="30" viewBox="-2 -2 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M18.2797 15.8559L13.8378 11.4141L9.39594 15.8559"
								stroke="#406AFF"
								strokeWidth="2.22095"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M13.8374 11.4141V21.4083"
								stroke="#406AFF"
								strokeWidth="2.22095"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M23.1547 18.5103C24.2378 17.9198 25.0934 16.9855 25.5865 15.8547C26.0796 14.724 26.1821 13.4612 25.8778 12.2658C25.5735 11.0703 24.8798 10.0102 23.9061 9.25278C22.9324 8.49536 21.7342 8.08377 20.5006 8.08295H19.1014C18.7653 6.78286 18.1388 5.57588 17.2691 4.55276C16.3994 3.52963 15.309 2.71699 14.08 2.17592C12.851 1.63485 11.5153 1.37943 10.1734 1.42888C8.83145 1.47832 7.51819 1.83133 6.33233 2.46137C5.14646 3.09142 4.11886 3.9821 3.32677 5.06645C2.53469 6.1508 1.99873 7.40061 1.75919 8.72191C1.51965 10.0432 1.58276 11.4016 1.94378 12.695C2.30481 13.9884 2.95434 15.1832 3.84356 16.1894"
								stroke="#406AFF"
								strokeWidth="2.22095"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M18.2797 15.8559L13.8378 11.4141L9.39594 15.8559"
								stroke="#406AFF"
								strokeWidth="2.22095"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</SvgIcon>
				</Box>

				<Box ml={'20px'}>
					<Box display={'flex'}>
						<Grid container direction={'column'} columns={{ xs: 1 }} alignItems={'center'}>
							<Grid item sm={12}>
								<Typography
									color={'black'}
									fontWeight={500}
									textAlign="center"
									sx={{ paddingY: 0 }}
									{...typographyProps}
								>
									{title ?? intl.formatMessage({ id: 'misc.file_uploader_text' })}
								</Typography>
							</Grid>
							<Grid item sm={12}>
								<Button
									variant="text"
									onClick={open}
									disabled={disabled}
									sx={{
										textDecoration: 'underline',
										textTransform: 'none',
										'&:hover': { backgroundColor: 'inherit', textDecoration: 'underline' },
										paddingTop: 0,
									}}
									{...buttonProps}
								>
									{buttonText ?? intl.formatMessage({ id: 'misc.file_uploader_button' })}
								</Button>
							</Grid>
						</Grid>
					</Box>

					<Typography fontSize={'12px'}>
						{specText ?? intl.formatMessage({ id: 'misc.file_uploader_limitations' })}
					</Typography>
				</Box>
			</FormControl>
			<Box
				component="ul"
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexWrap: 'wrap',
					listStyle: 'none',
					p: 0.5,
					m: 0,
				}}
			>
				{files}
			</Box>
			{(fileRejections[0] || showRequiredMessage) && (
				<FormHelperText sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red' }}>
					{validate()}
				</FormHelperText>
			)}
		</Box>
	);
};

export default NuqleaFileUpload;
