import { ThemeOptions, createTheme, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xs: true;  // Mantener los existentes
		sm: true;
		md: true;
		lg: true;
		xl: true;
		xxl: true;  // Agregar un breakpoint personalizado
	}
}

const breakpoints = {
	xs: 0,
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	xxl: 1440,
};

const rootElement = document.getElementById('root');
const themeOptions: ThemeOptions = {
	breakpoints: {
		values: breakpoints,
	},
	palette: {
		mode: 'light',
		background: {
			default: '#F8F9FA',
		},
		primary: {
			main: '#406AFF',
		},
		secondary: {
			main: '#622BFF',
		},
		error: {
			main: '#FF3D00',
		},
		text: {
			primary: '#2F2F3C',
			secondary: '#72727E',
		},
		grey: {
			'100': '#FFFFFF',
			'200': '#F9F9F9',
			'300': '#F0F0F2',
			'400': '#E0E0E4',
			'500': '#B1B1B8',
			'600': '#4B4B4B',
		},
		divider: '#bbb',
	},
	typography: {
		fontFamily: 'Inter',

		allVariants: {
			color: 'black',
		},
		h1: {
			fontSize: '2.25rem',
			fontWeight: 700
		},
		h2: {
			fontSize: '2rem',
			fontWeight: 700,
		},
		h3: {
			fontSize: '1.75rem',
			fontWeight: 600,
			lineHeight: 1.31,
			[`@media (max-width: ${breakpoints.xl}px)`]: {
				fontSize: '1.56rem !important',
			},
		},
		h4: {
			fontSize: '1.25rem',
			fontWeight: 500,
		},
		h5: {
			fontSize: '1.125rem',
			fontWeight: 500,
			color: '#72727e',
			[`@media (max-width: ${breakpoints.xl}px)`]: {
				fontSize: '1rem',
			},
		},
		h6: {
			fontSize: '1rem',
			fontWeight: 500,
			lineHeight: 1.4,
			[`@media (max-width: ${breakpoints.xl}px)`]: {
				fontSize: '0.875rem',
			},
		},
		body1: {
			fontSize: '1rem',
			fontWeight: 400,
			color: 'black',
			[`@media (max-width: ${breakpoints.xl}px)`]: {
				fontSize: '0.875rem',
			},
		},
		body2: {
			fontSize: '0.875rem',
			fontWeight: 400,
			color: '#72727E',
			[`@media (max-width: ${breakpoints.xl}px)`]: {
				fontSize: '0.75rem',
			},
		},
		subtitle1: {
			fontSize: '1rem',
			fontWeight: 500,
			lineHeight: 1.75,
			[`@media (max-width: ${breakpoints.xl}px)`]: {
			  fontSize: '0.875rem',
			},
		  },
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: ``,
		},
		MuiStepIcon: {
			styleOverrides: {
				root: {
					'&.Mui-completed': {
						color: '#000',
					},
					'&.Mui-active': {
						color: 'blue',
					},
					color: '#fff',
				},
				text: {
					fill: '#fff',
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: 'blue',
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontSize: '0.875rem',
					fontWeight: 400,
					[`@media (max-width: ${breakpoints.xl}px)`]: {
						fontSize: '0.75rem',
					},
				},
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: '0.875rem',
					fontWeight: 400,
					[`@media (max-width: ${breakpoints.xl}px)`]: {
						fontSize: '0.75rem',
					},
				},
			}
		},
		MuiFormGroup: {
			styleOverrides: {
				root: {
					fontSize: '0.875rem',
					fontWeight: 400,
					[`@media (max-width: ${breakpoints.xl}px)`]: {
						fontSize: '0.75rem',
					},
				},
			}
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					fontSize: '0.875rem',
					fontWeight: 400,
					[`@media (max-width: ${breakpoints.xl}px)`]: {
						fontSize: '0.75rem',
					},
				},
			}
		}, 
		MuiInputBase: {
			styleOverrides: {
				root: {
					variants: [{
						props: {
							size: 'small',
						},
						style: {
							fontSize: '0.875rem',
							fontWeight: 400,
							[`@media (max-width: ${breakpoints.xl}px)`]: {
								fontSize: '0.75rem',
							},
						},
					}]
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					fontSize: '1rem',
					[`@media (max-width: ${breakpoints.xl}px)`]: {
						fontSize: '0.875rem',
					},
				},
			},
		},

		MuiTableCell: {
			styleOverrides: {
				root: {
					borderCollapse: 'separate',
					padding: '10px',
					borderBottom: '1px solid rgba(221, 221, 221, 1)',
					backgroundColor: '#fff',
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					fontWeight: '300',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&.Mui-error .MuiOutlinedInput-notchedOutline': {
						border: '2px solid #FF3D00', // Borde de error
					},
					'&.Mui-error .Mui-focused .MuiOutlinedInput-notchedOutline': {
						border: '2px solid #FF3D00', // Borde de error cuando está enfocado
					},
					'&:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
						borderColor: 'rgba(0, 0, 0, 0.23)', // Borde por defecto en estado hover
					},
					'&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
						borderColor: 'rgba(0, 0, 0, 0.23)', // Borde cuando está enfocado pero no en estado de error
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					boxShadow: 'none',
					'&:hover': {
						boxShadow: 'none',
					},
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						backgroundColor: 'transparent',
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'&.Mui-checked': {
						color: '#000',
					},
				},
			},
		},
		MuiDialog: {
			defaultProps: {
				container: rootElement,
			},
			styleOverrides: {
				paper: {
					borderRadius: '23px',
					padding: '1.5rem',
				},
			},
		},
		MuiModal: {
			defaultProps: {
				container: rootElement,
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					marginTop: '1rem',
					padding: '0',
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					marginTop: '1rem',
					padding: 0,
				},
			},
		},

		MuiDialogTitle: {
			styleOverrides: {
				root: {
					padding: '0 0 8px 0',
					borderBottom: '1px solid rgba(165, 165, 179, 1)',
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: '#ADADB0',
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				multiple: {
					'&.Mui-error .MuiOutlinedInput-notchedOutline': {
						border: '2px solid #FF3D00',
					},
					paddingTop: '5px',
					paddingBottom: '5px',
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: '#4B4B4B',
					fontSize: '0.875rem',
					fontWeight: 400,
					[`@media (max-width: ${breakpoints.xl}px)`]: {
						fontSize: '0.75rem',
					},
				},
			},
		},
		MuiBadge: {
			styleOverrides: {
				badge: {
					right: 8,
					top: 13,
					border: `1px solid white`,
					width: '12px',
					height: '12px',
					borderRadius: '6px',
				},
			},
		},
	},
};

let theme = createTheme(themeOptions);
export default responsiveFontSizes(theme, { breakpoints: ['sm', 'md', 'lg', 'xl'] });
