import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { NextButton } from './styles';
import { blue } from '@mui/material/colors';

interface Props {
	open: boolean;
	content: {
		title: string;
		body: any;
		buttonText: string;
		onClose: () => void;
	};
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-container > .MuiPaper-root': {
		borderRadius: '10px',
		maxWidth: 'none',
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '90%',
		},
	},
	'& .MuiDialogContent-root': {
		padding: theme.spacing(0),
	},
}));

const StyledAlert2 = styled(Alert)(({ theme }) => ({
	border: 'none',
	backgroundColor: blue[50],
	borderRadius: '10px',
	color: '#222',
	textAlign: 'center',
}));

const ValidationDialog = (props: Props) => {
	const { open, content } = props;

	return (
		<BootstrapDialog onClose={() => {}} aria-labelledby="customized-dialog-title" open={open}>
			<DialogTitle
				sx={{
					textAlign: 'center',
					alignSelf: 'center',
					justifyContent: 'center',
					fontSize: '18px',
					paddingBottom: 0,
					marginTop: '10px',
					borderBottom: '0px',
				}}
			>
				{content.title}
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						my: 2,
						px: 7,
					}}
				>
					<StyledAlert2
						severity="info"
						sx={{
							fontSize: '13px',
							width: '100%',
							mb: 5,
							px: 4,
							color: '#222',
							borderRadius: 3,
							'& .MuiAlert-icon': { display: 'none' },
						}}
					>
						{content.body}
					</StyledAlert2>
					<NextButton variant="contained" onClick={content.onClose}>
						{content.buttonText}
					</NextButton>
				</Box>
			</DialogContent>
		</BootstrapDialog>
	);
};

export default ValidationDialog;
