import { Grid, Typography, debounce, Skeleton, Paper, Box } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useIntl } from 'react-intl';
import { Column, PaginatableTable, ValueRenderCell } from '../table-paginatable';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useMobile } from '../../hooks/use-mobile';
import PageContainer from '../page-container';
import service, { Audience } from './AudiencesService';
import styled from '@emotion/styled';
import SideModalContainer from '../modals/side-modal-container';
import AudienceDetail, { StatusBox } from './AudienceDetail';
import dayjs from 'dayjs';
import userState from '../../atoms/user-state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import disableRootElementScrollState from '../../atoms/disable-root-element-scroll-state';

interface AudienceRow {
	name: string,
	bonification: ValueRenderCell,
	token: string,
	members: string,
	date: string,
	disabled: boolean,
	disabledMessage?: string,
	onClick: () => void,
}

const AudienceBonificationBox = styled(Box)`
	border-radius: 4px;
	padding: 0 10px;
	height: 100%;
	width: 100%;
	max-width: 80px;
	display: inline-flex;
	justify-content: center;
`;

const Audiences: React.FC = () => {
	const user = useRecoilValue(userState);
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);
	const [isLoading, setIsLoading] = useState(true);
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const isMobile: boolean = useMobile();
	const intl = useIntl();
	const [audiences, setAudiences] = useState<AudienceRow[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedAudience, setSelectedAudience] = useState<Audience>();


	const columns: Column[] = [
		{ id: 'name', label: intl.formatMessage({ id: 'audiences.table.columns.name' }), width: '20%', align: 'left', orderable: true },
		{ id: 'bonification', label: intl.formatMessage({ id: 'audiences.table.columns.bonification' }), width: isMobile ? '15%' : '15%', align: 'left', orderable: true },
		{ id: 'token', label: intl.formatMessage({ id: 'audiences.table.columns.token' }), width: '15%', align: 'left' },
		{ id: 'members', label: intl.formatMessage({ id: 'audiences.table.columns.members' }), width: '20%', align: 'left', mobile: false },
		{ id: 'date', label: intl.formatMessage({ id: 'audiences.table.columns.date' }), width: '20%', align: 'left', orderable: true, mobile: false },
	];

	const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	};

	const getBonificationBox = (bonification: number, bonificationSign: string, bonificationType: string) => {
		const backgroundColor = bonificationSign === 'substract' ? '#3DBE001A' : '#FF3D001A';
		const color = bonificationSign === 'substract' ? '#3DBE00' : '#FF3D00';

		return {
			value: bonificationSign === 'substract' ? -bonification : bonification,
			render: (
				<AudienceBonificationBox sx={{ backgroundColor, color }}>
					{bonificationSign === 'substract' ? '-' : '+'}
					{(bonification || 0)?.toFixed(2)}
					{bonificationType === 'percentage' ? '%' : ''}
				</AudienceBonificationBox>
			)
		};
	};

	const formatTokenValue = (value: number) => {
		return intl.formatNumber(value, {
			style: 'currency',
			currency: 'ARS',
			currencyDisplay: 'narrowSymbol',
		})
	};

	const fetchAudiences = async (searchText?: string) => {
		let responseData: Audience[] = [];

		if (!searchText || searchText === '') {
			const audiencesResponse = await service.getAudiences(user);
			responseData = audiencesResponse || [];
		} else {
			const audiencesResponse = await service.searchAudiences(user, searchText);
			responseData = audiencesResponse || [];
		}

		const audiencesRows: AudienceRow[] = responseData.map((audience: Audience) => ({
			id: audience.id,
			name: audience.name,
			bonification: getBonificationBox(audience.bonification_value, audience.bonification_value_sign, audience.bonification_value_type),
			token: formatTokenValue(audience.token_value_without_iva),
			members: audience.members_count + ' ' + intl.formatMessage({ id: 'audiences.table.members_data' }),
			date: dayjs(audience.updated_at).locale('es').format('DD/MM/YYYY'),
			disabled: audience.status === 'disabled',
			disabledMessage: audience.status === 'disabled' ? intl.formatMessage({ id: 'audiences.table.disabled_message' }) : '',
			onClick: () => {
				setSelectedAudience(audience);
				setIsModalOpen(true);
			},
		})) || [];

		setAudiences(audiencesRows);

		setIsLoading(false);
	};

	useEffect(() => {
		let mounted = true;
		if (isInitialLoad) {
			const fetch = debounce(() => {
				if (!mounted) return;

				setIsLoading(true);
				fetchAudiences().then(() => setIsInitialLoad(false));
			});

			fetch();
		} else {
			const applyFilters = debounce(() => {
				if (!mounted) return;

				if (searchText === '') {
					fetchAudiences();
					return;
				}

				fetchAudiences(searchText);
			}, 250);

			applyFilters();
		}

		return () => { mounted = false };
	}, [isInitialLoad, searchText]);

	useEffect(() => {
		setDisableRootElementScroll(isModalOpen);
	}, [isModalOpen]);

	return (
		<PageContainer>
			<Grid sx={{ ml: '5px' }} container spacing={2}>
				<Grid item xs={12}>
					<Typography sx={{ pl: 2, fontSize: 32, fontWeight: 700 }} variant="h3" gutterBottom>
						{intl.formatMessage({ id: 'audiences.title' })}
					</Typography>
					{!isMobile && (
						<Typography sx={{ pl: 2, fontSize: 18, color: '#797979' }} variant="subtitle1">
							{intl.formatMessage({ id: `audiences.subtitle` })}
						</Typography>
					)}
				</Grid>

				<Paper elevation={0} sx={{ padding: '35px', marginTop: '35px', width: '100%', borderRadius: 2 }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								onChange={handleSearchInputChange}
								placeholder={intl.formatMessage({ id: 'audiences.search_placeholder' })}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>

					<Grid sx={{ mt: '35px' }} item xs={12}>
						{isLoading && isInitialLoad ? (
							<Skeleton variant="rectangular" height={'50vh'} animation="wave" />
						) : (
							<PaginatableTable
								columns={columns}
								noResultsMessage={intl.formatMessage({ id: 'common.table.no_data' })}
								isEmptyDataSource={audiences.length === 0}
								rows={audiences}
								emptyDataSourceMessage={intl.formatMessage({ id: 'common.table.no_data' })}
								disablePagination={true}
							/>
						)}
					</Grid>
				</Paper>
			</Grid>
			<SideModalContainer
				sx={{ width: '430px' }}
				slideDirection={isMobile ? 'up' : 'left'}
				modalPosition={isMobile ? 'center' : 'right'}
				modalContentAlign={isMobile ? 'bottom' : undefined}
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			>
				<AudienceDetail user={user} audience={selectedAudience} onClose={() => setIsModalOpen(false)} />
			</SideModalContainer>
		</PageContainer>
	);
};

export default Audiences;
