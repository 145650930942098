import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { debounce } from '@mui/material/utils';
import client from '../../../clients/client';
import { Role, User } from '../../../types';

interface Props {
    open: boolean;
    onClose: () => void;
    user: User | null;
}

interface ConstructorOptionType {
    id_constructora: string;
    name_constructora: string;
}
interface SellerOptionType {
    iddistribuidores: number;
    seller: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(1),
    borderRadius: '25px'
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    justifyContent: 'space-between'
  },
}));

const ChangeDialog = (props: Props) => {
    const { open, onClose, user } = props
    const userType = user?.type_external ?? Role.CONSTRUCTOR;
    
    const intl = useIntl();
    const [value, setValue] = React.useState<ConstructorOptionType | null>(null);
    const [sellerValue, setSellerValue] = React.useState<SellerOptionType | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState<readonly ConstructorOptionType[]>([]);
    const [sellerOptions, setSellerOptions] = React.useState<readonly SellerOptionType[]>([]);


    const fetchData = debounce(() => {
        client
            .switchInfo({
                fields: {
                    cid: value?.id_constructora,
                    user: user ? JSON.stringify({error: false, user}) : null,
                    nav_bar: {
                        "urls": {
                            "/proyectos/": "https://lookerstudio.google.com/embed/reporting/39193a90-da1e-456b-8e9a-325d6ad21849/page/p_upf7jhog3c?params=%7B%22ds2.id_constructora_2%22:%2290\",\"ds225.id_constructora\":\"90\"%7D",
                            "/requerimiento/": "https://lookerstudio.google.com/embed/reporting/39193a90-da1e-456b-8e9a-325d6ad21849/page/p_fdbdq8cd3c?params=%7B%22ds225.id_requerimiento%22:90,%22ds225.id_constructora%22:\"90\",%22ds2.id_constructora_2%22:\"90\"%7D",
                            "/cotizaciones/": "https://lookerstudio.google.com/embed/reporting/89c8b636-254f-47c0-98d3-52bfbc3ba580/page/p_gb5ls8xa2c?params=%7B%22ds2.id_constructora_2%22:%2290\",\"ds82.id_constructora\":\"90\"%7D",
                            "/": "https://lookerstudio.google.com/embed/reporting/89c8b636-254f-47c0-98d3-52bfbc3ba580/page/p_e85hpg0c2c?params=%7B%22ds2.id_constructora_2%22:%2290\",\"ds82.id_constructora\":\"90\"%7D"
                        },
                        "timestamp": "2023-09-21T17:36:19.562Z"
                    }
                }, user 
            })
            .then((data) => {
                localStorage.setItem('navbarData', JSON.stringify(data.new_nav_bar))
                localStorage.setItem('user', JSON.stringify({user: data.new_user.user, error: false}))
                localStorage.setItem('token', JSON.stringify(data.new_user.user.token))
                window.location.reload();
            })
            .catch((error) => console.log({ error }));
    }, 250);

    const fetchSellerData = debounce(() => {
        client
            .switchInfoSellers({
                fields: {
                    sid: sellerValue?.iddistribuidores,
                    user: user ? JSON.stringify({error: false, user}) : null,
                    nav_bar: {
                        "urls": {
                            "/proyectos/": "https://lookerstudio.google.com/embed/reporting/39193a90-da1e-456b-8e9a-325d6ad21849/page/p_upf7jhog3c?params=%7B%22ds2.id_constructora_2%22:%2290\",\"ds225.id_constructora\":\"90\"%7D",
                            "/requerimiento/": "https://lookerstudio.google.com/embed/reporting/39193a90-da1e-456b-8e9a-325d6ad21849/page/p_fdbdq8cd3c?params=%7B%22ds225.id_requerimiento%22:90,%22ds225.id_constructora%22:\"90\",%22ds2.id_constructora_2%22:\"90\"%7D",
                            "/cotizaciones/": "https://lookerstudio.google.com/embed/reporting/89c8b636-254f-47c0-98d3-52bfbc3ba580/page/p_gb5ls8xa2c?params=%7B%22ds2.id_constructora_2%22:%2290\",\"ds82.id_constructora\":\"90\"%7D",
                            "/": "https://lookerstudio.google.com/embed/reporting/89c8b636-254f-47c0-98d3-52bfbc3ba580/page/p_e85hpg0c2c?params=%7B%22ds2.id_constructora_2%22:%2290\",\"ds82.id_constructora\":\"90\"%7D"
                        },
                        "timestamp": "2023-09-21T17:36:19.562Z"
                    }
                }, user 
            })
            .then((data) => {
                localStorage.setItem('navbarData', JSON.stringify(data.new_nav_bar))
                localStorage.setItem('user', JSON.stringify({user: data.new_user.user , error: false}))
                localStorage.setItem('token', JSON.stringify(data.new_user.user.token))
                window.location.reload();
            })
            .catch((error) => console.log({ error }));
    }, 250);
    
    const fetchConstructor = React.useMemo(
        () =>
            debounce(
            (searchText) => {
                setLoading(true)
                client
                    .searchConstructor({ fields: {
                        q: searchText,
                        user: JSON.stringify(user)
                    }})
                    .then((data) => {
                        setOptions([...data.results]);
                    })
                    .catch((err) => console.log({ error: err }))
                    .finally(()=> setLoading(false));
            },
            400
            ),
        [],
        );

    const fetchSeller = React.useMemo(
        () =>
            debounce(
            (searchText) => {
                setLoading(true)
                client
                    .searchSeller({ fields: {
                        q: searchText,
                        user: JSON.stringify(user)
                    }})
                    .then((data) => {
                        setSellerOptions([...data.results]);
                    })
                    .catch((err) => console.log({ error: err }))
                    .finally(()=> setLoading(false));
            },
            400
            ),
        [],
        );
  

  React.useEffect(() => {
    if (inputValue.trim() !== '') {
        if(userType === Role.CONSTRUCTOR) fetchConstructor(inputValue);
        if(userType === Role.SELLER) fetchSeller(inputValue);
      } else {
        setOptions([]); 
        setSellerOptions([]);
      }
  }, [fetchConstructor, fetchSeller, inputValue, userType]);

  const handleConfirmChange = () => {
    if(userType === Role.CONSTRUCTOR) fetchData();
    if(userType === Role.SELLER) fetchSellerData();
  }
  
  return (
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ alignSelf: 'center', justifyContent: 'center' }} id="customized-dialog-title">
            {intl.formatMessage({ id: `dialog.change_${userType}.title` })}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
            <Typography gutterBottom>
                {intl.formatMessage({ id: `dialog.change_${userType}.body` })}
            </Typography>
            {userType === Role.CONSTRUCTOR && (<Autocomplete
                id="change-entity"
                sx={{ width: 300, marginTop: '10px' }}
                getOptionLabel={(option) =>
                    option.name_constructora
                }
                autoHighlight
                filterOptions={(x) => x}
                options={options}
                includeInputInList
                isOptionEqualToValue={(option, value) => option.name_constructora === value.name_constructora}
                noOptionsText={intl.formatMessage({ id: `dialog.change_constructor.not_found` })}
                onChange={(event: any, newValue: ConstructorOptionType | null) => {
                    setValue(newValue);
                  }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField {...params} label={intl.formatMessage({ id: `dialog.change_constructor.search` })} InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }} />
                )}
                renderOption={(props, option) => {
                                       
                    return (
                    <li {...props}>
                        <Grid container alignItems="center">
                        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                           
                            <Typography variant="body2" color="text.secondary">
                            {option.name_constructora}
                            </Typography>
                        </Grid>
                        </Grid>
                    </li>
                    );
                }}
            />)}
            {userType === Role.SELLER && (<Autocomplete
                id="change-entity"
                sx={{ width: 300, marginTop: '10px' }}
                getOptionLabel={(option) =>
                    option.seller
                }
                autoHighlight
                filterOptions={(x) => x}
                options={sellerOptions}
                includeInputInList
                isOptionEqualToValue={(option, value) => option.seller === value.seller}
                noOptionsText={intl.formatMessage({ id: `dialog.change_seller.not_found` })}
                onChange={(event: any, newValue: SellerOptionType | null) => {
                    setSellerValue(newValue);
                  }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField {...params} label={intl.formatMessage({ id: `dialog.change_seller.search` })} InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }} />
                )}
                renderOption={(props, option) => {
                    // const matches =
                    // option.structured_formatting.main_text_matched_substrings || [];

                    // const parts = parse(
                    // option.structured_formatting.main_text,
                    // matches.map((match: any) => [match.offset, match.offset + match.length]),
                    // );
                    
                    return (
                    <li {...props}>
                        <Grid container alignItems="center">
                        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                           
                            <Typography variant="body2" color="text.secondary">
                            {option.seller}
                            </Typography>
                        </Grid>
                        </Grid>
                    </li>
                    );
                }}
            />)}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} sx={{ border: '1px solid #000', borderRadius: 10, width: '45%' }} variant="contained">
                {intl.formatMessage({ id: 'common.back' })}
            </Button>
            <Button 
                onClick={() => handleConfirmChange()}
                sx={{ 
                    background: '#000', color: '#FFF', border: '1px solid #000', 
                    borderRadius: 10, width: '45%', "&:hover": {
                        color: '#FFF',
                        backgroundColor: '#000'
                    } 
                }} 
                variant="contained">
                {intl.formatMessage({ id: 'common.send' })}
            </Button>
        </DialogActions>
      </BootstrapDialog>
  );
}

export default ChangeDialog;
