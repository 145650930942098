import { Agreement, AgreementCollection, AgreementPricing, AgreementProduct, BusinessName, Response, Seller } from "../../../../types"
import {
    INITIALIZE_STATE_WITH_AGREEMENT_DATA,
    RESET_ERROR_ON_FETCH,
    SET_COMPANIES,
    SET_EQUIVALENCE_PRODUCTS_MAP,
    SET_ERROR_FROM_FETCH_TRUE,
    SET_NO_TOKENS_AVAILABLE_TO_BUY,
    SET_QUANTITY,
    SET_SELECTED_AGREEMENT_ID,
    SET_SELECTED_COMPANY,
    SET_SELECTED_FINANCING_METHOD,
    SET_SELECTED_DELIVERY,
    SET_STOCK_SAFEKEEPING_ID,
    TOGGLE_IS_LOADING_RESPONSE,
    SET_ERROR_FROM_PURCHASE,
    SET_SELLER,
    TOGGLE_IS_HELP_DIALOG_OPEN,
} from "./actionNames"

export interface AgreementCondition {
    id: string;
    auth_rules: any[] | null;
    condition_type_id:
        '1' |   // shipping
        '2' |   // tokens
        '3' |   // total_price
        '4' |   // financing
        '5' |   // equivalence
        '6',    // withdrawal
    condition_type_label: string;
    code: string;
    description: string;
    min_value: number | null;
    min_tokens: number | null;
    name: string;
    status: 'active' | 'archived';
    value: number | null;
    value_sign: 'add' | 'subtract' | 'multiply' | 'divide';
    value_type: 'percentage' | 'fixed';
    metadata: any;
    zones: ShippingZone[] | null;
}

export interface ShippingZone {
    zone_id: string,
    zone_label: string,
    agreement_condition_id: string,
    zone_url?: string,
    localities?: string,
    zone_condition_metadata?: any
}

export interface State {
	agreement: Agreement | undefined;
	benefit: AgreementCondition | null;
	collection: AgreementCollection | undefined;
	companies: BusinessName[];
    errorOnFetch: boolean;
    errorOnPurchase: string | null;
    equivalenceProductsMap: Map<string, { requireUnits: number | null, necessaryTokens: number | null }>,
	financing: AgreementCondition[];
    isHelpDialogOpen: boolean;
    isLoadingResponse: boolean;
    noAvailableTokensToBuy: boolean;
	pricing: AgreementPricing | undefined;
	products: AgreementProduct[];
    quantity: number;
    quantityString: string;
	shippings: AgreementCondition[];
    selectedAgreementId: string;
    selectedCompany: BusinessName | undefined;
    selectedDelivery: AgreementCondition | undefined;
    selectedFinancingMethod: AgreementCondition | undefined;
    seller: Seller | undefined;
    stockSafekeepingId: string;
	withdrawal: AgreementCondition | null;
}

export const initialState = {
    agreement: undefined,
    benefit: null,
    collection: undefined,
    companies: [],
    errorOnFetch: false,
    errorOnPurchase: null,
    equivalenceProductsMap: new Map(),
    financing: [],
    isHelpDialogOpen: false,
    isLoadingResponse: false,
    noAvailableTokensToBuy: false,
    pricing: undefined,
    products: [],
    quantity: 1,
    quantityString: '1',
    shippings: [],
    selectedAgreementId: '',
    selectedCompany: undefined,
    selectedDelivery: undefined,
    selectedFinancingMethod: undefined,
    seller: undefined,
    stockSafekeepingId: '',
    withdrawal: null,
}

export default function reducer(state: State = initialState, action: { type: string, payload?: any }) {
    switch (action.type) {
        case INITIALIZE_STATE_WITH_AGREEMENT_DATA:
            const {
                agreement,
                benefit,
                collection,
                financing,
                equivalenceProductsMap,
                products,
                pricing,
                shippings,
                selectedFinancingMethod,
                selectedDelivery,
                withdrawal,
            } = action.payload
            return {
                ...state,
                agreement,
                benefit,
                collection,
                equivalenceProductsMap,
                financing,
                isLoadingResponse: false,
                products,
                pricing,
                quantity: 1,
                quantityString: '1',
                shippings,
                selectedFinancingMethod,
                selectedDelivery,
                withdrawal,
            }
        case RESET_ERROR_ON_FETCH:
            return {
                ...state,
                errorOnFetch: false,
                isLoadingResponse: true
            }
        case SET_COMPANIES: {
            return {
                ...state,
                companies: action.payload,
                selectedCompany: action.payload[0]
            }
        }
        case SET_QUANTITY:
            const { n, str } = action.payload
            return {
                ...state,
                quantity: n,
                quantityString: str
            }
        case SET_EQUIVALENCE_PRODUCTS_MAP:
            const { newMap, quantity, quantityString } = action.payload
            return {
                ...state,
                equivalenceProductsMap: newMap,
                quantity,
                quantityString
            }
        case SET_ERROR_FROM_FETCH_TRUE:
            return {
                ...state,
                errorOnFetch: true,
            }
        case SET_ERROR_FROM_PURCHASE:
            return {
                ...state,
                errorOnPurchase: action.payload,
            }
        case SET_NO_TOKENS_AVAILABLE_TO_BUY:
            return {
                ...initialState, // to reset state
                isLoadingResponse: false,
                selectedAgreementId: state.selectedAgreementId,
                noAvailableTokensToBuy: action.payload
            }
        case SET_SELECTED_AGREEMENT_ID:
            return {
                ...state,
                noAvailableTokensToBuy: false, // when changing agreement, reset noAvailableTokensToBuy
                equivalenceProductsMap: new Map(),
                quantity: 1,
                quantityString: '1',
                selectedAgreementId: action.payload
            }
        case SET_SELECTED_COMPANY:
            return {
                ...state,
                selectedCompany: action.payload
            }
        case SET_SELECTED_FINANCING_METHOD:
            return {
                ...state,
                selectedFinancingMethod: action.payload
            }
        case SET_SELECTED_DELIVERY:
            return {
                ...state,
                selectedDelivery: action.payload
            }
        case SET_SELLER:
            return {
                ...state,
                seller: action.payload
            }
        case SET_STOCK_SAFEKEEPING_ID:
            return {
                ...state,
                stockSafekeepingId: action.payload
            }
        case TOGGLE_IS_LOADING_RESPONSE:
            return {
                ...state,
                isLoadingResponse: !state.isLoadingResponse
            }
        case TOGGLE_IS_HELP_DIALOG_OPEN:
            return {
                ...state,
                isHelpDialogOpen: !state.isHelpDialogOpen
            }
        default:
            return state;
    }
}