import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import userState from '../../../atoms/user-state';
import { Document } from '../../../types';
import FullScreenLoading from '../../full-screen-loading';
import NuqleaFileUpload from '../../nuqlea-file-uploader';
import SellerPaymentInfo from './seller-payment-info';
import client from '../../../clients/client';

interface Props {
  dataConstructor: any;
  listId: string;
	open: boolean;
	onClose: (event?: object | null, reason?: string, newFile?: Document) => void;
  seller: any;
  sellerFiles: any[];
}

interface State {
  files: File[];
  fileNumber: string;
  isLoading: boolean;
  isSellerPaymentInfoOpen: boolean;
  error: string;
}

const PurchaseOrderUploadDialog = (props: Props) => {
  const intl = useIntl();
  const { dataConstructor, listId, open, onClose, seller, sellerFiles } = props;

  const user = useRecoilValue(userState);

  const [state, setState] = useState<State>({
    files: [],
    fileNumber: '',
    isLoading: false,
    isSellerPaymentInfoOpen: false,
    error: '',
  });
  const { files, fileNumber, isLoading, isSellerPaymentInfoOpen, error } = state;

	const handleFileLoad = (file: File) => {
		if (file) {
			const FILES_MAX_SIZE = 4;

			if (file.size / 1024 / 1024 >= FILES_MAX_SIZE) {
        setState({ ...state, error: intl.formatMessage({ id: 'list.details.error_message.invalid_size' }, { maxFileSize: '4' })});
				return;
			}

			if (
				file.type !== 'application/pdf'
			) {
        setState({ ...state, error: intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.error.file_type' })});
				return;
			}
      setState({ ...state, files: [file], error: '' });
		} else {
      setState({ ...state, files: [], error: '' });
    }
	};
  
	const handleSubmit = () => {
    setState({ ...state, isLoading: true, error: '' });
		const file = files[0];

		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve((reader.result as string).split(',').pop());
			reader.onerror = (error) => reject(error);
		}).then((data) => {
			client.uploadDriveFile({
        fileName: file.name,
        fileType: file.type,
        fileData: data,
        type: 'Orden de compra',
        user,
        relatedFiles: [],
        listId: dataConstructor?.metadata.list_id,
        listMigrationId: dataConstructor.metadata.quotation_id,
        orderId: dataConstructor?.metadata.order_id,
        orderMigrationId: dataConstructor?.metadata.order_migration_id,
        listEnvironment: dataConstructor?.metadata.environment,
        filesFolderId: dataConstructor?.metadata.files_folder_id,
        documentId: fileNumber,
        documentDate: null
      })
				.then((response: any) => {
					onClose(null, 'success', {
						file_id: response.file_key,
						id_doc: fileNumber ?? '',
						list_id: dataConstructor.metadata.list_id,
						order_id: '',
						comment: null,
						file_type_id: 7,
						file_path: '',
						drive_file_id: response.fileId || response.file_id,
						label_file_type: 'Orden de compra',
						created_by: user.email,
						created_by_name: user.email.includes('@nuqlea.com') ? 'NUQLEA' : user.name,
						parent_documents_ids: [].join(','),
						timestamp: response.dateLoaded || response.date_loaded,
						constructor_id: user.type_external,
						children: [],
						created_at: response.createdAt,
					});

					// Reset form
          setState({ ...state, files: [], fileNumber: '' });
				})
				.catch((error: any) => setState({ ...state, error: error.message }))
				.finally(() => setState({ ...state, isLoading: false }));
		});
	};

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm' PaperProps={{ sx: { maxWidth: '500px' } }}>
        {isLoading && <FullScreenLoading />}
        <DialogTitle>
          <Typography variant='h4'>{intl.formatMessage({ id: 'list.details.purchase_order_upload_dialog.title' })}</Typography>
        </DialogTitle>
        <DialogContent className='pt-1 pb-4'>
          <Box
            className="flex items-center w-full p-4 rounded-md bg-blue-100"
          >
            <InfoOutlined sx={{ mr: 1.5, color: 'blue' }} />
            <Typography variant="body1" color="blue">
              {intl.formatMessage(
                { id: 'list.details.purchase_order_upload_dialog.seller_info_message' },
                { sellerLink:
                  <Box
                    component='span'
                    fontWeight={600}
                    className='underline cursor-pointer'
                    onClick={() => setState({ ...state, isSellerPaymentInfoOpen: true })}
                  >
                    {intl.formatMessage({ id: 'list.details.purchase_order_upload_dialog.seller_info_message_link' })}
                  </Box>
                }
              )}
            </Typography>
          </Box>
          <InputLabel className='font-black flex mt-8 mb-2'>
            {intl.formatMessage({ id: 'list.details.purchase_order_upload_dialog.number.input_label' })}
          </InputLabel>
          <TextField
            className='mb-2'
            disabled={isLoading}
            fullWidth
            variant="outlined"
            size="small"
            value={fileNumber}
            onChange={(event) => setState({ ...state, fileNumber: event.target.value })}
            placeholder={intl.formatMessage({ id: 'list.details.purchase_order_upload_dialog.number.placeholder' })}
          />
          <InputLabel className='font-black flex mt-5 mb-2'>
            {intl.formatMessage({ id: 'list.details.purchase_order_upload_dialog.file.input_label' })}
          </InputLabel>
          <NuqleaFileUpload
            value={files}
            onChange={(files) => {
              setState({ ...state, error: '' });
              handleFileLoad(files[0]);
            }}
            buttonText={intl.formatMessage({ id: 'common.upload' })}
            title={intl.formatMessage({ id: 'list.details.purchase_order_upload_dialog.file.placeholder' })}
            specText={' '}
          />
          {error && (
            <Alert severity='error' className='mt-2'>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: 1 }}>
          <Button
            className='w-28'
            disabled={Boolean(error) || !!isLoading}
            onClick={() => onClose(null, 'cancelButtonClick')}
            variant='outlined'
          >
            {intl.formatMessage({ id: 'common.close' })}
          </Button>
          <Button
            className='w-28'
            disabled={Boolean(isLoading || error || !files.length)}
            onClick={handleSubmit}
            variant='contained'
          >
            {intl.formatMessage({ id: 'common.upload' })}
          </Button>
        </DialogActions>
      </Dialog>
			{	
				sellerFiles?.length > 0 &&
				<SellerPaymentInfo
          open={isSellerPaymentInfoOpen}
          sellerData={{ name: seller.name, taxId: seller.cuit, image: seller.image }}
          files={sellerFiles?.reduce((acc:any, obj:any) => {
						const key = obj.file_type;
						const curGroup = acc[key] ?? [];
						return { ...acc, [key]: [...curGroup, obj] };
					}, {})}
          listId={listId}
          user={user}
					onClose={() => setState({ ...state, isSellerPaymentInfoOpen: false })}
				/>
			}
    </>
	);
}

export default PurchaseOrderUploadDialog