import { Box, Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { RedeemRequestStatus, RedeemRequestTypes, Document, Delivery, Role, RedeemTypesMapper } from '../../../types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FileUploadDialog from '../../details/dialogs/file-upload-dialog';
import StatusCard, { Status } from '../../status-card';
import TableCardTreeView from '../../table-card-tree-view';
import TableCard from '../../table-card';
import { useIntl } from 'react-intl';
import { InfoOutlined, ThumbDown, ThumbUp, Upload } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DeliveryContext } from '../../../contexts/deliveryContext';
import { relatedDeliveries, relatedFiles, relatedDeliveryFiles, relatedProducts } from './columns';
import client from '../../../clients/client';
import { useParams, useNavigate } from 'react-router-dom';
import BudgetAcceptanceDialog from '../BudgetAcceptanceDialog';
import RejectedRedeemTokensDialog from '../dashboard/dialogs/RejectedRedeemTokensDialog';
import DeliveryAddress from '../../delivery-address';
import userState from '../../../atoms/user-state';
import disableRootElementScrollState from '../../../atoms/disable-root-element-scroll-state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SellerDeliveryModal from './deliveries/seller-delivery-modal';
import ConstructorDeliveryModal from './deliveries/constructor-delivery-modal';
import EndRedeemDialog from './dialog/EndRedeem';
import Error from '../../error';
import BasePageFrame from '../../base-components/base-page-frame';

const sellerAllowedFileTypes = [
	{ id: 6, name: 'Factura' },
	{ id: 13, name: 'Nota de crédito' },
	{ id: 15, name: 'Nota de débito' },
	{ id: 14, name: 'Recibo de pago' },
];

const constructorAllowedFileTypes = [
	{ id: 7, name: 'Orden de compra' },
	{ id: 9, name: 'Comprobante de pago' },
	{ id: 10, name: 'Comprobante de retenciones' },
	{ id: 11, name: 'Órden de pago' },
];

const Redeemview = () => {
	const user = useRecoilValue(userState);
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);

	const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
	const [data, setData] = useState<any>(null);
	const [error, setError] = useState<any>(null);
	const [openRedeemModal, setOpenRedeemModal] = useState(false);
	const { id } = useParams();
	const intl = useIntl();
	const navigate = useNavigate();

	const [message, setMessage] = useState<any>();
	const [baseInfo, setBaseInfo] = useState<any>();
	const [deliveryModal, setDeliveryModal] = useState<{ data: Delivery; modalType: 'new' | 'edit' | 'view' } | null>(
		null
	);
	const [rrFiles, setRRFiles] = useState<any[]>([]);

	const [showBudgetAcceptanceDialog, setShowBudgetAcceptanceDialog] = useState(false);
	const [showBudgetRejectDialog, setShowBudgetRejectDialog] = useState(false);
	const [loadingDialog, setLoadingDialog] = useState(false);

	const init = useCallback(async () => {
		setShowBudgetAcceptanceDialog(false);
		setData(null);

		try {
			const { redeem_request: redeemRequest } = await client.fetchRedeemRequest(user.token, id as string);

			if (!redeemRequest) {
				setError({
					message: 'No se encontró la solicitud de canje o no estás autorizado para verla',
				});
				setLoadingDialog(false);
				return;
			}

			let address;
			if (redeemRequest.address.includes('https:')) {
				const url = new URL(redeemRequest.address);
				const qParam = url.searchParams.get('q');

				if (!!qParam) {
					const [lat, lng] = qParam.split(',').map(Number);
					const geocoder = new google.maps.Geocoder();

					geocoder
						.geocode({
							location: { lat, lng },
						})
						.then((res) => {
							address = res.results[0].formatted_address;
						});
				}
			}

			const { redeem_request_products: redeemRequestProducts } = await client.fetchRedeemRequestProducts(
				user.token,
				id as string
			);
			const { redeem_request_files: redeemRequestFiles } = await client.fetchRedeemRequestFiles(
				user.token,
				id as string
			);
			const { redeem_request_shippings: redeemRequestShippings } = await client.fetchRedeemRequestShippings(
				user.token,
				id as string
			);

			let statusPill: 'aceptado' | null = null;
			if (
				user.type_external === Role.CONSTRUCTOR &&
				redeemRequest?.status > 2 &&
				redeemRequest?.type === RedeemRequestTypes.SHIPPING
			) {
				statusPill = 'aceptado';
			}

			setRRFiles(
				redeemRequestFiles.map((item) =>
					item.label_tipo_archivo === 'Presupuesto de envio' ? { ...item, status: statusPill } : item
				)
			);

			const redeemProductsMap = new Map();
			redeemRequestShippings.forEach((shipping: any) => {
				// iterate shippings to get the quantity of delivered, pending to deliver and unassigned products
				shipping.products.forEach((product: any) => {
					if (redeemProductsMap.has(product.redeem_request_product_id)) {
						const { pending, delivered } = redeemProductsMap.get(product.redeem_request_product_id);
						if (shipping.status === 1) {
							redeemProductsMap.set(product.redeem_request_product_id, {
								pending: pending + product.quantity,
								delivered,
							});
						} else if (shipping.status === 2) {
							redeemProductsMap.set(product.redeem_request_product_id, {
								pending,
								delivered: delivered + product.quantity,
							});
						}
					} else {
						const pending = shipping.status === 1 ? product.quantity : 0;
						const delivered = shipping.status === 2 ? product.quantity : 0;
						redeemProductsMap.set(product.redeem_request_product_id, { pending, delivered });
					}
				});
			});

			const redeemRequestProductsUpdated: any[] = redeemRequestProducts.map((product: any) => {
				// update the array of products with the quantity of delivered and pending products
				const { pending, delivered } = redeemProductsMap.get(product.id) || { pending: 0, delivered: 0 };
				return {
					...product,
					pending,
					delivered,
					unassigned: product.quantity - pending - delivered,
				};
			});

			const data = {
				redeemRequest,
				address,
				redeemRequestProducts: redeemRequestProductsUpdated,
				redeemRequestShippings: redeemRequestShippings.map((delivery: any) => ({
					...delivery,
					id: delivery.entrega_id,
					type: delivery.tipo_entrega_id,
					estimated_delivery_date: delivery.fecha_pactada,
					delivery_date: delivery.fecha_entrega,
					comment: delivery.comentarios_entrega,
				})),
			};

			setData(data);
			setBaseInfo({
				metadata: {
					files_folder_id: redeemRequest.files_folder_id,
					redeem_request_id: redeemRequest.id,
				},
			});
		} catch (error) {
			setError({
				message: 'No se encontró la solicitud de canje o no estás autorizado para verla',
			});
			setLoadingDialog(false);
			return;
		}
	}, [id, user.token, user.type_external]);

	const handleGoBack = () => {
		navigate(
			user.type_external === Role.CONSTRUCTOR
				? `/my-tokens?agreement=${data?.redeemRequest?.agreement_id}`
				: `/my-tokens`
		);
	};

	const fetchFiles = async () => {
		const { redeem_request_files: redeemRequestFiles } = await client.fetchRedeemRequestFiles(user.token, id as string);
		setRRFiles(redeemRequestFiles);
	};

	const forceInsertNewFile = (newFile: Document) => {
		if (newFile.parent_documents_ids) {
			const allRelations = newFile.parent_documents_ids.split(',');
			let allNewDocs = rrFiles || [];

			allRelations.map((fileId: string) => {
				const foundIndex = allNewDocs.findIndex((el: Document) => el.file_id === fileId);
				allNewDocs[foundIndex].children = [...allNewDocs[foundIndex].children, newFile];

				return fileId;
			});

			setRRFiles(allNewDocs);
		} else {
			setRRFiles([
				...rrFiles,
				{ ...newFile, label_tipo_archivo: newFile.label_file_type, file: newFile.drive_file_id },
			]);
		}

		if (newFile.file_type_id === 9 && data?.redeemRequest?.status === 6) {
			setData({
				...data,
				redeemRequest: {
					...data.redeemRequest,
					status: 7,
				},
			});
		} else if (newFile.file_type_id === 6 && data?.redeemRequest?.status === 5) {
			setData({
				...data,
				redeemRequest: {
					...data.redeemRequest,
					status: 6,
				},
			});
		}
	};

	const handleReject = (content: string) => {
		setLoadingDialog(true);
		client
			.redeemConstructorAction({
				fields: {
					redeem_request_id: data?.redeemRequest?.id,
					action_type: 3,
					comments: content,
					result: 6,
				},
				token: user.token,
			})
			.then(() => {
				navigate(`/my-tokens?agreement=${data?.redeemRequest?.agreement_id}`);
			});
	};

	const handleAccept = () => {
		setLoadingDialog(true);
		client
			.redeemConstructorAction({
				fields: {
					redeem_request_id: data?.redeemRequest?.id,
					action_type: 3,
					comments: '',
					result: 5,
				},
				token: user.token,
			})
			.then(() => {
				// setShowBudgetAcceptanceDialog(false)
				setTimeout(() => {
					init();
				}, 2500);
				// navigate(`/my-tokens?agreement=${data?.redeemRequest?.agreement_id}`);
			});
	};

	useEffect(() => {
		init();
	}, [user.token, id, init]);

	useEffect(() => {
		setDisableRootElementScroll(
			Boolean(
				deliveryModal || fileUploadDialogOpen || showBudgetAcceptanceDialog || showBudgetRejectDialog || openRedeemModal
			)
		);
	}, [deliveryModal, fileUploadDialogOpen, showBudgetAcceptanceDialog, showBudgetRejectDialog, openRedeemModal, setDisableRootElementScroll]);

	const onBudgetRejectClick = () => {
		setShowBudgetRejectDialog(true);
	};

	const onBudgetAcceptClick = () => {
		setShowBudgetAcceptanceDialog(true);
	};

	const newDelivery = useMemo(() => {
		return {
			products: data?.redeemRequestProducts.map((product: any) => ({
				redeem_request_product_id: product.id,
				redeem_request_product_product_id: product.product_id,
				quantity: 0,
			})),
			estimated_delivery_date: '',
			delivery_date: null,
			comment: '',
			status: 1, // pending
			files: [{ file: undefined, id_doc: '' }],
		};
	}, [data?.redeemRequestProducts]);

	const counts: Status[] =
		user.type_external === Role.SELLER
			? !data?.redeemRequest?.shipping_price
				? [
						{ label: RedeemRequestStatus.REQUEST_RECEIVED, active: false, completed: true },
						{ label: RedeemRequestStatus.REDEEM_APPROVED, active: false, completed: true },
						{
							label: RedeemRequestStatus.PENDING_RELEASE,
							active: data?.redeemRequest?.status === 2,
							completed: data?.redeemRequest?.status > 2,
						},
						{ label: RedeemRequestStatus.DELIVERED, active: false, completed: data?.redeemRequest?.status > 2 },
				  ]
				: [
						{
							label: RedeemRequestStatus.QUOTE_SENT,
							active: [1].includes(data?.redeemRequest?.status),
							completed: ![1].includes(data?.redeemRequest?.status),
						},
						{
							label: RedeemRequestStatus.AWAITING,
							active: [2].includes(data?.redeemRequest?.status),
							completed: ![1, 2].includes(data?.redeemRequest?.status),
						},
						{
							label: RedeemRequestStatus.INVOICE,
							active: [5].includes(data?.redeemRequest?.status),
							completed: ![1, 2, 3, 5].includes(data?.redeemRequest?.status),
						},
						{
							label: RedeemRequestStatus.PAYMENT,
							active: [6].includes(data?.redeemRequest?.status),
							completed: ![1, 2, 3, 5, 6].includes(data?.redeemRequest?.status),
						},
						{
							label: RedeemRequestStatus.SHIPPING,
							active: [7].includes(data?.redeemRequest?.status),
							completed: ![1, 2, 3, 5, 6, 7].includes(data?.redeemRequest?.status),
							// active: data?.redeemRequest?.status === 4 && data?.redeemRequestShippings.findIndex((elem: any) => elem.status === 1) !== -1,
							// completed: data?.redeemRequest?.status === 4 && data?.redeemRequestShippings.findIndex((elem: any) => elem.status === 1) === -1
						},
				  ]
			: !data?.redeemRequest?.shipping_price
			? [
					{ label: RedeemRequestStatus.REQUEST_RECEIVED, active: false, completed: true },
					{
						label: RedeemRequestStatus.REDEEM_APPROVED,
						active: data?.redeemRequest?.status === 1,
						completed: data?.redeemRequest?.status > 1,
					},
					{
						label: RedeemRequestStatus.PENDING_RELEASE,
						active: data?.redeemRequest?.status === 2,
						completed: data?.redeemRequest?.status > 2,
					},
					{ label: RedeemRequestStatus.DELIVERED, active: false, completed: data?.redeemRequest?.status > 2 },
			  ]
			: [
					{
						label: RedeemRequestStatus.REQUEST_SENT,
						active: [1].includes(data?.redeemRequest?.status),
						completed: ![1].includes(data?.redeemRequest?.status),
					},
					{
						label: RedeemRequestStatus.QUOTE_RECEIVED,
						active: [2].includes(data?.redeemRequest?.status),
						completed: ![1, 2].includes(data?.redeemRequest?.status),
					},
					{
						label: RedeemRequestStatus.INVOICE,
						active: [5].includes(data?.redeemRequest?.status),
						completed: ![1, 2, 3, 5].includes(data?.redeemRequest?.status),
					},
					{
						label: RedeemRequestStatus.AWAITING_PAYMENT,
						active: [6].includes(data?.redeemRequest?.status),
						completed: ![1, 2, 3, 5, 6].includes(data?.redeemRequest?.status),
					},
					{
						label: RedeemRequestStatus.SHIPPING,
						active: [7].includes(data?.redeemRequest?.status),
						completed: ![1, 2, 3, 5, 6, 7].includes(data?.redeemRequest?.status),
					},
			  ];

	if (error) {
		return <Error />;
	}
	
	return (
		<BasePageFrame
			title={`Solicitud de canje ${data ? intl.formatDate(data?.redeemRequest?.created_at, { timeZone: 'UTC' }) : ''}`}
			transparent
			returnAction={handleGoBack}
			childContainerStyles="mt-[-1.2rem]"
			titleContainerStyles='items-center flex gap-1'
			endAdornment={
				<Box className="flex flex-col md:flex-row mt-[-0.5rem]">
					{!!data?.redeemRequest?.created_at && (
						<>
							{data?.redeemRequest?.status !== 4 &&
								data?.redeemRequest?.status !== 8 &&
								user.type_external === Role.SELLER && (
									<Tooltip
										title={
											data?.redeemRequestShippings.some((elem: any) => elem.status === 1)
												? 'Todas las entregas deben estar finalizadas'
												: data.redeemRequestShippings.length === 0
												? 'No hay entregas para finalizar el canje'
												: ''
										}
										placement="top"
									>
										<Stack direction={'row'} spacing={3}>
											<Button
												disabled={
													data?.redeemRequestShippings.some((elem: any) => elem.status === 1) ||
													data.redeemRequestShippings.length === 0
												}
												onClick={() => setOpenRedeemModal(true)}
												className="md:ml-4 mt-2 md:mt-0"
												variant="outlined"
											>
												{intl.formatMessage({ id: 'mytokens.redeem.end_button' })}
											</Button>
										</Stack>
									</Tooltip>
								)}
						</>
					)}
				</Box>
			}
		>
			<Box
				className="flex flex-col"
				sx={{ gap: { xs: 2, lg: 4 } }}
			>
				{data?.redeemRequest.returned_tokens > 0 && (
					<Box
						className="flex items-center w-full p-4 rounded-md bg-blue-100"
						sx={{ borderLeft: '8px solid blue' }}
					>
						<InfoOutlined sx={{ mr: 1.5, color: 'blue' }} />
						<Typography variant="body1" color="blue">
							{intl.formatMessage(
								{id: `mytokens.redeem.shipping.${user.type_external}.info_returned_tokens` },
								{ returnedTokens: intl.formatNumber(data.redeemRequest?.returned_tokens, { style: 'decimal' }) }
							)}
						</Typography>
					</Box>
				)}
				<StatusCard
					titleLocation="center"
					statuses={counts}
					loading={!data?.redeemRequest}
					cardbody={
						<Grid container sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
							<Grid item xs={12} md={6}>
								<Stack direction="row" spacing={2}>
								<Typography variant="inherit" fontWeight={700}>
									{intl.formatMessage({ id: 'mytokens.seller.dashboard.redeem_request.deliver_type' })}
								</Typography>
								{/* @ts-ignore */}
								<Typography variant="inherit">{RedeemTypesMapper[`${data?.redeemRequest?.type}`]}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
								<DeliveryAddress
									user={user}
									address={!!data?.address ? data.address : data?.redeemRequest?.address}
									locality={!!data?.address ? data.address : data?.redeemRequest?.address}
									withdrawal={data?.redeemRequest?.type === RedeemRequestTypes.WITHDRAWAL}
								/>
							</Grid>
						
						</Grid>
					}
				/>
				<Grid container spacing={4}>
					<Grid item xs={12} sm={6}>
						<TableCard
							title={intl.formatMessage({ id: 'mytokens.seller.dashboard.redeem_request.related_products.title' })}
							columns={relatedProducts}
							disabledWidthForUnsortedColumns
							rows={data?.redeemRequestProducts.map((product: any) => ({
								...product,
								unity: data?.redeemRequest?.unity,
							}))}
							loading={!data?.redeemRequestProducts}
							emptyMessage={intl.formatMessage({
								id: 'mytokens.seller.dashboard.redeem_request.related_products.empty_text',
							})}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TableCardTreeView
							loading={!rrFiles}
							title={intl.formatMessage({ id: 'mytokens.seller.dashboard.redeem_request.related_documentation.title' })}
							layoutColumns={12}
							columns={relatedFiles}
							relatedColumns={relatedFiles}
							rows={rrFiles?.map((document: Document) => ({ ...document, user }))}
							actions={[
								{
									displayName: 'Cargar archivo',
									icon: <Upload />,
									disabled: false,
									type: 'Button',
									onClick: () => setFileUploadDialogOpen(true),
								},
							]}
							budgetAcceptanceBlock={
								user.type_external === Role.CONSTRUCTOR &&
								data?.redeemRequest?.status === 2 &&
								data?.redeemRequest?.type === RedeemRequestTypes.SHIPPING &&
								!!data?.redeemRequest?.shipping_price ? (
									<>
										<Box
											sx={{
												marginTop: 2,
												borderRadius: '8px',
												padding: 2,
												backgroundColor: 'rgba(64, 106, 255, 0.19)',
												color: '#0047FF',
												display: 'flex',
												alignItems: 'center',
												gap: 2,
												width: '100%',
											}}
										>
											<ErrorOutlineIcon />
											<Typography color={'#0047FF'}>
												Por favor revise el presupuesto de envío adjunto y confirme su aceptación o rechazo
											</Typography>
										</Box>
										<Box sx={{ display: 'flex', gap: 4, justifyContent: 'center', marginTop: 4 }}>
											<Button
												variant="contained"
												size="medium"
												startIcon={<ThumbUp />}
												sx={{
													background: '#2E7D32',
													color: 'white',
													'&:hover': {
														opacity: 0.9,
													},
												}}
												onClick={onBudgetAcceptClick}
											>
												Aceptar
											</Button>
											<Button
												variant="contained"
												size="medium"
												startIcon={<ThumbDown />}
												sx={{ color: '#D32F2F', border: '1px solid #D32F2F' }}
												onClick={onBudgetRejectClick}
											>
												Rechazar
											</Button>
										</Box>
									</>
								) : (
									<></>
								)
							}
						/>
					</Grid>
				</Grid>
				<Box>
					<DeliveryContext.Provider value={{ baseInfo, message, setMessage, setDeliveryModal }}>
						<Grid item xs={12}>
							<TableCardTreeView
								loading={!data?.redeemRequestShippings}
								title={intl.formatMessage({ id: 'list.seller.details.related_deliveries.title' })}
								layoutColumns={12}
								columns={relatedDeliveries}
								relatedColumns={relatedDeliveryFiles}
								rows={data?.redeemRequestShippings?.map((delivery: Delivery) => ({
									...delivery,
									user,
									unity: data?.redeemRequest?.unity ?? '',
								}))}
								emptyMessage={
									user.type_external === Role.SELLER
										? intl.formatMessage({ id: 'mytokens.seller.redeem.deliveries.empty_state' })
										: intl.formatMessage({ id: 'mytokens.constructor.redeem.deliveries.empty_state' })
								}
								actions={
									user.type_external === Role.SELLER && !!data && data.redeemRequest.status === 2
										? [
												{
													displayName: intl.formatMessage({
														id: 'list.seller.details.deliveries.new_delivery.button_label',
													}),
													icon: <Upload />,
													type: 'Button',
													onClick: () => setDeliveryModal({ data: newDelivery as Delivery, modalType: 'new' }),
												},
										  ]
										: []
								}
							></TableCardTreeView>
						</Grid>
						{deliveryModal &&
							(user.type_external === Role.SELLER ? (
								<SellerDeliveryModal
									open={Boolean(deliveryModal)}
									delivery={deliveryModal.data}
									modalType={deliveryModal.modalType}
									onClose={(event, reason) => {
										setDeliveryModal(null);

										if (['success'].includes(reason ?? '')) init();
									}}
									deliveryType={data.redeemRequest.type}
									redeemRequestProducts={data.redeemRequestProducts}
									unity={data.redeemRequest.unity}
									redeemStep={data.redeemRequest.redeem_step}
								/>
							) : (
								<ConstructorDeliveryModal
									open={Boolean(deliveryModal)}
									delivery={deliveryModal.data}
									modalType={deliveryModal.modalType}
									onClose={() => setDeliveryModal(null)}
									deliveryType={data.redeemRequest.type}
									redeemRequestProducts={data.redeemRequestProducts}
									unity={data.redeemRequest.unity}
								/>
							))}
					</DeliveryContext.Provider>
				</Box>

				<FileUploadDialog
					redeemRequest={true}
					dataRedeemRequest={data?.redeemRequest}
					open={fileUploadDialogOpen}
					types={
						user.type_external === Role.SELLER
							? sellerAllowedFileTypes.filter((fileType) => {
									if ([13, 14, 15].includes(fileType.id)) {
										return (
											rrFiles?.filter(
												(document: Document) =>
													document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
											).length > 0
										);
									}
									return true;
							  })
							: constructorAllowedFileTypes
					}
					documents={
						rrFiles?.filter(
							(document: Document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
						) || []
					}
					user={user}
					onClose={(event, reason, newFile) => {
						setFileUploadDialogOpen(false);
						if (newFile) forceInsertNewFile(newFile);

						if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '') && newFile) fetchFiles();
					}}
				/>
				<BudgetAcceptanceDialog
					open={showBudgetAcceptanceDialog}
					onClose={() => setShowBudgetAcceptanceDialog(false)}
					loading={loadingDialog}
					onConfirm={handleAccept}
				/>
				<RejectedRedeemTokensDialog
					open={showBudgetRejectDialog}
					loading={loadingDialog}
					onClose={() => setShowBudgetRejectDialog(false)}
					onBack={() => setShowBudgetRejectDialog(false)}
					onReject={handleReject}
				/>
				<EndRedeemDialog
					open={openRedeemModal}
					onClose={() => setOpenRedeemModal(false)}
					redeemRequestId={id as string}
					refresh={init}
					tokensPendingToDeliver={data?.redeemRequestProducts.reduce(
						(acc: number, product: any) => acc + product.unassigned * product.equivalence,
						0
					) || 0
					}
				/>
			</Box>
		</BasePageFrame>
	);
};

export default Redeemview;
