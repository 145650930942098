import React from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { MenuItem, SvgIconTypeMap, createTheme } from '@mui/material';

interface Props {
	name: string;
	info?: string | null;
	primaryColor?: string;
	secondaryColor?: string;
	avatarColor?: string;
	avatarIcon?: React.ReactNode;
	avatarImg?: string;
	avatarShape?: 'circle' | 'rounded' | 'square';
	title?: string;
	onClick?: () => void;
	sx?: any;
}

const ContactMenuItem: React.FC<Props> = ({
	name,
	info,
	avatarShape,
	avatarIcon,
	avatarImg,
	title,
	primaryColor = '#141414',
	secondaryColor = '#72727EB2',
	avatarColor = '#D9D9D9',
	onClick,
	sx,
}) => {
	return (
		<MenuItem sx={sx} onClick={onClick}>
			<Grid container sx={{ ml: '10px' }} spacing={0}>
				{title && (
					<Grid sx={{ color: secondaryColor, mb: '10px', fontSize: '10px' }} item xs={11} lg={11} md={11}>
						{title}
					</Grid>
				)}
				<Grid item xs={2} lg={2} md={2}>
					<Avatar
						src={avatarImg}
						sx={{
							width: '40px !important',
							height: '40px !important',
							backgroundColor: avatarColor,
							mr: '5px !important',
							borderRadius: avatarShape === 'square' ? '0' : avatarShape === 'rounded' ? '8px' : '50%',
						}}
					>
						{avatarImg ? null : avatarIcon || name.charAt(0)}
					</Avatar>
				</Grid>
				<Grid item xs={9} lg={9} md={9} sx={{ ml: '5px' }}>
					<Typography
						whiteSpace={'pre-line'}
						variant="h6"
						style={{ color: primaryColor, marginTop: info ? '0px' : '10px', wordBreak: 'break-word' }}
					>
						{name}
					</Typography>
					{info && <Typography sx={{ fontSize: '12px', color: secondaryColor }}>{info}</Typography>}
				</Grid>
			</Grid>
		</MenuItem>
	);
};

export default ContactMenuItem;
