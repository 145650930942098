import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { User } from '../../../types';
import { Grid, Typography, Box, IconButton, styled, Dialog as MuiDialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadButtonText from '../../file-download-button-text';
import { useMobile } from '../../../hooks/use-mobile';

const Dialog = styled(MuiDialog)(({ theme }) => ({
	'& .MuiPaper-root': {
		padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
		borderRadius: '8px',
	},
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(2),
	},
}));

interface SellerData {
	image?: string;
	name?: string;
	taxId?: number;
}

interface Props {
	open: boolean;
	sellerData: SellerData;
	files?: any;
	listId?: string;
	user: User;
	onClose: (event?: object | null, reason?: string) => void;
}

const Wrapper = styled('div')`
	& > *:not(:first-child) {
	margin-left: 10px;
	}
	
	width: fit-content;	
	margin-bottom: 20px;
`;

const SellectPicture = styled('img')`
	height: 60px;
	display: inline-block;
`;

const TextWrapper = styled('div')`
	display: inline-block;
	vertical-align: top;
`;

const TaxID = styled(Typography)`
	color: #0000ff;
`;

const FileRow = styled(Grid)`
	display: flex;
	&:not(:last-child) {
		border-bottom: 1px solid rgb(223 223 223);
	}
`;

const SellerPaymentInfo = (props: Props) => {
	const intl = useIntl();
	const isMobile = useMobile();
	const { open, sellerData, files, listId, user, onClose } = props;

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle className='border-none pl-4'>
				<Box>
					<Typography variant="h5" textAlign="start">
						{intl.formatMessage({ id: 'list.details.payment_info.title' })}
					</Typography>

					<IconButton
						aria-label="close"
						onClick={onClose}
						className='absolute right-2 top-2 text-gray-500'
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Wrapper>
					{sellerData?.image && <SellectPicture src={sellerData?.image} />}
					<TextWrapper>
						<Typography className='font-light mt-2 text-base'>{sellerData?.name}</Typography>
						<TaxID className='font-light text-xs'>
							{intl.formatMessage({ id: 'common.tax_id' }, { taxId: sellerData?.taxId })}
						</TaxID>
					</TextWrapper>
				</Wrapper>

				<Box display={'flex'} flexDirection={'column'} gap={isMobile ? 0 : 1} >
					{Object.keys(files)?.map((type: any) => {
						return files[type]?.map((file: any, index: number) => {
							return (
								<FileRow key={`${type}-${index}`} container>
									<Grid item xs={8}>
										<Typography variant="subtitle1">
											{intl.formatMessage({ id: `list.details.payment_info.files.${file?.file_type}` })}
											{files[type].length > 1 ? ` #${index + 1}` : ''}
										</Typography>
									</Grid>
									<Grid item xs={4} className='flex flex-wrap justify-end content-center'>
										<FileDownloadButtonText
											driveFileId={file?.file_id}
											fileId={file?.file_type}
											listId={listId}
											user={user}
											gcsFilePath={file?.gcs_file_path}
										/>
									</Grid>
								</FileRow>
							);
						});
					})}
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default SellerPaymentInfo;
