import { DriveFileRenameOutline } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useIntl } from 'react-intl';

interface editButtonProps extends ButtonProps {
	text?: string;
}

const EditButton: React.FC<editButtonProps> = ({ text, ...props }) => {
	const intl = useIntl();
	return (
		<Button sx={{ bgcolor: blue[50] }} endIcon={<DriveFileRenameOutline />} {...props}>
			{text ?? intl.formatMessage({ id: 'common.edit' })}
		</Button>
	);
};

export default EditButton;
