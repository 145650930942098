import {
	KnockProvider,
	KnockFeedProvider,
	NotificationIconButton,
	NotificationFeedPopover,
	KnockI18nProvider,
} from '@knocklabs/react';
import '@knocklabs/react/dist/index.css';

import { CollaboratorRole, Role, User } from '../../types';
import { useState, useRef } from 'react';
import { Box } from '@mui/material';
import NotificationsToast from './notifications-toast';

interface Props {
	user: User | null;
}

const NotificationsBell = (props: Props) => {
	const { user } = props;
	const [isVisible, setIsVisible] = useState(false);
	const notifButtonRef = useRef(null);

	return (
		<KnockProvider
			apiKey={process.env.KNOCK_PUBLIC_API_KEY || 'pk_test_6POGaa25pb3HQ5OFcybJQSNEiQEZR7ED2EDKFWwMy2Y'}
			userId={String(user?.id)}
			userToken={user?.knock_token}
		>
			<KnockFeedProvider feedId={process.env.KNOCK_FEED_ID || '71e66f64-5c82-4e65-9fda-39f52709a1d2'}>
				<KnockI18nProvider
					i18n={{
						translations: {
							emptyFeedTitle: 'Aún no tenés notificaciones',
							emptyFeedBody: 'Te vamos a avisar acá cuando haya algo que debas saber.',
							notifications: 'Notificaciones',
							poweredBy: 'Nuqlea',
							markAllAsRead: 'Marcar todo como leído',
							archiveNotification: '',
							all: 'Todas',
							unread: 'No leídas',
							read: 'Leídas',
							unseen: 'No vistas',
						},
						locale: 'es',
					}}
				>
					<Box
						sx={{
							'& .rnf-notification-feed-popover__inner': {
								borderRadius: '6px',
							},
							'& .rnf-notification-feed__knock-branding': {
								display: 'none',
							},
							'& .rnf-notification-icon-button': {
								width: 'auto',
								height: 'auto',
							},
							'& .rnf-notification-icon-button svg': {
								width: '28px',
								height: '28px',
								color: 'text.primary',
								// mt: .8,
								// mr: .8
							},
						}}
					>
						<NotificationIconButton
							ref={notifButtonRef}
							onClick={(e) => setIsVisible(!isVisible)}
							badgeCountType="unread"
						/>
						<NotificationFeedPopover
							buttonRef={notifButtonRef}
							isVisible={isVisible}
							onClose={() => setIsVisible(false)}
						/>

						<NotificationsToast />
					</Box>
				</KnockI18nProvider>
			</KnockFeedProvider>
		</KnockProvider>
	);
};

export default NotificationsBell;
