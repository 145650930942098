import { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import excelIcon from '../assets/excel-icon.svg';
import pdfIcon from '../assets/pdf-icon.svg';
import { useIntl } from 'react-intl';
import { Alert, Typography, Button, Box, IconButton, CircularProgress, Avatar, debounce } from '@mui/material';
import FullScreenLoading from './full-screen-loading';
import VisibilityIcon from '@mui/icons-material/Visibility';
import client from '../clients/client';
import { User, Role } from '../types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';

import { read } from 'xlsx';
import { stox } from '../utils/excel-utils';
import Spreadsheet from 'x-data-spreadsheet';
import 'x-data-spreadsheet/dist/xspreadsheet.css';
import PdfViewer from '../components/pdfViewer';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import * as is from 'is_js';

interface Props {
	driveFileId?: string;
	fileId?: string;
	listId?: string;
	user: User;
	buttonType?: 'text' | 'icon' | 'link';
	forcedState?: any;
	text?: string;
	permanentLabel?: Boolean | false;
	gcsFilePath?: string;
	filePath?: string;
	directDownload?: Boolean | false;
	downloadFileMetadataHandler?: (filePath: string, user: User) => Promise<any>;
	downloadFileBinaryHandler?: (gcsFilePath: string, user: User) => Promise<any>;
}

const FileViewer = (props: Props) => {
	const intl = useIntl();
	const { driveFileId, fileId, listId, user, buttonType, forcedState, text, permanentLabel, gcsFilePath, filePath = '', directDownload } = props;
	const { downloadFileMetadataHandler, downloadFileBinaryHandler } = props;

	const baseTheme = useTheme();
	const matchesXs = useMediaQuery(baseTheme.breakpoints.down('md'));
	const ref = useRef('');
	const [open, setOpen] = useState<boolean>(false);
	const [viewType, setViewType] = useState<any>('excel');
	const [loading, setLoading] = useState<boolean>();
	const [error, setError] = useState<string>();
	const [src, setSrc] = useState<string>('');
	const [nameData, setNameData] = useState<any>();
	const [fileData, setFileData] = useState<any>();
	const [centerPos, setCenterPos] = useState<number>(290);
	const [initialScale, setInitialScale] = useState<number>(1);

	const downloadDoc = (nameData: any, fileData: any) => {
		const blob = new Blob([fileData], { type: 'application/octet-stream' });
		const blobURL = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.setAttribute('target', '_blank');
		a.setAttribute('download', nameData.filename);
		a.setAttribute('href', blobURL);
		a.click();
	};

	const showPreview = (fileData: any, documentType: string) => {
		setViewType(documentType);
		const blob = new Blob([fileData], { type: 'application/octet-stream' });
		const blobURL = URL.createObjectURL(blob);
		setSrc(blobURL);
		setOpen(true);
	}

	const showExcelPreview = (nameData: any, fileData: any) => {
		setViewType('excel');
		const blob = new Blob([fileData], { type: 'application/octet-stream' });
		const file = new File([blob], nameData.filename, { type: 'application/octet-stream' });
		const fileReader = new FileReader();
		fileReader.onload = (event: any) => {
			const arrayBuffer = event?.target?.result;
			const wb = read(arrayBuffer, { type: 'array', cellDates: true, cellStyles: true });
			const excelData = stox(wb);

			const s = new Spreadsheet(ref.current, {
				mode: 'read',
				showToolbar: false,
				showContextmenu: false,
				view: {
					height: () => document.getElementById('x-spreadsheet')?.clientHeight || 100,
					width: () => document.getElementById('x-spreadsheet')?.clientWidth || 320,
				},
			}).loadData(excelData.data);
		};
		fileReader.readAsArrayBuffer(file);
		setOpen(true);
	}

	const handleFileView = async (nameData: any, fileData: any) => {
		if (nameData.mime_type === 'application/pdf') {
			// Es un PDF
			showPreview(fileData, 'pdf');
		} else if (nameData.mime_type === 'image/vnd.dwg') {
			// Es un DWG
			downloadDoc(nameData, fileData);
		} else if (nameData.mime_type.startsWith('image/')) {
			// Es una imagen
			showPreview(fileData, 'img');
		} else if (
			['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(
				nameData.mime_type
			)
		) {
			// Es un Excel
			showExcelPreview(nameData, fileData);
		} else {
			downloadDoc(nameData, fileData);
		}
	};

	const handleGcsClick = debounce((gcsFilePath: string) => {
		setLoading(true);

		const downloadFileMetadata = downloadFileMetadataHandler
			? downloadFileMetadataHandler(directDownload ? filePath : gcsFilePath, user)
			: client.downloadGCSFile(gcsFilePath, 'base64', user.id_external, user.token);
		const downloadFileBinary = downloadFileBinaryHandler
			? downloadFileBinaryHandler(gcsFilePath, user)
			: client.downloadGCSBinaryFile(gcsFilePath, user.id_external, user.token);

		Promise.all([downloadFileMetadata, downloadFileBinary])
			.then(([responseFile, responseBinary]) => {
				setNameData(responseFile);
				setFileData(responseBinary);
				handleFileView(responseFile, responseBinary);
			})
			.finally(() => setLoading(false));
	}, 250);

	useEffect(() => {
		setCenterPos(window.innerWidth / 7);
		setInitialScale(window.innerWidth > 1080 ? 1 : window.innerWidth > 780 ? 0.62 : 0.5);
		return () => URL.revokeObjectURL(src);
	}, [src]);

	useEffect(() => {
		if (forcedState && forcedState.open) {
			setNameData(forcedState.nameData);
			setFileData(forcedState.fileData);
			handleFileView(forcedState.nameData, forcedState.fileData);
		}
	}, [forcedState]);

	if (loading) {
		if (permanentLabel) {
			return (
				<Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}>
					<Box>{text}</Box>
					<CircularProgress color="info" size={30} />
				</Box>
			);
		} else {
			return <CircularProgress color="info" size={30} />;
		}
	}

	return (
		<Box sx={{ justifyContent: 'center', display: buttonType === 'link' ? 'inline-block' : 'auto' }}>
			{gcsFilePath && buttonType === 'icon' && (
				<IconButton
					onClick={(e: any) => {
						e.preventDefault();
						e.stopPropagation();
						e.nativeEvent.stopImmediatePropagation();
						handleGcsClick(gcsFilePath);
					}}
				>
					<VisibilityIcon />
				</IconButton>
			)}
			{gcsFilePath && buttonType === 'text' && (
				<Button
					variant="outlined"
					sx={{ color: '#000', borderColor: '#000', borderRadius: 16, fontSize: '.9em', ml: -1 }}
					onClick={() => handleGcsClick(gcsFilePath)}
				>
					<VisibilityIcon sx={{ mr: 1 }} /> {text || intl.formatMessage({ id: 'common.view' })}
				</Button>
			)}
			{gcsFilePath && buttonType === 'link' && (
				<a
					href="#"
					onClick={(event: any) => {
						event.preventDefault();
						handleGcsClick(gcsFilePath);
					}}
				>
					{text || intl.formatMessage({ id: 'common.view' })}
				</a>
			)}
			{driveFileId && buttonType === 'text' && (
				<Button
					variant="outlined"
					sx={{ color: '#000', borderColor: '#000', borderRadius: 16, fontSize: '.9em', ml: -1 }}
					onClick={() => {
						setLoading(true);
						Promise.all([
							client.getDriveFileName({ driveFileId, user }),
							listId && fileId
								? client.getDriveFile({ driveFileId, fileId, listId, user })
								: client.getDriveFileOnly({ driveFileId, user }),
						])
							.then(([nameData, fileData]) => {
								setNameData(nameData);
								setFileData(fileData);
								handleFileView(nameData, fileData);
							})
							.finally(() => setLoading(false));
					}}
				>
					<VisibilityIcon sx={{ mr: 1 }} /> {text || intl.formatMessage({ id: 'common.view' })}
				</Button>
			)}
			{driveFileId && buttonType === 'icon' && (
				<IconButton
					onClick={(e: any) => {
						e.preventDefault();
						e.stopPropagation();
						e.nativeEvent.stopImmediatePropagation();
						setLoading(true);
						Promise.all([
							client.getDriveFileName({ driveFileId, user }),
							listId && fileId
								? client.getDriveFile({ driveFileId, fileId, listId, user })
								: client.getDriveFileOnly({ driveFileId, user }),
						])
							.then(([nameData, fileData]) => {
								setNameData(nameData);
								setFileData(fileData);
								handleFileView(nameData, fileData);
							})
							.finally(() => setLoading(false));
					}}
				>
					<VisibilityIcon />
				</IconButton>
			)}
			{driveFileId && buttonType === 'link' && (
				<a
					href="#"
					onClick={(event: any) => {
						event.preventDefault();

						setLoading(true);
						Promise.all([
							client.getDriveFileName({ driveFileId, user }),
							listId && fileId
								? client.getDriveFile({ driveFileId, fileId, listId, user })
								: client.getDriveFileOnly({ driveFileId, user }),
						])
							.then(([nameData, fileData]) => {
								setNameData(nameData);
								setFileData(fileData);
								handleFileView(nameData, fileData);
							})
							.finally(() => setLoading(false));
					}}
				>
					{text || intl.formatMessage({ id: 'common.view' })}
				</a>
			)}
			{!(driveFileId || gcsFilePath) && permanentLabel && (
				<Box>{text || intl.formatMessage({ id: 'common.view' })}</Box>
			)}
			{(driveFileId || gcsFilePath) && (
				<Dialog
					open={open}
					onClose={(e: any) => {
						e.preventDefault();
						e.stopPropagation();
						e.nativeEvent.stopImmediatePropagation();
						setOpen(false);
					}}
					fullWidth
					maxWidth="xl"
					sx={{
						'& .MuiPaper-root': {
							mt: {
								md: '-32px',
								xs: 0,
							},
							height: {
								md: 'auto',
								xs: '86vh',
							},
						},
					}}
				>
					{loading && <FullScreenLoading />}
					<DialogContent
						sx={{
							background: 'rgba(0,0,0,0.72)',
							p: {
								xs: 0,
							},
							'& #proxy-renderer': { minHeight: '360px', overflow: 'hidden' },
							'& .react-transform-wrapper': {
								width: '100%',
								pt: !matchesXs ? 6 : 0,
							},
						}}
					>
						{error && (
							<Alert severity="error">{intl.formatMessage({ id: 'common.loading_error_message.details' })}</Alert>
						)}
						<TransformWrapper
							disabled={viewType === 'excel'}
							initialPositionX={viewType === 'excel' ? 0 : centerPos}
							initialScale={viewType !== 'excel' && !matchesXs ? initialScale : 1}
							minScale={0.25}
							wheel={{ wheelDisabled: !matchesXs }}
							limitToBounds={matchesXs}
							centerZoomedOut={matchesXs}
							disablePadding={matchesXs}
						>
							{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
								<>
									{
										<Box
											sx={{
												width: '100%',
												position: 'absolute',
												backgroundColor: 'rgba(0,0,0,0.4)',
												zIndex: 10,
											}}
										>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
													gap: 1,
													mt: 1.25,
													ml: 1.5,
													mb: 1,
												}}
											>
												<Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
													<Avatar
														sx={{ width: 30, height: 30 }}
														variant="square"
														src={viewType === 'excel' ? excelIcon : viewType === 'pdf' ? pdfIcon : ''}
													/>
													<Typography
														color="#FFF"
														sx={{
															maxWidth: '150px',
															wordWrap: 'break-word',
															whiteSpace: 'nowrap',
															textOverflow: 'ellipsis',
															maxHeight: '22px',
															overflow: 'hidden',
														}}
													>
														{nameData.filename}
													</Typography>
												</Box>
												{viewType !== 'excel' && !matchesXs && (
													<Box sx={{ mr: 2.5, display: 'flex', gap: 1.5 }}>
														<IconButton
															size="small"
															color="primary"
															onClick={() => zoomIn()}
															sx={{
																backgroundColor: '#FFF',
																color: '#000',
																boxShadow: '0 4px 10px rgba(0,0,0,0.5)',
																'&:hover': { backgroundColor: '#acacac' },
															}}
														>
															<ZoomInIcon />
														</IconButton>
														<IconButton
															size="small"
															color="primary"
															onClick={() => zoomOut()}
															sx={{
																backgroundColor: '#FFF',
																color: '#000',
																boxShadow: '0 4px 10px rgba(0,0,0,0.5)',
																'&:hover': { backgroundColor: '#acacac' },
															}}
														>
															<ZoomOutIcon />
														</IconButton>
														<IconButton
															size="small"
															color="primary"
															onClick={() => resetTransform()}
															sx={{
																backgroundColor: '#FFF',
																color: '#000',
																boxShadow: '0 4px 10px rgba(0,0,0,0.5)',
																'&:hover': { backgroundColor: '#acacac' },
															}}
														>
															<RestartAltIcon />
														</IconButton>
													</Box>
												)}
												{is.iphone() && (
													<Box sx={{ mr: 2.5, display: 'flex', gap: 1.5 }}>
														<IconButton
															size="small"
															color="primary"
															onClick={(e: any) => {
																e.preventDefault();
																e.stopPropagation();
																e.nativeEvent.stopImmediatePropagation();
																downloadDoc(nameData, fileData);
															}}
															sx={{
																backgroundColor: '#FFF',
																color: '#000',
																boxShadow: '0 4px 10px rgba(0,0,0,0.5)',
																'&:hover': { backgroundColor: '#acacac' },
															}}
														>
															<DownloadIcon />
														</IconButton>
														<IconButton
															size="small"
															color="primary"
															onClick={(e: any) => {
																e.preventDefault();
																e.stopPropagation();
																e.nativeEvent.stopImmediatePropagation();
																setOpen(false);
															}}
															sx={{
																backgroundColor: '#FFF',
																color: '#000',
																boxShadow: '0 4px 10px rgba(0,0,0,0.5)',
																'&:hover': { backgroundColor: '#acacac' },
															}}
														>
															<CloseIcon />
														</IconButton>
													</Box>
												)}
											</Box>
										</Box>
									}
									{viewType === 'pdf' && (
										<Box
											sx={{
												height: 'calc(100vh - 210px)',
												width: '100%',
												minWidth: '100%',
												'& .react-pdf__Document': {
													py: 4,
													'& .react-pdf__Page': {
														backgroundColor: 'transparent!important',
														'& .react-pdf__Page__canvas': {
															boxShadow: '2px 2px 12px rgba(0,0,0,0.72)',
															mx: 'auto',
														},
													},
												},
											}}
										>
											<TransformComponent>
												<PdfViewer url={src} />
											</TransformComponent>
										</Box>
									)}
									{viewType === 'excel' && (
										<TransformComponent contentStyle={{ width: '100%', padding: matchesXs ? 0 : 28 }}>
											<Box
												id="x-spreadsheet"
												sx={{
													height: {
														md: 'calc(100vh - 320px)',
														xs: 'calc(100vh - 125px)',
													},
													width: '100%',
													minWidth: '100%',
													boxShadow: '2px 2px 12px rgba(0,0,0,0.72)',
												}}
												ref={ref}
											/>
										</TransformComponent>
									)}
									{viewType === 'img' && (
										<Box
											sx={{
												width: '100%',
												textAlign: 'center',
												height: 'calc(100vh - 210px)',
												'& img': {
													m: 4,
													boxShadow: '2px 2px 12px rgba(0,0,0,0.72)',
													mx: 'auto',
												},
											}}
										>
											<TransformComponent>
												<img src={src} loading="lazy" alt="Vista previa" />
											</TransformComponent>
										</Box>
									)}
								</>
							)}
						</TransformWrapper>
					</DialogContent>
					{is.not.iphone() && (
						<DialogActions sx={{ justifyContent: 'center', gap: 1 }}>
						<Button
						  onClick={(e: any) => {
							e.preventDefault();
							e.stopPropagation();
							e.nativeEvent.stopImmediatePropagation();
							setOpen(false);
						  }}
						  variant="outlined"
						  sx={{ width: '120px' }}
						>
						  {intl.formatMessage({ id: 'common.close' })}
						</Button>
						<Button
						  onClick={(e: any) => {
							e.preventDefault();
							e.stopPropagation();
							e.nativeEvent.stopImmediatePropagation();
							downloadDoc(nameData, fileData);
						  }}
						  variant="contained"
						  sx={{ width: '120px' }}
						>
						  {intl.formatMessage({ id: 'common.download' })}
						</Button>
					  </DialogActions>
					)}
				</Dialog>
			)}
		</Box>
	);
};

export default FileViewer;
