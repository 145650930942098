import axios, { AxiosResponse } from 'axios';
import { BusinessDetails, BusinessName, User } from '../types';

const customAxios = (user?: User) => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL + '/constructor',
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers['Accept'] = 'application/json';
            if (user) {
                config.headers['Authorization'] = `Bearer ${user.token}`;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;


interface GeneralResponse {
    success: boolean;
}

interface BusinessNameFileUploadResponse extends GeneralResponse {
    businessNameId?: string;
    files: any[];
}

interface BusinessDetailsFileUploadResponse extends GeneralResponse {
    businessDetailId?: string;
    files: any[];
}

export interface FileUploadRequest {
    type: number;
    format: string;
    name: string
}

export interface PrepareUploadRequest {
    user: User;
    entityId?: string;
    files: FileUploadRequest[];
}

// TODO: Tipificar los responses
const client = {
    prepareBusinessNameUpload: async (requestDto: PrepareUploadRequest): Promise<BusinessNameFileUploadResponse | null> => {
        const { user, entityId, files } = requestDto;

        if (files.length === 0) {
            return null;
        }

        try {
            const client = customAxios(user);
            const response = await client.post<any>('/prepare-upload', { files, user, businessNameId: entityId });
            return responseBody(response);
        } catch (error) {
            throw error;
        }
    },
    checkBusinessNameExists: async ({ data, user }: { user: User; data: any }): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.post<any>('/checkBusinessNameExists', data);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    getBusinessName: async ({ user, id }: { user: User; id: string }): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/getBusinessName/${id}`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    getBusinessNames: async ({ user, constructorId }: { user: User; constructorId: string }): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/getBusinessNames/${constructorId}`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    getDefaultBusinessName: async ({ user, constructor_id }: { user: User; constructor_id: string }): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/${constructor_id}/main-business-name`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    createBusinessName: async ({ data, user }: { user: User; data: BusinessName }): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.post<any>('/createBusinessName', data);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    updateBusinessName: async ({ data, user }: { user: User; data: any }): Promise<GeneralResponse> => {
        try {
            const client = customAxios(user);
            const response = await client.post<any>('/updateBusinessName', data);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    deleteBusinessName: async ({ id, user }: { user: User; id: string }): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.post<any>(`/deleteBusinessName/${id}`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    prepareBusinessDetailsUpload: async (requestDto: PrepareUploadRequest): Promise<BusinessDetailsFileUploadResponse | null> => {
        const { user, entityId, files } = requestDto;

        if (files.length === 0) {
            return null;
        }

        try {
            const client = customAxios(user);
            const response = await client.post<any>('/business-name/detail/prepare-upload', { files, user, businessDetailId: entityId });
            return responseBody(response);
        } catch (error) {
            throw error;
        }
    },
    createBusinessDetails: async ({ data, user }: { user: User; data: BusinessDetails }): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.post<any>('/createBusinessDetails', data);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    getBusinessDetails: async ({ user, id }: { user: User; id: string }): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/getBusinessDetails/${id}`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    updateBusinessDetails: async ({ data, user }: { user: User; data: any }): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.post<any>('/updateBusinessDetails', data);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    deleteBusinessDetails: async ({ id, user }: { user: User; id: string }): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.post<any>(`/deleteBusinessDetails/${id}`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },

};

export default client;