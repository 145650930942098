import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { InputLabel, MenuItem, Typography, styled, Alert } from '@mui/material';
import DatePicker from '../../date-picker';
import { DeliveryCompleteness, Delivery, DeliveryType, User } from '../../../types';
import FullScreenLoading from '../../full-screen-loading';
import client from '../../../clients/client';
import { useDeliveryContext } from '../../../contexts/deliveryContext';

const SeparatedLabel = styled(InputLabel)`
	margin-top: 15px;
	margin-bottom: 5px;
`;

interface Props {
	open: boolean;
	delivery: Delivery;
	deliveryType: string;
	// dataConstructor?: ListDetails;
	// dataSeller?: SellerListDetails;
	user?: User;
	onClose: (event?: object | null, reason?: string, newFile?: any) => void;
}

const SellerEditDeliveryDialog = (props: Props) => {
	const intl = useIntl();
	const { open, delivery, deliveryType, user, onClose } = props;

	const { baseInfo, setMessage } = useDeliveryContext();
	
	const deliveryTypeOptions = [
		{ id: 6, value: 'pickup' },
		{ id: 1, value: 'delivery' },
		{ id: 3, value: 'express' }
	];

	const [loading, setLoading] = useState<boolean>();
	const [error, setError] = useState<string>('');
	const [comments, setComments] = useState<string>();
	const [expresoName, setExpresoName] = useState<string | undefined>(delivery.express_name || undefined);
	const [date, setDate] = useState<Date | undefined>(delivery.estimated_delivery_date ? new Date(delivery.estimated_delivery_date) : undefined);
	const [deliveryOption, setDeliveryOption] = useState<string | undefined>(delivery.type ? deliveryTypeOptions.find(op => op.id === delivery.type)?.value : undefined);

	const handleSubmit = () => {
		setLoading(true);
		setError('');
		
		date?.setHours(24)
		client.updateDelivery({
			deliveryId: delivery.id,
			fields: {
				completeness: delivery.completeness,
				delivery_type: deliveryOption,
				express_name: expresoName,
				estimated_delivery_date: date?.toISOString().split('T')[0],
				change_reason: comments,
				comment: delivery.comment
			},
			user
		}).then((response) => {
			setMessage({
				delivery,
				delivery_file: null,
				updated_fields: {
					delivery_type: deliveryOption,
					express_name: expresoName,
					estimated_delivery_date: date?.toISOString().split('T')[0],
					change_reason: comments
				}
			})

			onClose(null, 'success')
			
			// Reset form
			setDate(undefined)
			setComments(undefined)
			setDeliveryOption(undefined)
			setExpresoName(undefined)
		})
		.catch((error) => setError(error.message))
		.finally(() => setLoading(false));
	};

	const getTitle = (type: string) => {
		return 'list.seller.details.deliveries.edit_delivery_dialog.title'
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
			sx={{ '& .MuiPaper-root': { maxWidth: '500px', p: 3, borderRadius: 4 } }}
			onClick={(e) => {
				if (e.target instanceof HTMLElement) {
					if(!['checkbox', 'checkbox-label', 'datepicker'].includes(e.target.id)) {
						e.preventDefault()
					}
				}
				e.stopPropagation()
			}}
		>
			{loading && <FullScreenLoading />}
			<DialogTitle>	
				<Typography variant="h5">{intl.formatMessage({ id: getTitle(deliveryType) })}</Typography>
			</DialogTitle>
			<DialogContent>
				<SeparatedLabel sx={{ fontWeight: 900 }}>
					{intl.formatMessage({ id: 'list.seller.details.deliveries.edit_delivery_dialog.delivery_type_field' })}
				</SeparatedLabel>
				<TextField
					select
					fullWidth
					required
					size="small"
					value={deliveryOption}
					onChange={(event) => setDeliveryOption(event.target.value)}
				>
					{deliveryTypeOptions.map((dop) => (
						<MenuItem key={dop.id} value={dop.value}>
							{intl.formatMessage({ id: `list.seller.details.deliveries.edit_delivery_dialog.delivery_type_value.${dop.value}` })}
						</MenuItem>
					))}
				</TextField>

				{
					deliveryOption === 'express' && <>
						<SeparatedLabel sx={{ fontWeight: 900 }}>
							{intl.formatMessage({ id: 'list.seller.details.deliveries.delivery_dialog.expreso.expreso_name_field' })}
						</SeparatedLabel>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={expresoName}
							onChange={(event) => setExpresoName(event.target.value)}
						/>
					</>
				}

				<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
					{intl.formatMessage({ id: 'list.seller.details.deliveries.edit_delivery_dialog.delivery_date_field' })}
				</SeparatedLabel>
				<DatePicker
					value={date}
					onChange={(value) => { console.log(value?.toISOString().split('T')[0]);console.log(delivery.estimated_delivery_date.split('T')[0]);setDate(value); }}
					variant="form"
				/>

				<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
					{intl.formatMessage({ id: 'list.seller.details.deliveries.edit_delivery_dialog.delivery_reason_field' })}
					{ ( date && date?.toISOString().split('T')[0] !== delivery.estimated_delivery_date?.split('T')[0]) ? '*' : ''}
				</SeparatedLabel>
				<TextField
					fullWidth
					multiline
					size="small"
					rows={3}
					placeholder={intl.formatMessage({
						id: 'list.seller.details.deliveries.edit_delivery_dialog.delivery_reason_field_placeholder',
					})}
					value={comments}
					onChange={(event) => setComments(event.target.value)}
				/>
				{error && (
					<Alert severity="error" sx={{ mt: 2 }}>
						{error ?? 'Ocurrió un error.'}
					</Alert>
				)}
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', gap: 1 }}>
				<Button
					onClick={() => onClose(null, 'cancelButtonClick')}
					variant="contained"
					sx={{ border: '1px solid #000', borderRadius: 10, width: '120px' }}
				>
					{intl.formatMessage({ id: 'common.cancel' })}
				</Button>
				<Button
					onClick={handleSubmit}
					variant="contained"
					disabled={
						!deliveryOption ||
						(deliveryOption === 'express' && !expresoName) ||
						(
							( date && (date?.toISOString().split('T')[0] !== delivery.estimated_delivery_date?.split('T')[0])) &&
							(!comments || comments === '')
						)
					}
					sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius: 10, width: '120px' }}
				>
					{intl.formatMessage({ id: 'common.confirm' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SellerEditDeliveryDialog;
